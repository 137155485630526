import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Container, Card, Row, Col } from 'react-bootstrap';
import { get } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { FaTrash } from 'react-icons/fa';

import Loading from '../../components/Loading';
import axios from '../../services/axios';
import Breadcrumbs from '../../components/Breadcrumbs';
import { useNotification } from '../../components/Notification/NotificationContext';
import Forms from '../../components/Forms';
import ErrorProcessor from '../../components/ErrorProcessor';
import ButtonModal from '../../components/ModalConfirmation/ButtonModal';
import DataTable from '../../components/DataTable';
import PageHeader from '../../components/PageHeader';

export default function AccessModule() {
  const routeName = '/userdpto';
  const titleName = 'Atribuições de Usuários';
  const dataName = 'Usuário';
  const [dataLoaded, setDataLoaded] = useState(false);
  const [apiErrors, setApiErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [, setData] = useState([]);
  const notification = useNotification();
  const navigate = useNavigate();
  const params = useParams();
  const id = get(params, 'id', '');
  const [modules, setModules] = useState([]);
  const [access, setAccess] = useState({ Users: [] });
  const [formValues, setFormValues] = useState({
    userId: '',
  });

  const validationSchema = Yup.object().shape({
    userId: Yup.string().required('Usuário obrigatório.'),
  });

  const tableColumns = useMemo(
    () => [
      {
        Header: 'Usuários',
        accessor: 'name',
      },
      {
        Header: 'Remover',
        accessor: 'remove',
        noSearch: true,
      },
    ],
    []
  );

  const handleFieldChange = useCallback((fieldName, value) => {
    setFormValues((prevValues) => ({ ...prevValues, [fieldName]: value }));
  }, []);

  const fields = [
    {
      name: 'userId',
      id: 'userId',
      label: 'Usuários',
      type: 'select',
      options: modules,
      onChange: (e) =>
        handleFieldChange('userId', e.value ? e.value : e.target.value),
      colSize: 12,
      additionalProps: {},
    },
  ];
  const reloadAccessData = async () => {
    try {
      const getModules = await axios.get(`${routeName}/${id}`);
      if (getModules.data) {
        setAccess(getModules.data);
      } else {
        setAccess([]); // Tratar caso não tenha usuários
      }
    } catch (error) {
      console.error('Erro ao recarregar os dados:', error);
      setApiErrors(error);
    }
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      await axios.post(routeName, {
        ...formValues,
        ...{ departmentId: id },
      });
      const getAccess = await axios.get(`users/`);
      setAccess(getAccess.data);
      notification(`${dataName} adicionado com sucesso`, 'success');
      reloadAccessData();
    } catch (err) {
      setApiErrors(err);
    } finally {
      setIsLoading(false);
    }
  };

  const loadData = useCallback(async () => {
    try {
      const result = await axios.get(`${routeName}/${id}`);
      setData(result.data);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // Se não encontrar o departamento, considera como uma lista vazia de usuários.
        setData([]); // Configura os dados como um array vazio para representar nenhum usuário.
        notification(
          'Nenhum usuário encontrado para este departamento',
          'info'
        );
      } else {
        console.error('Erro ao carregar os dados:', error);
        setApiErrors(error);
      }
    }
  }, [id, routeName, notification, setApiErrors]); // Inclua as dependências necessárias para o useCallback

  // Use loadData dentro de useEffect ou useCallback conforme necessário
  useEffect(() => {
    loadData();
  }, [loadData]);

  const confirmDelete = useCallback(
    async (userId) => {
      try {
        setIsLoading(true);
        const response = await axios.delete(`${routeName}/${userId}`);
        if (response.status === 200 || response.status === 204) {
          setAccess((currentAccess) => {
            console.log('Access before update:', currentAccess);
            // Aplica o filtro diretamente em currentAccess
            const updatedAccess = currentAccess.filter(
              (user) => user.id.toString() !== userId.toString()
            );
            console.log('Access after update:', updatedAccess);
            return updatedAccess;
          });
          notification('Usuário removido com sucesso', 'success');
        } else {
          throw new Error('Falha ao deletar o usuário');
        }
      } catch (err) {
        console.error('Erro ao remover usuário', err);
        notification('Erro ao remover usuário', 'error');
        setApiErrors(err);
      } finally {
        setIsLoading(false);
      }
    },
    [notification, setIsLoading, routeName]
  );

  const tableData = useMemo(() => {
    if (!Array.isArray(access)) return []; // Verificação adicional para garantir que é um array
    return access.map((userDept) => ({
      name: `${userDept.UserProfile.name} ${userDept.UserProfile.lastname}`,
      remove: (
        <ButtonModal
          onConfirm={() => confirmDelete(userDept.id)}
          message={`Confirma remoção do ${dataName} ${userDept.UserProfile.name}?`}
          buttonProps={{
            variant: 'danger',
            className: 'rounded-pill shadow',
            size: 'sm',
          }}
          textModal={{
            title: 'Remover',
            textConfirm: 'Remover',
          }}
        >
          <FaTrash />
        </ButtonModal>
      ),
    }));
  }, [access, confirmDelete, dataName]);

  useEffect(() => {
    if (!id) {
      notification(`Acesso inválido ou não informado`);
      navigate('/departaments');
      return;
    }
    async function getData() {
      setIsLoading(true);
      try {
        // Carrega todos os usuários para opções de atribuição
        const optionsModules = [{ value: '', label: '-- Selecione --' }];
        const getAccess = await axios.get(`users/`);
        getAccess.data.forEach((data) => {
          optionsModules.push({
            value: data.UserProfile.id,
            label: data.UserProfile.name,
          });
        });

        // Busca apenas os usuários atribuídos a este departamento específico
        try {
          const getModules = await axios.get(`${routeName}/${id}`);
          setAccess(getModules.data);
        } catch (moduleError) {
          if (moduleError.response && moduleError.response.status === 404) {
            // Caso não haja usuários atribuídos ao departamento, trata como lista vazia
            setAccess([]);
          } else {
            throw moduleError; // Re-lança o erro se não for um erro 404
          }
        }

        setModules(optionsModules);
      } catch (err) {
        console.error('Erro ao carregar os dados:', err);
        setApiErrors(err);
      } finally {
        setIsLoading(false);
        setDataLoaded(true);
      }
    }

    getData();
  }, [id, notification, navigate, routeName]);
  return (
    <Container fluid className="px-lg-4 px-xl-5">
      <Loading isLoading={isLoading} />
      <Breadcrumbs
        title="Usuários"
        pages={[{ link: '/departaments', name: 'Atribuição' }]}
      />
      <PageHeader title={titleName} />
      <section>
        <Row>
          <Col lg={8}>
            <Card className="mb-4">
              <Card.Header>
                <h4 className="card-heading">
                  Atribuições de Departamentos {access.name}
                </h4>
              </Card.Header>
              <Card.Body>
                <DataTable
                  items={tableData}
                  columns={tableColumns}
                  defaultPageSize={5}
                />
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4}>
            <Card className="mb-5">
              <Card.Header>
                <h4 className="card-heading">Adicionar Usuário</h4>
              </Card.Header>
              <Card.Body>
                <ErrorProcessor error={apiErrors} />
                {!id || dataLoaded ? (
                  <Forms
                    // key={JSON.stringify(initialValues)}
                    initialValues={formValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    fields={fields}
                    btnSubmit={{
                      variant: 'primary',
                      type: 'submit',
                      text: 'Adicionar',
                    }}
                  />
                ) : null}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </section>
    </Container>
  );
}
