import React, { useEffect, useState, useCallback } from 'react';
// import Router, { useRouter } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Badge, Collapse } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Icon from '../Icon';
import ActiveLink from '../ActiveLink';

export default function Sidebar({ sidebarShrink }) {
  const [dropdown, setDropdown] = useState({});
  const [activeParent, setActiveParent] = useState();
  const userData = useSelector((state) => state.auth.user);

  const [modules, setModules] = useState([{ name: 'Principal', items: [] }]);
  // const router = useRouter();
  const location = useLocation();

  const toggleDropdown = useCallback(
    (e, name) => {
      e && e.preventDefault();
      setDropdown({ ...dropdown, [name]: !dropdown[name] });
    },
    [dropdown]
  );

  const highlightDropdownParent = useCallback(() => {
    let found = false;
    modules.forEach((block) => {
      block.items.forEach((item) => {
        item.links &&
          item.links.forEach((link) => {
            if (
              link.link &&
              link.link === `/${location.pathname.split('/')[1]}`
            ) {
              setDropdown((prevDropdown) => ({
                ...prevDropdown,
                [item.name]: true,
              }));
              setActiveParent(item.name);
              found = true;
            }
          });
      });
    });

    if (!found) {
      setDropdown({});
      setActiveParent(null);
    }
  }, [location.pathname, modules]);

  const handleItemClick = (e, item) => {
    if (item.links) {
      toggleDropdown(e, item.name);
    } else {
      setDropdown({});
      setActiveParent(item.name);
    }
  };

  useEffect(() => {
    const modulesPrepare = [{ name: 'Principal', items: [] }];
    let getData =
      userData.isadmin !== 1 ? userData.userModules : userData.modules;

    const groups = {};

    if (getData) {
      getData = getData.sort((a, b) => a.order - b.order);
      getData.forEach((module) => {
        if (!groups[module.group]) {
          groups[module.group] = [];
          modulesPrepare[0].items.push({
            name: module.group,
            link: '/#',
            icon: module.icon,
            links: groups[module.group],
          });
        }

        groups[module.group].push({
          name: module.name,
          link: module.page,
        });
      });
    }
    setModules(modulesPrepare);
  }, [userData]);

  useEffect(() => {
    highlightDropdownParent();
  }, [location.pathname, highlightDropdownParent]);

  return (
    <div
      className={`sidebar py-3 ${sidebarShrink ? 'shrink show' : ''}`}
      id="sidebar"
    >
      {modules.map((block) => (
        <React.Fragment key={block.name}>
          <h6 className="sidebar-heading">{block.name}</h6>
          <ul className="list-unstyled">
            {block.items.map((item) => (
              <li key={item.name} className="sidebar-list-item">
                <ActiveLink href={item.link} activeClassName="active">
                  <div
                    className={`sidebar-link text-muted ${
                      activeParent === item.name ? 'active' : ''
                    }`}
                    onClick={(e) =>
                      item.links
                        ? toggleDropdown(e, item.name)
                        : (setDropdown({}), setActiveParent(item.name))
                    }
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleItemClick(e, item);
                      }
                    }}
                    data-bs-toggle={item.links && 'collapse'}
                    role={item.links && 'button'}
                    aria-expanded={dropdown[item.name]}
                  >
                    <Icon className="svg-icon-md me-3" icon={item.icon} />

                    <span className="sidebar-link-title">{item.name}</span>
                  </div>
                </ActiveLink>
                {item.links && (
                  <Collapse in={dropdown[item.name]}>
                    <ul className="sidebar-menu list-unstyled">
                      {item.links.map((link) => (
                        <li key={link.name} className="sidebar-list-item">
                          <ActiveLink href={link.link} activeClassName="active">
                            <div
                              className="sidebar-link text-muted"
                              onClick={() => setActiveParent(item.name)}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  setActiveParent(item.name);
                                }
                              }}
                            >
                              {link.name}
                              {link.new && (
                                <Badge
                                  bg="info"
                                  className="ms-2 text-decoration-none"
                                >
                                  New
                                </Badge>
                              )}
                            </div>
                          </ActiveLink>
                        </li>
                      ))}
                    </ul>
                  </Collapse>
                )}
              </li>
            ))}
          </ul>
        </React.Fragment>
      ))}
    </div>
  );
}

Sidebar.propTypes = {
  sidebarShrink: PropTypes.bool.isRequired,
};
