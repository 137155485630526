// HeaderWithLocation.js
import React from 'react';
import { useLocation } from 'react-router-dom';
import Sidebar from './index';
import * as pagesConfig from '../../config/pages';

export default function SidebarLocation(props) {
  const location = useLocation();

  if (pagesConfig.noSideBarPages.includes(location.pathname)) {
    return null;
  }

  return <Sidebar {...props} />;
}
