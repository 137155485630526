import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';
import { Container, Card, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import axios from '../../services/axios';
import Loading from '../../components/Loading';
import Breadcrumbs from '../../components/Breadcrumbs';
import DataTable from '../../components/DataTable';
import ButtonModal from '../../components/ModalConfirmation/ButtonModal';
import { useNotification } from '../../components/Notification/NotificationContext';
import ErrorProcessor from '../../components/ErrorProcessor';
import CardHeaderMore from '../../components/Cards/CardHeaderMore';

export default function ServiceDesks() {
  const routeName = '/servicedesk';
  const backend = '/servicedesks';
  const dataName = 'Mesas de Serviços';
  const titleName = 'Mesas de Serviços';
  const navigate = useNavigate();
  const [apiErrors, setApiErrors] = useState({});
  const notification = useNotification();
  const [serviceDesks, setServiceDesks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const userData = useSelector((state) => state.auth.user);

  const tableColumns = useMemo(
    () => [
      { Header: 'Nome', accessor: 'displayName' },
      { Header: 'Clientes', accessor: 'customerCount', noSearch: true },
      { Header: 'Departamentos', accessor: 'departmentCount', noSearch: true },
      { Header: 'Ações', accessor: 'actions', noSearch: true },
    ],
    []
  );

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const response = await axios.get('/servicedesks');
        const serviceDesksData = response.data.map((serviceDesk) => ({
          ...serviceDesk,
          customerCount: serviceDesk.customers.length,
          departmentCount: serviceDesk.departments.length,
        }));
        setServiceDesks(serviceDesksData);
      } catch (error) {
        console.error('Erro ao carregar dados:', error);
        setApiErrors(error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchData();
  }, []);

  const confirmDelete = useCallback(
    async (id) => {
      try {
        setIsLoading(true);
        await axios.delete(`${backend}/${id}`);
        notification(`${dataName} removido com sucesso`, 'success');
        const updatedServiceDesks = serviceDesks.filter(
          (serviceDesk) => serviceDesk.id !== id
        );
        setServiceDesks(updatedServiceDesks);
      } catch (err) {
        setApiErrors(err);
      } finally {
        setIsLoading(false);
      }
    },
    [serviceDesks, notification]
  );

  const tableData = useMemo(
    () =>
      serviceDesks.map((serviceDesk) => {
        const linksAction = [
          {
            onclick: () => navigate(`${routeName}/${serviceDesk.id}/edit`),
            faicon: 'faEdit',
            text: 'Atualizar',
          },
        ];

        if (userData.isadmin === 1) {
          linksAction.push({
            custom: (
              <ButtonModal
                onConfirm={() => confirmDelete(serviceDesk.id)}
                message={`Confirma remoção da ${dataName} ${serviceDesk.displayName}?`}
                buttonProps={{ text: 'Remover', faicon: 'faTrash' }}
                textModal={{
                  title: `Remover ${dataName}`,
                  textConfirm: 'Remover',
                }}
                dropdown
              />
            ),
          });
        }

        return {
          displayName: serviceDesk.displayName,
          customerCount: serviceDesk.customerCount,
          departmentCount: serviceDesk.departmentCount,
          actions: <CardHeaderMore links={linksAction} className="" />,
        };
      }),
    [serviceDesks, userData.isadmin, navigate, confirmDelete]
  );

  return (
    <Container fluid className="px-lg-4 px-xl-5">
      <Loading isLoading={isLoading} />
      <Breadcrumbs title="Mesas de Serviços" />
      <div className="page-header d-flex justify-content-between align-items-center">
        <h1 className="page-heading">{titleName}</h1>
        <div>
          <Link to={`${routeName}/new`}>
            <Button variant="primary" className="text-uppercase">
              <FaPlus /> Cadastrar
            </Button>
          </Link>
        </div>
      </div>
      <section>
        <Card className="card-table mb-5">
          <ErrorProcessor error={apiErrors} />
          <DataTable
            items={tableData}
            columns={tableColumns}
            defaultPageSize={5}
          />
        </Card>
      </section>
    </Container>
  );
}
