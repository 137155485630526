import React, { useState, useCallback } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { v4 } from 'uuid';
import * as faSolidIcons from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ConfirmationModal from '.';

export default function ButtonModal({
  onConfirm,
  message,
  textModal,
  buttonProps,
  children,
  dropdown,
}) {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = useCallback((e) => {
    e.stopPropagation(); // Impede a propagação do evento
    setShowModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const handleConfirmAction = useCallback(() => {
    onConfirm();
    handleCloseModal();
  }, [onConfirm, handleCloseModal]);

  const variantButton = buttonProps.variant || 'primary';
  return (
    <>
      {dropdown ? (
        <Dropdown.Item key={v4()} onClick={handleShowModal}>
          <FontAwesomeIcon
            icon={faSolidIcons[buttonProps.faicon] || faSolidIcons.faCircle}
            className="me-2 opacity-5"
          />
          {buttonProps.text}
        </Dropdown.Item>
      ) : (
        <Button
          onClick={handleShowModal}
          variant={variantButton}
          {...buttonProps}
        >
          {children}
        </Button>
      )}

      <ConfirmationModal
        show={showModal}
        handleClose={handleCloseModal}
        handleConfirm={handleConfirmAction}
        message={message}
        {...textModal}
      />
    </>
  );
}

ButtonModal.defaultProps = {
  buttonProps: { variant: 'primary', text: 'Clique aqui', faicon: 'faCircle' },
  children: null,
  textModal: {},
  message: null,
  dropdown: false,
};

ButtonModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  buttonProps: PropTypes.shape({
    variant: PropTypes.string,
    text: PropTypes.string,
    faicon: PropTypes.string,
  }),
  children: PropTypes.node,
  textModal: PropTypes.shape({
    title: PropTypes.string,
    textCancel: PropTypes.string,
    textConfirm: PropTypes.string,
  }),
  dropdown: PropTypes.bool,
};
