/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaPlus, FaFilter } from 'react-icons/fa';
import { Container, Card, Button, Col, Form, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { v4 } from 'uuid';

import axios from '../../services/axios';
import Loading from '../../components/Loading';
import Breadcrumbs from '../../components/Breadcrumbs';
import DataTable from '../../components/DataTable';
import ButtonModal from '../../components/ModalConfirmation/ButtonModal';
import { useNotification } from '../../components/Notification/NotificationContext';
import ErrorProcessor from '../../components/ErrorProcessor';
import DatepickerComponent from '../../components/Datepicker';
import ToXLSX from '../../components/Exports/ToXlsx';
import { formatPhoneBr } from '../../hooks/formatPhoneBr';
import CardHeaderMore from '../../components/Cards/CardHeaderMore';

export default function Leads() {
  const routeName = '/leads';
  const dataName = 'Lead';
  const titleName = 'Leads';
  const navigate = useNavigate();
  const [apiErrors, setApiErrors] = useState({});
  const notification = useNotification();
  const [data, setData] = useState([]);
  const [dataExport, setDataExport] = useState([]);
  const [filterCompany, setFilterCompany] = useState('');
  const [filterClosed, setFilterClosed] = useState(0);
  const filterOptionsCompany = useRef([]);
  const filterOptionsClosed = useRef([]);
  const [filterProtocol, setFilterProtocol] = useState('');
  const [filterName, setFilterName] = useState('');
  const [filterDocId, setFilterDocId] = useState('');
  const [filterUser, setFilterUser] = useState('');
  const filterOptionsUser = useRef([]);
  const [filterClassifiers, setFilterClassifiers] = useState('');
  const filterOptionsClassifiers = useRef([]);
  const filterStartDate = useRef(moment().format('YYYY-MM-DD'));
  const filterEndDate = useRef(moment().format('YYYY-MM-DD'));
  const [isLoading, setIsLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const userData = useSelector((state) => state.auth.user);
  const [filterApplied, setFilterApplied] = useState(false);
  const tableColumns = useMemo(
    () => [
      {
        Header: 'Data',
        accessor: 'created_at',
      },
      {
        Header: 'Nome',
        accessor: 'name',
      },
      {
        Header: 'Documento',
        accessor: 'doc_id',
      },
      {
        Header: 'Protocolo',
        accessor: 'protocol',
      },
      {
        Header: 'Empresa',
        accessor: 'company_name',
      },
      {
        Header: 'Usuário',
        accessor: 'user_name',
      },
      {
        Header: 'Classificação',
        accessor: 'cassification_name',
      },
      {
        Header: 'Status',
        accessor: 'closed_name',
      },
    ],
    []
  );

  const getData = useCallback(async () => {
    try {
      const formatedExportData = [];
      setIsLoading(true);
      const response = await axios.post(
        `${routeName}/${filterStartDate.current}/${filterEndDate.current}`,
        {
          name: filterName,
          company_id: filterCompany,
          protocol: filterProtocol,
          doc_id: filterDocId,
          user_id: filterUser,
          classifier_id: filterClassifiers,
          closed: filterClosed,
        }
      );

      response.data.forEach((leadObj) => {
        const leadFormat = {
          Data: moment.utc(leadObj.created_at).format('DD/MM/YYYY'),
          Nome: leadObj.name,
          Documento: leadObj.doc_id,
          Protocolo: leadObj.protocol,
          Empresa: leadObj.Company.name,
          Usuário: leadObj.User
            ? leadObj.User.UserProfile.name
            : 'Não atribuído',
          Classificação: leadObj.Classifier
            ? leadObj.Classifier.name
            : 'Não classificado',
          Status: leadObj.closed === 1 ? 'Fechado' : 'Aberto',
          Fonte: leadObj.Channel ? leadObj.Channel.name : '',
          Cliente: leadObj.Customer ? leadObj.Customer.name : '',
          Observações: leadObj.notes,
        };

        if (leadObj.LeadIvrOpenNewCanceled) {
          leadFormat.Telefone = formatPhoneBr(
            leadObj.LeadIvrOpenNewCanceled.phone
          );
          leadFormat.Duração = leadObj.LeadIvrOpenNewCanceled.call_time;
          leadFormat.Método_Reembolso =
            leadObj.LeadIvrOpenNewCanceled.metodo_reembolso;
          leadFormat.Banco = leadObj.LeadIvrOpenNewCanceled.banco;
          leadFormat.Conta = leadObj.LeadIvrOpenNewCanceled.conta;
          leadFormat.Titular_Conta =
            leadObj.LeadIvrOpenNewCanceled.titular_conta;
          leadFormat.Pix = leadObj.LeadIvrOpenNewCanceled.pix;
        }

        formatedExportData.push(leadFormat);
      });

      setData(response.data);
      setDataExport(formatedExportData);
    } catch (err) {
      setApiErrors(err);
      setData([]);
    } finally {
      setIsLoading(false);
    }
  }, [
    filterCompany,
    filterProtocol,
    filterDocId,
    filterUser,
    filterClassifiers,
    filterName,
    filterClosed,
  ]);

  const getFilter = useCallback(async () => {
    try {
      setIsLoading(true);

      const optionsCompanies = [{ value: '', label: '-- Selecione --' }];
      const optionsClassifiers = [{ value: '', label: '-- Selecione --' }];
      const optionsUsers = [{ value: '', label: '-- Selecione --' }];

      filterOptionsClosed.current = [
        { value: 0, label: 'Aberto' },
        { value: 1, label: 'Fechado' },
        { value: '', label: 'Todos' },
      ];

      const getCompanies = await axios.get(`/companies`);
      if (getCompanies) {
        getCompanies.data.forEach((company) => {
          optionsCompanies.push({
            value: company.id,
            label: company.name,
          });
        });
      }

      filterOptionsCompany.current = optionsCompanies;

      const getClassifiers = await axios.get(`/classifiers`);
      if (getClassifiers) {
        getClassifiers.data.forEach((classifier) => {
          optionsClassifiers.push({
            value: classifier.id,
            label: classifier.name,
          });
        });
      }

      filterOptionsClassifiers.current = optionsClassifiers;

      const getUsers = await axios.get(`/users`);
      if (getUsers) {
        getUsers.data.forEach((user) => {
          optionsUsers.push({
            value: user.id,
            label: user.UserProfile.name,
          });
        });
      }

      filterOptionsUser.current = optionsUsers;
    } catch (err) {
      setApiErrors(err);
    } finally {
      setIsLoading(false);
      setDataLoaded(true);
    }
  }, []);

  useEffect(() => {
    getFilter();
    getData();
  }, []);

  useEffect(() => {
    if (filterApplied) {
      getData();
      setFilterApplied(false); // Resetar o estado do filtro aplicado
    }
  }, [filterApplied]);

  const confirmDelete = useCallback(
    async (id) => {
      try {
        setIsLoading(true);
        await axios.delete(`${routeName}/${id}`);
        notification(`${dataName} removido com sucesso`, 'success');
        const updated = data.filter((channel) => channel.id !== id);
        setData(updated);
      } catch (err) {
        setApiErrors(err);
      } finally {
        setIsLoading(false);
      }
    },
    [setIsLoading, notification, data]
  );

  const handleDateChange = useCallback((date) => {
    const dateChange = date.start || date.end;

    if (!dateChange) {
      return;
    }

    const srcDate = new Date(dateChange);
    const formatedDate = moment(srcDate).format('YYYY-MM-DD');

    if (date.start) {
      filterStartDate.current = formatedDate;
    }

    if (date.end) {
      filterStartDate.current = formatedDate;
    }
  }, []);

  const tableData = useMemo(() => {
    return data.map((dataObject) => {
      const linksAction = [
        {
          onclick: () => {
            navigate(`${routeName}/${dataObject.id}/edit`);
          },
          faicon: 'faEdit',
          text: 'Detalhes',
        },
      ];

      if (userData.isadmin === 1) {
        linksAction.push({
          custom: (
            <ButtonModal
              onConfirm={() => confirmDelete(dataObject.id)}
              message={`Confirma remoção do ${dataName} ${dataObject.name}?`}
              buttonProps={{ text: 'Remover', faicon: 'faTrash' }}
              textModal={{
                title: `Remover ${dataName}`,
                textConfirm: 'Remover',
              }}
              dropdown
            />
          ),
        });
      }

      const closedName =
        dataObject.closed === 0 ? (
          <b className="text-success">Aberto</b>
        ) : (
          <b className="text-danger">Fechado</b>
        );

      return {
        ...dataObject,
        closed_name: (
          <div className="d-flex justify-content-between">
            <span>{closedName}</span>
            <CardHeaderMore links={linksAction} className="" />
          </div>
        ),
        company_name: dataObject.Company.name,
        channel_name: dataObject.Channel ? dataObject.Channel.name : '',
        cassification_name: dataObject.Classifier
          ? dataObject.Classifier.name
          : 'Não classificado',
        created_at: moment.utc(dataObject.created_at).format('DD/MM/YYYY'),
        user_name: dataObject.User
          ? dataObject.User.UserProfile.name
          : 'Não atribuído',
      };
    });
  }, [data, confirmDelete, userData]);

  return (
    <Container fluid className="px-lg-4 px-xl-5">
      <Loading isLoading={isLoading} />
      <Breadcrumbs title={titleName} />
      <div className="page-header d-flex justify-content-between align-items-center">
        <h1 className="page-heading">{titleName}</h1>
        <div>
          <Link to={`${routeName}/new`}>
            <Button variant="primary" className="text-uppercase">
              <FaPlus /> Cadastrar
            </Button>
          </Link>
        </div>
      </div>
      <section>
        {dataLoaded ? (
          <>
            <Row className="mb-1">
              <Col lg={12}>
                <h6 className="page-heading">
                  <b>
                    Datas :{' '}
                    {`${moment(filterStartDate.current).format('DD/MM/YYYY')} - ${moment(filterEndDate.current).format('DD/MM/YYYY')}`}
                  </b>
                  {filterCompany && (
                    <b className="ms-2">
                      | Empresa :{' '}
                      {filterOptionsCompany.current.find(
                        (company) =>
                          Number(company.value) === Number(filterCompany)
                      ) !== undefined
                        ? filterOptionsCompany.current.find(
                            (company) =>
                              Number(company.value) === Number(filterCompany)
                          ).label
                        : null}
                    </b>
                  )}
                  {filterClassifiers && (
                    <b className="ms-2">
                      | Classificação :{' '}
                      {filterOptionsClassifiers.current.find(
                        (classifier) =>
                          Number(classifier.value) === Number(filterClassifiers)
                      ) !== undefined
                        ? filterOptionsClassifiers.current.find(
                            (classifier) =>
                              Number(classifier.value) ===
                              Number(filterClassifiers)
                          ).label
                        : null}
                    </b>
                  )}
                  {filterUser && (
                    <b className="ms-2">
                      | Usuário :{' '}
                      {filterOptionsUser.current.find(
                        (user) => Number(user.value) === Number(filterUser)
                      ) !== undefined
                        ? filterOptionsUser.current.find(
                            (user) => Number(user.value) === Number(filterUser)
                          ).label
                        : null}
                    </b>
                  )}
                  {filterClosed !== '' && (
                    <b className="ms-2">
                      | Status do lead :{' '}
                      {filterOptionsClosed.current.find(
                        (status) =>
                          Number(status.value) === Number(filterClosed)
                      ) !== undefined
                        ? filterOptionsClosed.current.find(
                            (status) =>
                              Number(status.value) === Number(filterClosed)
                          ).label
                        : null}
                    </b>
                  )}
                  {filterProtocol && (
                    <b className="ms-2"> | Protocolo : {filterProtocol}</b>
                  )}
                  {filterDocId && (
                    <b className="ms-2"> | Documento : {filterDocId}</b>
                  )}
                </h6>
              </Col>
            </Row>
            <Card className="card-table mb-5">
              <ErrorProcessor error={apiErrors} />
              <DataTable
                items={tableData}
                columns={tableColumns}
                defaultPageSize={5}
                defaultSort="created_at"
                customActions={
                  <>
                    <ButtonModal
                      onConfirm={() => setFilterApplied(true)}
                      message={
                        <Row sm={12}>
                          <Col sm={12} className="mb-3">
                            <Form.Label>Datas:</Form.Label>
                            <DatepickerComponent
                              range
                              maxNumberOfDates={62}
                              defaultValue={{
                                start: moment(filterStartDate.current).format(
                                  'DD/MM/YYYY'
                                ),
                                end: moment(filterEndDate.current).format(
                                  'DD/MM/YYYY'
                                ),
                              }}
                              addProps={{
                                onChange: handleDateChange,
                              }}
                            />
                          </Col>
                          <Col sm={6} className="mb-2">
                            <Form.Label>Nome:</Form.Label>
                            <Form.Control
                              value={filterName}
                              onInput={(e) => {
                                setFilterName(e.target.value);
                              }}
                            />
                          </Col>
                          <Col sm={6} className="mb-2">
                            <Form.Label>Empresa:</Form.Label>
                            <Form.Control
                              value={filterCompany}
                              as="select"
                              onChange={(e) => {
                                setFilterCompany(e.target.value);
                              }}
                            >
                              {filterOptionsCompany.current.map((value) => (
                                <option key={v4()} value={value.value}>
                                  {value.label}
                                </option>
                              ))}
                            </Form.Control>
                          </Col>
                          <Col sm={6} className="mb-2">
                            <Form.Label>Protocolo:</Form.Label>
                            <Form.Control
                              value={filterProtocol}
                              onInput={(e) => {
                                setFilterProtocol(e.target.value);
                              }}
                            />
                          </Col>
                          <Col sm={6} className="mb-2">
                            <Form.Label>Documento:</Form.Label>
                            <Form.Control
                              value={filterDocId}
                              onInput={(e) => {
                                setFilterDocId(e.target.value);
                              }}
                            />
                          </Col>
                          <Col sm={6} className="mb-2">
                            <Form.Label>Usuário:</Form.Label>
                            <Form.Control
                              value={filterUser}
                              as="select"
                              onChange={(e) => {
                                setFilterUser(e.target.value);
                              }}
                            >
                              {filterOptionsUser.current.map((value) => (
                                <option key={v4()} value={value.value}>
                                  {value.label}
                                </option>
                              ))}
                            </Form.Control>
                          </Col>
                          <Col sm={6}>
                            <Form.Label>Classificação:</Form.Label>
                            <Form.Control
                              value={filterClassifiers}
                              as="select"
                              onChange={(e) => {
                                setFilterClassifiers(e.target.value);
                              }}
                            >
                              {filterOptionsClassifiers.current.map((value) => (
                                <option key={v4()} value={value.value}>
                                  {value.label}
                                </option>
                              ))}
                            </Form.Control>
                          </Col>
                          <Col sm={6}>
                            <Form.Label>Status do lead:</Form.Label>
                            <Form.Control
                              value={filterClosed}
                              as="select"
                              onChange={(e) => {
                                setFilterClosed(e.target.value);
                              }}
                            >
                              {filterOptionsClosed.current.map((value) => (
                                <option key={v4()} value={value.value}>
                                  {value.label}
                                </option>
                              ))}
                            </Form.Control>
                          </Col>
                        </Row>
                      }
                      buttonProps={{
                        variant: 'secondary',
                        className: 'shadow text-white me-2',
                        size: 20,
                        style: { height: '35px' },
                      }}
                      textModal={{
                        title: 'Filtrar pesquisa',
                        textConfirm: 'Filtrar',
                      }}
                    >
                      <FaFilter />
                      <b className="ms-2">Abrir Filtro</b>
                    </ButtonModal>
                    <ToXLSX data={dataExport} fileName="leads.xlsx" />
                  </>
                }
              />
            </Card>
          </>
        ) : null}
      </section>
    </Container>
  );
}
