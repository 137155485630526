import React, { useCallback, useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';

import Image from '../CustomImage';

export default function Dropzone({
  className,
  limitFiles,
  onChange,
  title,
  clear,
  titleDesc,
  acceptImage,
  acceptMedia,
  acceptDocument,
}) {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const onDrop = useCallback(
    (acceptedFiles) => {
      // Do something with the files
      const filesArr = [
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ];

      setUploadedFiles((prev) => {
        if (prev.length === 0) {
          return [...filesArr];
        }
        if (prev.length < limitFiles) {
          return [...prev, ...filesArr];
        }

        const lastImages = [...prev];
        lastImages.shift();

        return [...lastImages, ...filesArr];
      });
      onChange && onChange(filesArr);
    },
    [limitFiles, onChange]
  );

  useEffect(() => {
    if (clear) {
      setUploadedFiles([]);
    }
  }, [clear]);

  const acceptDefine = {};

  if (acceptImage) {
    acceptDefine['image/*'] = ['.jpeg', '.jpg', '.png'];
  }

  if (acceptMedia) {
    acceptDefine['audio/*'] = ['.mp3', '.wav', '.ogg', '.flac'];
    acceptDefine['video/*'] = ['.mp4', '.mvk'];
  }

  if (acceptDocument) {
    acceptDefine['application/pdf'] = ['.pdf'];
    acceptDefine['application/zip'] = ['.zip'];
    acceptDefine['application/msword'] = ['.doc'];
    acceptDefine['application/vnd.ms-excel'] = ['.xls'];
    acceptDefine[
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ] = ['.xlsx'];
    acceptDefine['text/csv'] = ['.csv'];
    acceptDefine['text/plain'] = ['.txt'];
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptDefine,
    onDrop,
  });

  return (
    <div
      {...getRootProps()}
      className={`dropzone ${className || ''}`}
      style={{ cursor: 'pointer' }}
    >
      <input {...getInputProps()} />

      <div className="dz-message">
        <p title="Clique ou arraste para carregar um arquivo.">{titleDesc}</p>
        <p>
          <span className="note">{title}</span>
        </p>
      </div>
      <Row className="mt-4">
        {uploadedFiles.map((file) => (
          <Col xs={6} md={4} lg={3} key={file.name}>
            <div
              style={{ aspectRatio: '1/1' }}
              className="position-relative rounded overflow-hidden shadow mb-4"
            >
              {file.type.startsWith('image/') ? (
                <Image
                  src={file.preview}
                  className="img-fluid"
                  layout="fill"
                  objectFit="cover"
                />
              ) : (
                <div
                  style={{
                    aspectRatio: '1/1',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#f0f0f0',
                    color: '#333',
                    fontSize: '18px',
                    fontWeight: 'bold',
                    padding: '10px',
                    margin: '10px',
                    textAlign: 'center',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  <div className="subtitle text-gray-500">{`${file.name.split('.').pop()}`}</div>
                  <div title={file.name} className="text-muted text-sm">
                    {file.name.length > 10
                      ? `${file.name.substring(0, 9)}...`
                      : file.name}
                  </div>
                </div>
              )}
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
}

Dropzone.defaultProps = {
  className: '',
  limitFiles: 1,
  onChange: () => {},
  title: '',
  clear: false,
  titleDesc: 'Clique ou arraste para carregar um arquivo.',
  acceptImage: true,
  acceptMedia: false,
  acceptDocument: false,
};

Dropzone.propTypes = {
  className: PropTypes.string,
  limitFiles: PropTypes.number,
  onChange: PropTypes.func,
  title: PropTypes.string,
  clear: PropTypes.bool,
  titleDesc: PropTypes.string || PropTypes.node,
  acceptImage: PropTypes.bool,
  acceptMedia: PropTypes.bool,
  acceptDocument: PropTypes.bool,
};
