import React, { useState, useEffect, useCallback } from 'react';
import { Container, Card, Row, Col } from 'react-bootstrap';
import { get } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import Loading from '../../components/Loading';
import axios from '../../services/axios';
import Breadcrumbs from '../../components/Breadcrumbs';
import { useNotification } from '../../components/Notification/NotificationContext';
import Forms from '../../components/Forms';
import ErrorProcessor from '../../components/ErrorProcessor';
import PageHeader from '../../components/PageHeader';

export default function User() {
  const routeName = '/companies';
  const titleName = 'Empresas';
  const dataName = 'Empresa';
  const [dataLoaded, setDataLoaded] = useState(false);
  const [apiErrors, setApiErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const notification = useNotification();
  const navigate = useNavigate();
  const params = useParams();
  const id = get(params, 'id', '');
  const [formValues, setFormValues] = useState({
    name: '',
  });

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, 'Nome deve conter no mínimo 3 caracteres.')
      .required('Nome obrigatório.'),
  });

  const handleFieldChange = useCallback((fieldName, value) => {
    setFormValues((prevValues) => ({ ...prevValues, [fieldName]: value }));
  }, []);

  const fields = [
    {
      name: 'name',
      id: 'name',
      label: 'Empresa',
      type: 'text',
      onChange: (e) => handleFieldChange('name', e.target.value),
      colSize: 4,
      additionalProps: {},
    },
  ];

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      let action = 'cadastrado';

      if (id) {
        await axios.put(`${routeName}/${id}`, formValues);
        action = 'atualizado';
      } else {
        await axios.post(routeName, formValues);
      }
      navigate(routeName);
      notification(`${dataName} ${action} com sucesso`, 'success');
    } catch (err) {
      setApiErrors(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!id) return;
    async function getData() {
      try {
        setIsLoading(true);
        const { data } = await axios.get(`${routeName}/${id}`);
        setFormValues({
          ...data,
        });
      } catch (err) {
        setApiErrors(err);
      } finally {
        setIsLoading(false);
        setDataLoaded(true);
      }
    }

    getData();
  }, [id]);

  return (
    <Container fluid className="px-lg-4 px-xl-5">
      <Loading isLoading={isLoading} />
      <Breadcrumbs
        title={id ? 'Atualizar' : 'Cadastrar'}
        pages={[{ link: routeName, name: 'Empresas' }]}
      />
      <PageHeader title={titleName} />
      <section>
        <Row>
          <Col lg={12}>
            <Card className="mb-5">
              <Card.Header>
                <h4 className="card-heading">
                  {id ? 'Atualizar' : 'Cadastrar'}
                </h4>
              </Card.Header>
              <Card.Body>
                <ErrorProcessor error={apiErrors} />
                {!id || dataLoaded ? (
                  <Forms
                    initialValues={formValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    fields={fields}
                    btnSubmit={{
                      variant: 'primary',
                      type: 'submit',
                      text: id ? 'Atualizar' : 'Cadastrar',
                    }}
                  />
                ) : null}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </section>
    </Container>
  );
}
