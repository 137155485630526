/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import { Container, Row, Col, Button, Form, Table } from 'react-bootstrap';
import axios from '../../services/axios';
import { useNotification } from '../../components/Notification/NotificationContext';

export default function ApiForm() {
  const notification = useNotification();

  const [url, setUrl] = useState('');
  const [method, setMethod] = useState('POST');
  const [bodyType, setBodyType] = useState('x-www-form-urlencoded'); // Default body type
  const [rawBodyContent, setRawBodyContent] = useState(''); // For raw body
  const [formUrlencodedBody, setFormUrlencodedBody] = useState([
    { key: '', value: '' },
  ]); // For x-www-form-urlencoded key-value pairs

  // Handle form-url-encoded key-value pair changes
  const handleFormUrlencodedChange = (index, field, value) => {
    const updatedBody = [...formUrlencodedBody];
    updatedBody[index][field] = value;
    setFormUrlencodedBody(updatedBody);
  };

  // Add a new row for key-value pair
  const addKeyValuePair = () => {
    setFormUrlencodedBody([...formUrlencodedBody, { key: '', value: '' }]);
  };

  // Remove a row for key-value pair
  const removeKeyValuePair = (index) => {
    const updatedBody = formUrlencodedBody.filter((_, idx) => idx !== index);
    setFormUrlencodedBody(updatedBody);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const apiData = {
      url,
      method,
      body: bodyType === 'raw' ? rawBodyContent : formUrlencodedBody,
      bodyType,
    };

    try {
      await axios.post('/ligacoes', apiData);
      notification('API cadastrada com sucesso!', 'success');
    } catch (err) {
      notification('Erro ao cadastrar API', 'error');
    }
  };

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col md={6}>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="apiUrl">
              <Form.Label>URL da API</Form.Label>
              <Form.Control
                type="text"
                placeholder="https://url.api.ligacoes/"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="apiMethod" className="mt-3">
              <Form.Label>Método</Form.Label>
              <Form.Control
                as="select"
                value={method}
                onChange={(e) => setMethod(e.target.value)}
              >
                <option value="POST">POST</option>
                <option value="GET">GET</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="bodyType" className="mt-3">
              <Form.Label>Tipo de Body</Form.Label>
              <Form.Control
                as="select"
                value={bodyType}
                onChange={(e) => setBodyType(e.target.value)}
              >
                <option value="x-www-form-urlencoded">
                  x-www-form-urlencoded
                </option>
                <option value="raw">raw</option>
              </Form.Control>
            </Form.Group>

            {bodyType === 'raw' ? (
              <Form.Group controlId="rawBodyContent" className="mt-3">
                <Form.Label>Body da Requisição (JSON)</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  placeholder={`{
  "token": "$1$CfIZKh0t$GMjgjP0dAN8dMoBaBSsec.",
  "tipo": "outbound",
  "origem": "{$ramal}",
  "destino": "{$telefone}",
  "categoria": "1"
}`}
                  value={rawBodyContent}
                  onChange={(e) => setRawBodyContent(e.target.value)}
                />
              </Form.Group>
            ) : (
              <>
                <Form.Label className="mt-3">Body da Requisição</Form.Label>
                <Table bordered hover>
                  <thead>
                    <tr>
                      <th>Key</th>
                      <th>Value</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formUrlencodedBody.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <Form.Control
                            type="text"
                            placeholder="Digite a chave"
                            value={item.key}
                            onChange={(e) =>
                              handleFormUrlencodedChange(
                                index,
                                'key',
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <Form.Control
                            type="text"
                            placeholder="Digite o valor"
                            value={item.value}
                            onChange={(e) =>
                              handleFormUrlencodedChange(
                                index,
                                'value',
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <Button
                            variant="danger"
                            onClick={() => removeKeyValuePair(index)}
                          >
                            Remover
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <div className="d-flex justify-content-between mt-2">
                  <Button variant="primary" onClick={addKeyValuePair}>
                    Adicionar chave-valor
                  </Button>
                  <Button variant="primary" type="submit">
                    Salvar API
                  </Button>
                </div>
              </>
            )}
          </Form>
        </Col>
      </Row>
    </Container>
  );
}
