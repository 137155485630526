import React from 'react';
import PropTypes from 'prop-types';

export default function Icon({ className, style, icon }) {
  return (
    <svg className={`svg-icon ${className || ''}`} style={style}>
      <use xlinkHref={`#${icon}`} />
    </svg>
  );
}

Icon.defaultProps = {
  className: '',
  style: {},
};

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.shape({}),
};
