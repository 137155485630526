/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Container, Card, Row, Col } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import Loading from '../../components/Loading';
import axios from '../../services/axios';
import Breadcrumbs from '../../components/Breadcrumbs';
import { useNotification } from '../../components/Notification/NotificationContext';
import ErrorProcessor from '../../components/ErrorProcessor';
import PageHeader from '../../components/PageHeader';

export default function Customer() {
  const routeName = '/customers';
  const dataName = 'Cliente';
  const titleName = 'Clientes';
  const [dataLoaded, setDataLoaded] = useState(false);
  const [apiErrors, setApiErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const notification = useNotification();
  const navigate = useNavigate();
  const { id } = useParams();

  const [formValues, setFormValues] = useState({
    notes: '',
    cpfCnpj: '',
    razaoSocial: '',
    naturezaJuridica: '',
    situacaoCadastral: '',
    tipoLogradouro: '',
    logradouro: '',
    numero: '',
    complemento: '',
    bairro: '',
    cep: '',
    type_person: '',
  });

  const validationSchema = Yup.object().shape({
    cpfCnpj: Yup.string()
      .min(14, 'CNPJ deve conter 14 caracteres.')
      .required('CNPJ obrigatório.'),
    type_person: Yup.string().required('Tipo de pessoa obrigatória.'),
  });

  const handleFieldChange = (fieldName, value) => {
    setFormValues((prevValues) => ({ ...prevValues, [fieldName]: value }));
  };

  const handleCnpjChange = async (e) => {
    const cpfCnpj = e.target.value;
    setFormValues((prevValues) => ({ ...prevValues, cpfCnpj }));

    if (cpfCnpj.length === 14) {
      try {
        setIsLoading(true);
        const { data } = await axios.get(
          `https://publica.cnpj.ws/cnpj/${cpfCnpj}`
        );
        const updatedValues = {
          razaoSocial: data.razao_social || '',
          naturezaJuridica: data.natureza_juridica.descricao || '',
          situacaoCadastral: data.estabelecimento.situacao_cadastral || '',
          tipoLogradouro: data.estabelecimento.tipo_logradouro || '',
          logradouro: data.estabelecimento.logradouro || '',
          numero: data.estabelecimento.numero || '',
          complemento: data.estabelecimento.complemento || '',
          bairro: data.estabelecimento.bairro || '',
          cep: data.estabelecimento.cep || '',
        };

        setFormValues((prevValues) => ({
          ...prevValues,
          ...updatedValues,
        }));

        notification('Dados preenchidos automaticamente', 'success');
      } catch (error) {
        console.error('Error fetching CNPJ data:', error);
        setApiErrors(error);
        notification('Erro ao buscar dados do CNPJ', 'error');
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        try {
          setIsLoading(true);
          const { data } = await axios.get(`${routeName}/${id}`);
          setFormValues({
            notes: data.notes || '',
            cpfCnpj: data.cpfCnpj || '',
            razaoSocial: data.razaoSocial || '',
            naturezaJuridica: data.naturezaJuridica || '',
            situacaoCadastral: data.situacaoCadastral || '',
            tipoLogradouro: data.tipoLogradouro || '',
            logradouro: data.logradouro || '',
            numero: data.numero || '',
            complemento: data.complemento || '',
            bairro: data.bairro || '',
            cep: data.cep || '',
            type_person: data.type_person || '',
          });
          setDataLoaded(true);
        } catch (error) {
          console.error('Error fetching customer data:', error);
          setApiErrors(error);
        } finally {
          setIsLoading(false);
        }
      };
      fetchData();
    } else {
      setDataLoaded(true);
    }
  }, [id]);

  useEffect(() => {}, [formValues]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      let action = 'cadastrado';

      if (id) {
        await axios.put(`${routeName}/${id}`, formValues);
        action = 'atualizado';
      } else {
        await axios.post(routeName, formValues);
      }
      navigate(routeName);
      notification(`${dataName} ${action} com sucesso`, 'success');
    } catch (err) {
      setApiErrors(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container fluid className="px-lg-4 px-xl-5">
      <Loading isLoading={isLoading} />
      <Breadcrumbs
        title={id ? 'Atualizar' : 'Cadastrar'}
        pages={[{ link: routeName, name: titleName }]}
      />
      <PageHeader title={titleName} />
      <section>
        <Row>
          <Col lg={12}>
            <Card className="mb-5">
              <Card.Header>
                <h4 className="card-heading">
                  {id ? `Atualizar ${dataName}` : `Cadastrar ${dataName}`}
                </h4>
              </Card.Header>
              <Card.Body>
                <ErrorProcessor error={apiErrors} />
                {dataLoaded ? (
                  <form onSubmit={handleSubmit}>
                    <Row>
                      <Col md={4}>
                        <div className="form-group">
                          <label htmlFor="type_person">Tipo de pessoa</label>
                          <select
                            id="type_person"
                            className="form-control"
                            value={formValues.type_person}
                            onChange={(e) =>
                              handleFieldChange('type_person', e.target.value)
                            }
                          >
                            <option value="">-- Selecione --</option>
                            <option value="fisica">Física</option>
                            <option value="juridica">Jurídica</option>
                          </select>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="form-group">
                          <label htmlFor="cpfCnpj">CPF ou CNPJ</label>
                          <input
                            type="text"
                            id="cpfCnpj"
                            className="form-control"
                            value={formValues.cpfCnpj}
                            onChange={handleCnpjChange}
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="form-group">
                          <label htmlFor="razaoSocial">Razão Social</label>
                          <input
                            type="text"
                            id="razaoSocial"
                            className="form-control"
                            value={formValues.razaoSocial}
                            readOnly
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="form-group">
                          <label htmlFor="naturezaJuridica">
                            Natureza Jurídica
                          </label>
                          <input
                            type="text"
                            id="naturezaJuridica"
                            className="form-control"
                            value={formValues.naturezaJuridica}
                            readOnly
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="form-group">
                          <label htmlFor="situacaoCadastral">
                            Situação Cadastral
                          </label>
                          <input
                            type="text"
                            id="situacaoCadastral"
                            className="form-control"
                            value={formValues.situacaoCadastral}
                            readOnly
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="form-group">
                          <label htmlFor="tipoLogradouro">
                            Tipo Logradouro
                          </label>
                          <input
                            type="text"
                            id="tipoLogradouro"
                            className="form-control"
                            value={formValues.tipoLogradouro}
                            readOnly
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="form-group">
                          <label htmlFor="logradouro">Logradouro</label>
                          <input
                            type="text"
                            id="logradouro"
                            className="form-control"
                            value={formValues.logradouro}
                            readOnly
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="form-group">
                          <label htmlFor="numero">Número</label>
                          <input
                            type="text"
                            id="numero"
                            className="form-control"
                            value={formValues.numero}
                            readOnly
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="form-group">
                          <label htmlFor="complemento">Complemento</label>
                          <input
                            type="text"
                            id="complemento"
                            className="form-control"
                            value={formValues.complemento}
                            readOnly
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="form-group">
                          <label htmlFor="bairro">Bairro</label>
                          <input
                            type="text"
                            id="bairro"
                            className="form-control"
                            value={formValues.bairro}
                            readOnly
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="form-group">
                          <label htmlFor="cep">CEP</label>
                          <input
                            type="text"
                            id="cep"
                            className="form-control"
                            value={formValues.cep}
                            readOnly
                          />
                        </div>
                      </Col>
                      <Col md={20}>
                        <div className="form-group">
                          <label htmlFor="notes">Informações do cliente</label>
                          <textarea
                            id="notes"
                            className="form-control"
                            value={formValues.notes}
                            onChange={(e) =>
                              handleFieldChange('notes', e.target.value)
                            }
                          />
                        </div>
                      </Col>
                    </Row>
                    <button type="submit" className="btn btn-primary">
                      {id ? 'Atualizar' : 'Cadastrar'}
                    </button>
                  </form>
                ) : (
                  <p>Carregando dados...</p>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </section>
    </Container>
  );
}
