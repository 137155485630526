import React, { useState, useEffect } from 'react';
import { Container, Card, Table, Button, Modal, Form } from 'react-bootstrap';
import axios from '../../services/axios';
import Loading from '../../components/Loading';
import Breadcrumbs from '../../components/Breadcrumbs';

export default function PriorityList() {
  const [stages, setStages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentPriority, setCurrentPriority] = useState(null);
  const [newStages, setnewStages] = useState({
    name: '',
    atendimentoVencimento: '',
    solucaoVencimento: '',
  });

  const fetchStages = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/stages');
      setStages(response.data);
    } catch (error) {
      console.error('Erro ao carregar prioridades:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStages();
  }, []);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setShowModal(false);
    setEditMode(false);
    setnewStages({
      name: '',
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setnewStages({ ...newStages, [name]: value });
  };

  const handleSaveStages = async () => {
    setLoading(true);
    try {
      if (editMode && currentPriority) {
        await axios.put(`/stages/${currentPriority.id}`, newStages);
      } else {
        await axios.post('/stages', newStages);
      }
      fetchStages();
      handleCloseModal();
    } catch (error) {
      console.error('Erro ao salvar estágio:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (stage) => {
    setCurrentPriority(stage);
    setnewStages({
      name: stage.name,
    });
    setEditMode(true);
    handleShowModal();
  };

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      await axios.delete(`/stages/${id}`);
      fetchStages();
    } catch (error) {
      console.error('Erro ao deletar prioridade:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container fluid className="px-lg-4 px-xl-5">
      <Breadcrumbs title="Estágios" />
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <h1 className="page-heading">Estágios</h1>
          <Button variant="success" onClick={handleShowModal}>
            + Estágios
          </Button>
        </Card.Header>
        <Card.Body>
          {loading && <Loading />}
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Nome</th>
              </tr>
            </thead>
            <tbody>
              {stages.map((priority) => (
                <tr key={priority.id}>
                  <td>{priority.name}</td>
                  <td>
                    <Button
                      variant="primary"
                      className="me-2"
                      onClick={() => handleEdit(priority)}
                    >
                      Editar
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => handleDelete(priority.id)}
                    >
                      Remover
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {editMode ? 'Editar prioridade' : 'Adicionar prioridade'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Nome</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={newStages.name}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancelar
          </Button>
          <Button variant="success" onClick={handleSaveStages}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}
