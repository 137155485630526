import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

export default function ConfirmationModal({
  show,
  handleClose,
  handleConfirm,
  message,
  title,
  textCancel,
  textConfirm,
}) {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton className="border-0 bg-gray-100">
        <Modal.Title as="h5" className="text-uppercase">
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{message}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {textCancel}
        </Button>
        <Button variant="primary" onClick={handleConfirm}>
          {textConfirm}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ConfirmationModal.defaultProps = {
  title: 'Confirmação',
  textCancel: 'Cancelar',
  textConfirm: 'Confirmar',
  message: null,
};

ConfirmationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  title: PropTypes.string,
  textCancel: PropTypes.string,
  textConfirm: PropTypes.string,
};
