import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';
import { Container, Card, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import axios from '../../services/axios';
import Loading from '../../components/Loading';
import Breadcrumbs from '../../components/Breadcrumbs';
import DataTable from '../../components/DataTable';
import ButtonModal from '../../components/ModalConfirmation/ButtonModal';
import { useNotification } from '../../components/Notification/NotificationContext';
import ErrorProcessor from '../../components/ErrorProcessor';
import CardHeaderMore from '../../components/Cards/CardHeaderMore';

export default function Accesses() {
  const routeName = '/departaments';
  const dataName = 'Departament';
  const titleName = 'Departamentos';
  const navigate = useNavigate();
  const [apiErrors, setApiErrors] = useState({});
  const notification = useNotification();
  const [accesses, setAccesses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const userData = useSelector((state) => state.auth.user);
  const tableColumns = useMemo(
    () => [
      { Header: 'Departamentos', accessor: 'name' },
      { Header: 'Agentes', accessor: 'module', noSearch: true },
    ],
    []
  );

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const responseDepartments = await axios.get('/departaments');
        const responseUserDepartments = await axios.get('/userdpto/');

        const userCountByDepartment = responseUserDepartments.data.reduce(
          (acc, userDept) => {
            acc[userDept.departmentId] = (acc[userDept.departmentId] || 0) + 1;
            return acc;
          },
          {}
        );

        const departmentsWithUserCounts = responseDepartments.data.map(
          (dept) => ({
            ...dept,
            userCount: userCountByDepartment[dept.id] || 0,
          })
        );

        setAccesses(departmentsWithUserCounts);
      } catch (error) {
        console.error('Erro ao carregar dados:', error);
        setApiErrors(error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchData();
  }, []);

  const confirmDelete = useCallback(
    async (id) => {
      try {
        setIsLoading(true);
        await axios.delete(`${routeName}/${id}`);
        notification(`${dataName} removido com sucesso`, 'success');
        const updatedAccesses = accesses.filter((access) => access.id !== id);
        setAccesses(updatedAccesses);
      } catch (err) {
        setApiErrors(err);
      } finally {
        setIsLoading(false);
      }
    },
    [accesses, notification]
  );

  const tableData = useMemo(
    () =>
      accesses.map((department) => {
        const linksAction = [
          {
            onclick: () => navigate(`${routeName}/${department.id}/edit`),
            faicon: 'faEdit',
            text: 'Atualizar',
          },
        ];

        if (userData.isadmin === 1) {
          linksAction.push({
            custom: (
              <ButtonModal
                onConfirm={() => confirmDelete(department.id)}
                message={`Confirma remoção do ${dataName} ${department.name}?`}
                buttonProps={{ text: 'Remover', faicon: 'faTrash' }}
                textModal={{
                  title: `Remover ${dataName}`,
                  textConfirm: 'Remover',
                }}
                dropdown
              />
            ),
          });
        }

        const moduleCount = department.userCount;
        return {
          module: (
            <div className="d-flex justify-content-between">
              <div>
                <Link to={`${routeName}/${department.id}/module`}>
                  <Button
                    variant="success"
                    className="rounded-pill shadow text-white"
                    size="sm"
                  >
                    <b>{moduleCount}</b>
                  </Button>
                </Link>
              </div>
              <CardHeaderMore links={linksAction} className="" />
            </div>
          ),
          ...department,
        };
      }),
    [accesses, confirmDelete, navigate, userData.isadmin]
  );

  return (
    <Container fluid className="px-lg-4 px-xl-5">
      <Loading isLoading={isLoading} />
      <Breadcrumbs title="Departamentos" />
      <div className="page-header d-flex justify-content-between align-items-center">
        <h1 className="page-heading">{titleName}</h1>

        <div>
          <Link to={`${routeName}/new`}>
            <Button variant="primary" className="text-uppercase">
              <FaPlus /> Cadastrar
            </Button>
          </Link>
        </div>
      </div>
      <section>
        <Card className="card-table mb-5">
          <ErrorProcessor error={apiErrors} />
          <DataTable
            items={tableData}
            columns={tableColumns}
            defaultPageSize={5}
          />
        </Card>
      </section>
    </Container>
  );
}
