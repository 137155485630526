import React, { useState, useEffect } from 'react';
import {
  Container,
  Card,
  Row,
  Col,
  Form,
  Button,
  Tabs,
  Tab,
  Spinner,
} from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from '../../services/axios';
import Loading from '../../components/Loading';
import Breadcrumbs from '../../components/Breadcrumbs';

export default function PreticketsAdd() {
  const userData = useSelector((state) => state.auth.user);
  const { state } = useLocation();
  const {
    email,
    title: preticketTitle,
    message: preticketMessage,
    files: preticketFiles,
    id: preticketId, // Adicionando o ID do pré-ticket para exclusão posterior
  } = state || {};

  // Habilite o módulo de imagens no ReactQuill
  const modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['bold', 'italic', 'underline'],
      ['link', 'image'], // Inclui opção de inserir imagens
      ['clean'],
    ],
  };

  const [isSubmitting, setIsSubmitting] = useState(false); // Estado para controle do loading
  const [dataLoaded, setDataLoaded] = useState(false);
  const [selectedRequesterId, setSelectedRequesterId] = useState('');
  const [selectedCompany, setSelectedCompany] = useState(userData.empresa_id);
  const [clientCommunication, setClientCommunication] = useState(
    preticketMessage || ''
  );
  const [serviceDesks, setServiceDesks] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [priorityOptions, setPriorityOptions] = useState([]);
  const [stageOptions, setStageOptions] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [selectedPriority, setSelectedPriority] = useState('');
  const [selectedStage, setSelectedStage] = useState('');
  const [title, setTitle] = useState(preticketTitle || '');
  const [errors] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    let parsedFiles = [];
    if (preticketFiles) {
      try {
        // Verificar se preticketFiles é uma string JSON válida antes de fazer o parse
        parsedFiles =
          typeof preticketFiles === 'string'
            ? JSON.parse(preticketFiles)
            : preticketFiles;
        if (!Array.isArray(parsedFiles)) {
          parsedFiles = []; // Se o resultado não for um array, defina-o como array vazio
        }
      } catch (error) {
        console.error('Erro ao fazer o parse de preticketFiles:', error);
        parsedFiles = []; // Em caso de erro, assegure que parsedFiles seja um array vazio
      }
    }

    const loadRequesterInfo = async () => {
      try {
        if (!email) {
          console.error('Email do solicitante não encontrado.');
          navigate('/pretickets');
          return;
        }

        const response = await axios.get(`/requester-info/${email}`);
        const requesterData = response.data[0];

        if (!requesterData) {
          console.error(
            'Solicitante não encontrado. Redirecionando para criar novo solicitante.'
          );
          navigate('/requesters');
          return;
        }

        setSelectedRequesterId(requesterData.id_requesters);
        setSelectedCompany(requesterData.id_customers);

        const serviceDeskResponse = await axios.get(
          `/servicedesks/customer/${requesterData.id_customers}`
        );
        setServiceDesks(serviceDeskResponse.data);

        if (serviceDeskResponse.data.length > 0) {
          const {
            departments: deptOptions,
            priorities: prioOptions,
            stages: stageOpts,
          } = serviceDeskResponse.data[0];
          setDepartmentOptions(deptOptions);
          setPriorityOptions(prioOptions);
          setStageOptions(stageOpts);
        }

        setDataLoaded(true);
      } catch (error) {
        console.error('Erro ao buscar informações do solicitante:', error);
      }
    };

    loadRequesterInfo();

    // Adiciona as imagens ao conteúdo da comunicação do cliente, se houver
    if (parsedFiles.length > 0) {
      const imageTags = parsedFiles
        .map(
          (file) =>
            `<img src="${axios.defaults.baseURL}${file}" alt="Anexo" style="max-width: 100%; height: auto;" />`
        )
        .join('\n');
      setClientCommunication((prev) => `${prev}\n${imageTags}`);
    }
  }, [email, navigate, state, preticketFiles, preticketMessage]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Ativar o loading

    try {
      const ticketData = {
        requesterId: selectedRequesterId,
        company: selectedCompany,
        title,
        assignedTo: userData.id,
        serviceDesk: serviceDesks[0].id || '', // Acessa o primeiro service desk, se existir
        status: 'aberto',
        department: selectedDepartment,
        priority: selectedPriority,
        stages: selectedStage,
      };

      const formData = new FormData();
      formData.append('ticketData', JSON.stringify(ticketData));

      // Adicionar a comunicação do cliente e anexos ao formulário
      let clientMessage = clientCommunication;

      // Verificação adicional para garantir que preticketFiles seja um array
      if (Array.isArray(preticketFiles) && preticketFiles.length > 0) {
        clientMessage += `\n\nArquivos anexados:\n${preticketFiles
          .map((file) => `${axios.defaults.baseURL}${file}`)
          .join('\n')}`;
      }

      formData.append('clientCommunication', clientMessage);

      const ticketResponse = await axios.post('/tickets', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const ticketId = ticketResponse.data.id;

      await axios.post('/communicationHistory', {
        ticketId,
        userId: userData.id,
        clientMessage: clientCommunication,
        type: 'client',
      });

      await axios.delete(`/pretickets/${preticketId}`);

      navigate('/tickets');
    } catch (error) {
      console.error('Erro ao criar o ticket:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!dataLoaded) {
    return <Loading />;
  }

  return (
    <Container fluid className="px-lg-4 px-xl-15">
      <Breadcrumbs
        title="Pré Tickets"
        pages={[{ name: ' Pré Tickets', link: '/pretickets' }]}
      />
      <Card>
        <Card.Header>
          <Tabs
            defaultActiveKey="clientCommunication"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="clientCommunication" title="Comunicação com Cliente">
              <ReactQuill
                theme="snow"
                value={clientCommunication}
                onChange={setClientCommunication}
                modules={modules} // Passa o módulo para aceitar imagens
              />
            </Tab>
          </Tabs>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>Título</Form.Label>
                  <Form.Control
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    isInvalid={!!errors.title}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.title}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            {/* Selecionar Departamento */}
            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>Departamento</Form.Label>
                  <Form.Select
                    value={selectedDepartment}
                    onChange={(e) => setSelectedDepartment(e.target.value)}
                  >
                    <option value="">--Selecione--</option>
                    {departmentOptions.map((department) => (
                      <option key={department.id} value={department.id}>
                        {department.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>

              {/* Selecionar Prioridade */}
              <Col>
                <Form.Group>
                  <Form.Label>Prioridade</Form.Label>
                  <Form.Select
                    value={selectedPriority}
                    onChange={(e) => setSelectedPriority(e.target.value)}
                  >
                    <option value="">--Selecione--</option>
                    {priorityOptions.map((priority) => (
                      <option key={priority.id} value={priority.id}>
                        {priority.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>

              {/* Selecionar Estágio */}
              <Col>
                <Form.Group>
                  <Form.Label>Estágio</Form.Label>
                  <Form.Select
                    value={selectedStage}
                    onChange={(e) => setSelectedStage(e.target.value)}
                  >
                    <option value="">--Selecione--</option>
                    {stageOptions.map((stage) => (
                      <option key={stage.id} value={stage.id}>
                        {stage.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Button type="submit" disabled={isSubmitting} className="mt-2">
              {isSubmitting ? (
                <Spinner animation="border" size="sm" />
              ) : (
                'Enviar'
              )}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
}
