import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import store, { persistor } from './store';
import GlobalStyles from './styles/GlobalStyles';
import AppRoutes from './routes';
import SvgIcons from './components/SvgIcons';
import HeaderLocation from './components/Header/HeaderLocation';
import SidebarLocation from './components/SideBar/SideBarLocation';
import FooterLocation from './components/Footer/FooterLocation';
import ContentPages from './components/ContentPages';
import { NotificationProvider } from './components/Notification/NotificationContext';

function App() {
  const [sidebarShrink, setSidebarShrink] = useState(false);
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <BrowserRouter>
          <NotificationProvider>
            <div>
              <Helmet>
                <title>VoipDesk | Open New</title>
                <link rel="icon" href="/img/icons/opennewIcon.png" />
              </Helmet>
              <HeaderLocation
                setSidebarShrink={setSidebarShrink}
                sidebarShrink={sidebarShrink}
              />
              <div className="d-flex align-items-stretch">
                <SidebarLocation sidebarShrink={sidebarShrink} />
                <ContentPages>
                  <AppRoutes />
                  <FooterLocation />
                </ContentPages>
              </div>
              <GlobalStyles />
              <SvgIcons />
            </div>
          </NotificationProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
