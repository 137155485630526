import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import Breadcrumbs from '../../components/Breadcrumbs';
import CardMain from '../../components/Cards/CardMain';

export default function Page403() {
  const customText = (
    <span className="fs-3">
      Você não tem autorização para acessar esta página.
    </span>
  );
  return (
    <Container fluid className="px-lg-4 px-xl-5">
      <Breadcrumbs title="Não autorizado" />
      <section>
        <Row>
          <Col md={12} lg={12}>
            <CardMain
              className="mb-4"
              subtitle="Support card subtitle"
              img="/img/unauthorized.jpg"
              text={customText}
              imgStyle={{ maxHeight: '300px' }}
              button={{
                variant: 'primary',
                label: 'Voltar',
                link: '/',
                goBack: 2,
              }}
            />
          </Col>
        </Row>
      </section>
    </Container>
  );
}
