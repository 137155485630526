// HeaderWithLocation.js
import React from 'react';
import { useLocation } from 'react-router-dom';
import Header from './index';
import * as pagesConfig from '../../config/pages';

export default function HeaderLocation(props) {
  const location = useLocation();

  if (pagesConfig.noHeaderPages.includes(location.pathname)) {
    return null;
  }

  return <Header {...props} />;
}
