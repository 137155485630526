import React, { useState, useEffect } from 'react';
import {
  Container,
  Card,
  Table,
  Button,
  Modal,
  Form,
  Row,
  Col,
} from 'react-bootstrap';
import axios from '../../services/axios';
import Loading from '../../components/Loading';
import Breadcrumbs from '../../components/Breadcrumbs';

export default function PriorityList() {
  const [priorities, setPriorities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentPriority, setCurrentPriority] = useState(null);
  const [newPriority, setNewPriority] = useState({
    name: '',
    atendimentoVencimento: '',
    solucaoVencimento: '',
  });

  const fetchPriorities = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/priorities');
      setPriorities(response.data);
    } catch (error) {
      console.error('Erro ao carregar prioridades:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPriorities();
  }, []);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setShowModal(false);
    setEditMode(false);
    setCurrentPriority(null);
    setNewPriority({
      name: '',
      atendimentoVencimento: '',
      solucaoVencimento: '',
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewPriority({ ...newPriority, [name]: value });
  };

  const handleSavePriority = async () => {
    setLoading(true);
    try {
      if (editMode && currentPriority) {
        await axios.put(`/priorities/${currentPriority.id}`, newPriority);
      } else {
        await axios.post('/priorities', newPriority);
      }
      fetchPriorities();
      handleCloseModal();
    } catch (error) {
      console.error('Erro ao salvar prioridade:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (priority) => {
    setCurrentPriority(priority);
    setNewPriority({
      name: priority.name,
      atendimentoVencimento: priority.atendimentoVencimento,
      solucaoVencimento: priority.solucaoVencimento,
    });
    setEditMode(true);
    handleShowModal();
  };

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      await axios.delete(`/priorities/${id}`);
      fetchPriorities();
    } catch (error) {
      console.error('Erro ao deletar prioridade:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container fluid className="px-lg-4 px-xl-5">
      <Breadcrumbs title="Prioridades" />
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <h1 className="page-heading">Prioridades</h1>
          <Button variant="success" onClick={handleShowModal}>
            + Prioridade
          </Button>
        </Card.Header>
        <Card.Body>
          {loading && <Loading />}
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Nome</th>
                <th>Vencimento de atendimento</th>
                <th>Vencimento de solução</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {priorities.map((priority) => (
                <tr key={priority.id}>
                  <td>{priority.name}</td>
                  <td>{priority.atendimentoVencimento}</td>
                  <td>{priority.solucaoVencimento}</td>
                  <td>
                    <Button
                      variant="primary"
                      className="me-2"
                      onClick={() => handleEdit(priority)}
                    >
                      Editar
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => handleDelete(priority.id)}
                    >
                      Remover
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {editMode ? 'Editar prioridade' : 'Adicionar prioridade'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Nome</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={newPriority.name}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Vencimento de atendimento</Form.Label>
                  <Form.Control
                    type="time"
                    name="atendimentoVencimento"
                    value={newPriority.atendimentoVencimento}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Vencimento de solução</Form.Label>
                  <Form.Control
                    type="time"
                    name="solucaoVencimento"
                    value={newPriority.solucaoVencimento}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancelar
          </Button>
          <Button variant="success" onClick={handleSavePriority}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}
