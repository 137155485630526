import React from 'react';
import PropTypes from 'prop-types';

export default function PageHeader({ title }) {
  return (
    <div className="page-header">
      <h1 className="page-heading">{title}</h1>
    </div>
  );
}

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
};
