import React, { useState, useEffect, useCallback } from 'react';
import { Container, Card, Row, Col, Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from '../../services/axios';
import { useNotification } from '../../components/Notification/NotificationContext';
import Loading from '../../components/Loading';
import Breadcrumbs from '../../components/Breadcrumbs';
import ErrorProcessor from '../../components/ErrorProcessor';

// Quill modules to handle images
const quillModules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image'],
    ['clean'],
  ],
};

export default function MessageTemplates() {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [apiErrors, setApiErrors] = useState({});
  const notification = useNotification();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [templates, setTemplates] = useState({
    aberto: '',
    fechado: '',
    pausado: '',
    em_atendimento: '',
    logo_url: '',
  });
  const [logo, setLogo] = useState(null);
  const [logoPreview, setLogoPreview] = useState(null);

  // Função para lidar com as mudanças nos campos de texto
  const handleFieldChange = useCallback((fieldName, value) => {
    setTemplates((prevValues) => ({ ...prevValues, [fieldName]: value }));
  }, []);

  // Função para upload de logo e pré-visualização
  const handleLogoUpload = (e) => {
    const file = e.target.files[0];
    setLogo(file);
    setLogoPreview(URL.createObjectURL(file));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append('status_fechado', templates.fechado);
      formData.append('status_pausado', templates.pausado);
      formData.append('status_em_atendimento', templates.em_atendimento);

      if (logo) {
        formData.append('logo', logo);
      }

      await axios.post('/messagetemplate', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      notification('Templates e logo salvos com sucesso!', 'success');
      navigate('/');
    } catch (err) {
      setApiErrors(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    async function getData() {
      try {
        setIsLoading(true);
        const { data } = await axios.get('/messagetemplate');
        setTemplates({
          fechado: data.status_fechado,
          pausado: data.status_pausado,
          em_atendimento: data.status_em_atendimento,
          logo_url: data.logo_url,
        });

        if (data.logo_url) {
          setLogoPreview(data.logo_url);
        }
      } catch (err) {
        setApiErrors(err);
      } finally {
        setIsLoading(false);
        setDataLoaded(true);
      }
    }
    getData();
  }, [notification, navigate]);

  return (
    <Container fluid className="px-lg-4 px-xl-5">
      <Loading isLoading={isLoading} />
      <Breadcrumbs
        title="Modelos de Mensagens"
        pages={[{ link: '/', name: 'Inicio' }]}
      />
      <section>
        <Row className="justify-content-center">
          <Col lg={10}>
            <Card className="mb-10">
              <Card.Header>
                <h4 className="card-heading text-center">
                  <strong>Configurar Modelos de Mensagem</strong>
                </h4>
                <br />
                <p className="text-center">
                  Use as seguintes variáveis dinâmicas nas mensagens:
                </p>
                <ul className="text-center">
                  <code>@cliente</code>: Nome do Cliente
                  <br />
                  <code>@status</code>: Status do Ticket
                  <br />
                  <code>@agente</code>: Nome do Agente
                  <br />
                  <code>@ticket</code>: Título do Ticket
                  <br />
                  <code>@number</code>: Número do Ticket
                  <br />
                  <code>@data</code>: Data de Abertura
                  <br />
                </ul>
              </Card.Header>
              <Card.Body>
                <ErrorProcessor error={apiErrors} />
                {dataLoaded && (
                  <Form onSubmit={handleSubmit}>
                    <Row className="mb-4">
                      <Col md={12}>
                        <Form.Group controlId="templateFechado">
                          <Form.Label>Mensagem para Ticket Fechado</Form.Label>
                          <ReactQuill
                            value={templates.fechado}
                            onChange={(value) =>
                              handleFieldChange('fechado', value)
                            }
                            modules={quillModules}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Col md={12}>
                        <Form.Group controlId="templatePausado">
                          <Form.Label>Mensagem para Ticket Pausado</Form.Label>
                          <ReactQuill
                            value={templates.pausado}
                            onChange={(value) =>
                              handleFieldChange('pausado', value)
                            }
                            modules={quillModules}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Col md={12}>
                        <Form.Group controlId="templateEmAtendimento">
                          <Form.Label>
                            Mensagem para Ticket em Atendimento
                          </Form.Label>
                          <ReactQuill
                            value={templates.em_atendimento}
                            onChange={(value) =>
                              handleFieldChange('em_atendimento', value)
                            }
                            modules={quillModules}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Col md={12}>
                        <Form.Group controlId="logoUpload">
                          <Form.Label>
                            Logo para Assinatura de E-mail
                          </Form.Label>
                          <Form.Control
                            type="file"
                            onChange={handleLogoUpload}
                          />
                          {logoPreview && (
                            <div className="mt-2 text-center">
                              <img
                                src={logoPreview}
                                alt="Pré-visualização do Logo"
                                style={{ width: '180px', height: 'auto' }}
                              />
                            </div>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>

                    <div className="d-flex justify-content-center">
                      <Button
                        variant="primary"
                        type="submit"
                        className="w-6"
                        disabled={isLoading}
                      >
                        {isLoading ? 'Salvando...' : 'Salvar Templates e Logo'}
                      </Button>
                    </div>
                  </Form>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </section>
    </Container>
  );
}
