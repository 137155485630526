import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { v4 } from 'uuid';

import { Table, Button, Form } from 'react-bootstrap';
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  useRowState,
} from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import useWindowSize from '../../hooks/useWindowSize';
import DataTablePagination from './DataTablePagination';

export default function DataTable({
  columns,
  items,
  updateMyData,
  nameAsc,
  searchLabel,
  cellClassname,
  defaultSort,
  hiddenColumns,
  hideHeader,
  hideToolbar,
  striped,
  defaultPageSize,
  showactions,
  customActions,
  defaultPageIndex,
}) {
  const data = React.useMemo(() => items, [items]);
  const {
    getTableProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    setHiddenColumns,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: hiddenColumns || [],
        pageIndex: defaultPageIndex || 0,
        pageSize: defaultPageSize || 5,
        sortBy: [
          {
            id: defaultSort || 'name',
            desc: !!nameAsc,
          },
        ],
        loading: false,
      },
      updateMyData,
      disableSortRemove: true,
      autoResetRowState: false,
    },
    useFilters,
    useSortBy,
    usePagination,
    useRowState
  );
  const perPageOptions = [
    {
      value: 5,
      label: 5,
    },
    {
      value: 10,
      label: 10,
    },
    {
      value: 15,
      label: 15,
    },
    {
      value: 20,
      label: 20,
    },
    {
      value: 25,
      label: 25,
    },
  ];

  const size = useWindowSize();

  useEffect(() => {
    if (size.width < 991) {
      searchLabel === 'categories'
        ? setHiddenColumns(['plant_type.name', 'description'])
        : setHiddenColumns(['slug', 'plant_category.name']);
    } else {
      setHiddenColumns([]);
    }
  }, [size.width, searchLabel, setHiddenColumns]);

  return (
    <>
      <div className={`dataTable-top ${hideToolbar ? 'd-none' : ''}`}>
        {showactions === true && (
          <span className="me-2">
            <Form.Select size="sm" className="d-inline w-auto me-1">
              <option>Remover</option>
            </Form.Select>

            <Button
              size="sm"
              variant="outline-primary"
              className="align-top mb-1 mb-lg-0"
            >
              Aplicar
            </Button>
          </span>
        )}
        {customActions}
        <div className="d-inline-block">
          <Form.Select
            className="d-inline w-auto me-1"
            onChange={(e) => setPageSize(Number(e.target.value))}
            value={pageSize}
          >
            {perPageOptions.map((option) => (
              <option key={option.label}>{option.label}</option>
            ))}
          </Form.Select>
        </div>
        <span className="text-xs">Registros por página</span>
      </div>
      <Table
        hover
        {...getTableProps()}
        className="align-middle mb-0"
        striped={striped}
        responsive
      >
        <thead className={hideHeader ? 'd-none' : ''}>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={v4()}>
              {headerGroup.headers.map((column) => {
                return (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="py-4"
                    key={v4()}
                  >
                    <span className="d-flex align-items-center text-transform-none ">
                      {column.render('Header')}
                      {!column.disableSortBy && (
                        <span className="d-grid ms-auto">
                          <FontAwesomeIcon
                            icon={faCaretUp}
                            className={`ms-1 ${
                              !column.isSortedDesc ? 'opacity-6' : ''
                            }`}
                            size="sm"
                          />
                          <FontAwesomeIcon
                            icon={faCaretDown}
                            className={`ms-1 ${
                              column.isSorted && !column.isSortedDesc
                                ? ''
                                : 'opacity-6'
                            }`}
                            size="sm"
                          />
                        </span>
                      )}
                    </span>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody className="position-relative border-top-0">
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={v4()}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      className={cellClassname || ''}
                      key={v4()}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div className="dataTable-bottom align-items-center d-flex">
        <div className="flex-shrink-0 mb-2 mb-md-0 me-auto">
          Visualizando página {pageIndex + 1} de {pageCount}
        </div>

        {pageOptions.length > 1 && (
          <DataTablePagination
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            gotoPage={gotoPage}
            nextPage={nextPage}
            previousPage={previousPage}
            pageIndex={pageIndex}
            pageOptions={pageOptions}
            viewportWidth={size.width || 500}
          />
        )}
      </div>
    </>
  );
}

DataTable.defaultProps = {
  updateMyData: null,
  nameAsc: 'desc',
  searchLabel: '',
  cellClassname: '',
  defaultSort: '',
  hiddenColumns: [],
  hideHeader: false,
  hideToolbar: false,
  striped: false,
  defaultPageSize: 5,
  showactions: false,
  customActions: null,
  defaultPageIndex: null,
};

DataTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.string.isRequired,
      accessor: PropTypes.string.isRequired,
      noSearch: PropTypes.bool,
    })
  ).isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  updateMyData: PropTypes.func,
  nameAsc: PropTypes.string,
  searchLabel: PropTypes.string,
  cellClassname: PropTypes.string,
  defaultSort: PropTypes.string,
  hiddenColumns: PropTypes.arrayOf(PropTypes.string),
  hideHeader: PropTypes.bool,
  hideToolbar: PropTypes.bool,
  striped: PropTypes.bool,
  defaultPageSize: PropTypes.number,
  showactions: PropTypes.bool,
  customActions: PropTypes.node,
  defaultPageIndex: PropTypes.number,
};
