import React from 'react';

import { ReactComponent as OrionIcons } from '../../assets/svg/orion-svg-sprite.svg';

function SvgIcons() {
  return (
    <div style={{ position: 'absolute' }}>
      <OrionIcons />
    </div>
  );
}

export default SvgIcons;
