import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

export default function CustomImage({
  src,
  alt,
  width,
  height,
  className,
  link,
}) {
  const image = (
    <img
      src={src}
      alt={alt}
      width={width}
      height={height}
      className={className}
    />
  );
  return link ? (
    <NavLink
      to={link}
      target="_blank"
      rel="noopener noreferrer"
      title="Clique para abrir"
    >
      {image}
    </NavLink>
  ) : (
    image
  );
}

CustomImage.defaultProps = {
  className: '',
  width: undefined,
  height: undefined,
  src: '',
  alt: '',
  link: null,
};

CustomImage.propTypes = {
  src: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  alt: PropTypes.string,
  link: PropTypes.string,
};
