import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Breadcrumb } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { v4 } from 'uuid';

export default function Breadcrumbs({ pages, title }) {
  const navigate = useNavigate();
  return (
    <div className="page-breadcrumb">
      <Breadcrumb>
        <Breadcrumb.Item onClick={() => navigate('/')}>Início</Breadcrumb.Item>
        {pages &&
          pages.map((page) => (
            <Breadcrumb.Item key={v4()} onClick={() => navigate(page.link)}>
              {page.name}
            </Breadcrumb.Item>
          ))}
        <Breadcrumb.Item key={v4()} active>
          {title}
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );
}

Breadcrumbs.defaultProps = {
  pages: [],
  title: '',
};

Breadcrumbs.propTypes = {
  pages: PropTypes.arrayOf(PropTypes.shape({})),
  title: PropTypes.string,
};
