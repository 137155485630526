import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';
import { Container, Card, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import axios from '../../services/axios';
import Loading from '../../components/Loading';
import Breadcrumbs from '../../components/Breadcrumbs';
import DataTable from '../../components/DataTable';
import ButtonModal from '../../components/ModalConfirmation/ButtonModal';
import { useNotification } from '../../components/Notification/NotificationContext';
import ErrorProcessor from '../../components/ErrorProcessor';
import CardHeaderMore from '../../components/Cards/CardHeaderMore';

export default function Companies() {
  const routeName = '/companies';
  const dataName = 'Empresa';
  const titleName = 'Empresas';
  const navigate = useNavigate();
  const [apiErrors, setApiErrors] = useState({});
  const notification = useNotification();
  const [companies, setCompanies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const userData = useSelector((state) => state.auth.user);
  const tableColumns = useMemo(
    () => [
      {
        Header: 'Empresa',
        accessor: 'name',
      },
      {
        Header: 'Acessos',
        accessor: 'access',
        noSearch: true,
      },
    ],
    []
  );

  useEffect(() => {
    async function getData() {
      try {
        setIsLoading(true);
        const response = await axios.get(routeName);
        setCompanies(response.data);
      } catch (err) {
        setApiErrors(err);
        setCompanies([]);
      } finally {
        setIsLoading(false);
      }
    }

    getData();
  }, [notification]);

  const confirmDelete = useCallback(
    async (id) => {
      try {
        setIsLoading(true);
        await axios.delete(`${routeName}/${id}`);
        notification(`${dataName} removida com sucesso`, 'success');
        const updatedCompanies = companies.filter(
          (company) => company.id !== id
        );
        setCompanies(updatedCompanies);
      } catch (err) {
        setApiErrors(err);
      } finally {
        setIsLoading(false);
      }
    },
    [setIsLoading, notification, companies]
  );

  const tableData = useMemo(() => {
    return companies.map((dataObject) => {
      const linksAction = [
        {
          onclick: () => {
            navigate(`${routeName}/${dataObject.id}/edit`);
          },
          faicon: 'faEdit',
          text: 'Atualizar',
        },
      ];

      if (userData.isadmin === 1) {
        linksAction.push({
          custom: (
            <ButtonModal
              onConfirm={() => confirmDelete(dataObject.id)}
              message={`Confirma remoção da ${dataName} ${dataObject.name}?`}
              buttonProps={{ text: 'Remover', faicon: 'faTrash' }}
              textModal={{
                title: `Remover ${dataName}`,
                textConfirm: 'Remover',
              }}
              dropdown
            />
          ),
        });
      }
      return {
        access: (
          <div className="d-flex justify-content-between">
            <div>
              <Link to={`${routeName}/${dataObject.id}/access`}>
                <Button
                  variant="success"
                  className="rounded-pill shadow text-white"
                  size="sm"
                >
                  <b>{Object.values(dataObject.Accesses).length}</b>
                </Button>
              </Link>
            </div>
            <CardHeaderMore links={linksAction} className="" />
          </div>
        ),
        ...dataObject,
      };
    });
  }, [companies, confirmDelete, navigate, userData.isadmin]);

  return (
    <Container fluid className="px-lg-4 px-xl-5">
      <Loading isLoading={isLoading} />
      <Breadcrumbs title={`${titleName}`} />
      <div className="page-header d-flex justify-content-between align-items-center">
        <h1 className="page-heading">{titleName}</h1>
        {userData.isadmin === 1 && (
          <div>
            <Link to={`${routeName}/new`}>
              <Button variant="primary" className="text-uppercase">
                <FaPlus /> Cadastrar
              </Button>
            </Link>
          </div>
        )}
      </div>
      <section>
        <Card className="card-table mb-5">
          <ErrorProcessor error={apiErrors} />
          <DataTable
            items={tableData}
            columns={tableColumns}
            defaultPageSize={5}
          />
        </Card>
      </section>
    </Container>
  );
}
