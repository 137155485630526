import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';
import { Container, Card, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import axios from '../../services/axios';
import Loading from '../../components/Loading';
import Breadcrumbs from '../../components/Breadcrumbs';
import DataTable from '../../components/DataTable';
import ButtonModal from '../../components/ModalConfirmation/ButtonModal';
import { useNotification } from '../../components/Notification/NotificationContext';
import ErrorProcessor from '../../components/ErrorProcessor';
import CardHeaderMore from '../../components/Cards/CardHeaderMore';

export default function EmailsProviders() {
  const routeName = '/emailsproviders';
  const dataName = 'Provedor de e-mail';
  const titleName = 'Provedores de e-mail';
  const navigate = useNavigate();
  const [apiErrors, setApiErrors] = useState({});
  const notification = useNotification();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const userData = useSelector((state) => state.auth.user);
  const tableColumns = useMemo(
    () => [
      {
        Header: 'Nome do provedor',
        accessor: 'provider_name',
      },
      {
        Header: 'Empresa',
        accessor: 'company_name',
      },
    ],
    []
  );

  useEffect(() => {
    async function getData() {
      try {
        setIsLoading(true);
        const response = await axios.get(routeName);
        setData(response.data);
      } catch (err) {
        setApiErrors(err);
        setData([]);
      } finally {
        setIsLoading(false);
      }
    }

    getData();
  }, [notification]);

  const confirmDelete = useCallback(
    async (id) => {
      try {
        setIsLoading(true);
        await axios.delete(`${routeName}/${id}`);
        notification(`${dataName} removido com sucesso`, 'success');
        const updated = data.filter((channel) => channel.id !== id);
        setData(updated);
      } catch (err) {
        setApiErrors(err);
      } finally {
        setIsLoading(false);
      }
    },
    [setIsLoading, notification, data]
  );

  const tableData = useMemo(() => {
    return data.map((dataObject) => {
      const linksAction = [
        {
          onclick: () => {
            navigate(`${routeName}/${dataObject.id}/edit`);
          },
          faicon: 'faEdit',
          text: 'Atualizar',
        },
      ];

      if (userData.isadmin === 1) {
        linksAction.push({
          custom: (
            <ButtonModal
              onConfirm={() => confirmDelete(dataObject.id)}
              message={`Confirma remoção do ${dataName} ${dataObject.provider_name}?`}
              buttonProps={{ text: 'Remover', faicon: 'faTrash' }}
              textModal={{
                title: `Remover ${dataName}`,
                textConfirm: 'Remover',
              }}
              dropdown
            />
          ),
        });
      }

      return {
        company_name: (
          <div className="d-flex justify-content-between">
            <span>{dataObject.Company.name}</span>
            <CardHeaderMore links={linksAction} className="" />
          </div>
        ),
        ...dataObject,
      };
    });
  }, [data, confirmDelete, userData]);

  return (
    <Container fluid className="px-lg-4 px-xl-5">
      <Loading isLoading={isLoading} />
      <Breadcrumbs title={titleName} />
      <div className="page-header d-flex justify-content-between align-items-center">
        <h1 className="page-heading">{titleName}</h1>
        <div>
          <Link to={`${routeName}/new`}>
            <Button variant="primary" className="text-uppercase">
              <FaPlus /> Cadastrar
            </Button>
          </Link>
        </div>
      </div>
      <section>
        <Card className="card-table mb-5">
          <ErrorProcessor error={apiErrors} />
          <DataTable
            items={tableData}
            columns={tableColumns}
            defaultPageSize={5}
          />
        </Card>
      </section>
    </Container>
  );
}
