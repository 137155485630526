import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'react-bootstrap';
import { v4 } from 'uuid';

import Avatar from '../Avatar';

export default function Activity({ title, data, appendHeader }) {
  return (
    <Card className="mb-4">
      <Card.Header>
        <h5 className="card-heading">{title}</h5>
      </Card.Header>
      <Card.Body>
        {appendHeader}
        <div className="list-group list-group-flush list-group-timeline">
          {data.map((item) => (
            <div className="list-group-item px-0" key={v4()}>
              <Row>
                <Col xs="auto">
                  <Avatar
                    image={item.img || '/img/user-profile-circle.png'}
                    className="me-2"
                    border
                    link={item.link || false}
                  />
                </Col>
                <Col className="col ms-n3 pt-2 text-sm text-gray-800">
                  <strong className="text-dark">{item.name} </strong>{' '}
                  {item.text}
                  <div className="text-gray-500 small">{item.time}</div>
                </Col>
              </Row>
            </div>
          ))}
        </div>
      </Card.Body>
    </Card>
  );
}

Activity.defaultProps = {
  data: [],
  title: 'Atividades',
  appendHeader: null,
};

Activity.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      time: PropTypes.string,
      data: PropTypes.shape({
        img: PropTypes.string,
        name: PropTypes.string,
        text: PropTypes.string,
        link: PropTypes.string,
        time: PropTypes.string,
      }),
    })
  ),
  appendHeader: PropTypes.node,
  title: PropTypes.string,
};
