import React, { useState, useEffect } from 'react';
import { isEmail } from 'validator';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';

import Loading from '../../components/Loading';
import Image from '../../components/CustomImage';
import { useNotification } from '../../components/Notification/NotificationContext';
import axios from '../../services/axios';
import ErrorProcessor from '../../components/ErrorProcessor';

export default function ResetPassword() {
  const notification = useNotification();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [apiErrors, setApiErrors] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isEmail(email)) {
      notification('E-mail inválido.');
      return;
    }

    try {
      setIsLoading(true);
      await axios.put(`/resetpassword`, { email });
      notification(`Você receberá um e-mail com a sua nova senha.`, 'success');
      navigate('/login');
    } catch (err) {
      setApiErrors(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {}, []);

  return (
    <Container fluid className="px-0 overflow-hidden">
      <Loading isLoading={isLoading} />
      <ErrorProcessor error={apiErrors} />
      <Row className="gx-0 min-vh-100">
        <Col
          md={9}
          lg={6}
          xl={4}
          className="px-5 d-flex align-items-top shadow"
        >
          <div className="w-100 py-5">
            <div className="text-center">
              <img
                className="img-fluid mb-4"
                src="img/logos/opennewLogo.png"
                alt="..."
                style={{ maxWidth: '6rem' }}
              />
              <h1 className="h4 text-uppercase mb-5">Redefinir senha</h1>
            </div>
            <Form onSubmit={handleSubmit}>
              <div className="mb-3">
                <Form.Label className="form-label">
                  Informe o e-mail do seu usuário
                </Form.Label>
                <Form.Control
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Entre com seu e-mail"
                />
              </div>
              <div className="d-grid mb-5">
                <Button
                  type="submit"
                  variant="primary"
                  className="text-uppercase"
                >
                  Redefinir
                </Button>
              </div>
            </Form>
          </div>
        </Col>
        <Col md={3} lg={6} xl={8} className="d-none d-md-block">
          <div className="bg-cover h-100 me-n3 position-relative">
            <Image
              src="/img/photos/greenShapes.jpg"
              alt="..."
              layout="fill"
              objectFit="cover"
              sizes="75vw"
              height={900}
              priority
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
}
