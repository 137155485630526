/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';
import { Container, Card, Button, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import 'moment/locale/pt-br';
import axios from '../../services/axios';
import Loading from '../../components/Loading';
import Breadcrumbs from '../../components/Breadcrumbs';
import DataTable from '../../components/DataTable';
import ButtonModal from '../../components/ModalConfirmation/ButtonModal';
import { useNotification } from '../../components/Notification/NotificationContext';
import ErrorProcessor from '../../components/ErrorProcessor';
import CardHeaderMore from '../../components/Cards/CardHeaderMore';

moment.locale('pt-br');

export default function Tickets() {
  const routeName = '/tickets';
  const titleName = 'Tickets';
  const navigate = useNavigate();
  const [apiErrors, setApiErrors] = useState({});
  const notification = useNotification();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const userData = useSelector((state) => state.auth.user);
  const [selectedColumns, setSelectedColumns] = useState([
    'ticketNumber',
    'title',
    'requester',
    'priority',
    'department',
    'assignedTo',
    'createdAt',
    'vencAtendimento',
    'vencSolucao',
    'status',
  ]);
  const [searchText, setSearchText] = useState('');

  const allColumns = useMemo(
    () => [
      { Header: 'Nº Ticket', accessor: 'ticketNumber' },
      { Header: 'Título', accessor: 'title' },
      { Header: 'Solicitante', accessor: 'requester' },
      { Header: 'Prioridade', accessor: 'priority' },
      { Header: 'Departamento', accessor: 'department' },
      { Header: 'Responsável', accessor: 'assignedTo' },
      { Header: 'Criado em', accessor: 'createdAt' },
      { Header: 'Venc Atendimento', accessor: 'vencAtendimento' },
      { Header: 'Venc Solução', accessor: 'vencSolucao' },
      { Header: 'Status', accessor: 'status' },
      { Header: 'Ações', accessor: 'actions', disableSortBy: true },
    ],
    []
  );

  const tableColumns = useMemo(() => {
    return allColumns.filter(
      (col) =>
        selectedColumns.includes(col.accessor) || col.accessor === 'actions'
    );
  }, [selectedColumns, allColumns]);

  const handleColumnSelection = (e) => {
    const { value, checked } = e.target;
    setSelectedColumns((prev) =>
      checked ? [...prev, value] : prev.filter((col) => col !== value)
    );
  };

  const getData = useCallback(async () => {
    try {
      setIsLoading(true);
      const [
        ticketsResponse,
        usersResponse,
        requestersResponse,
        userDepartmentsResponse,
        prioritiesResponse,
      ] = await Promise.all([
        axios.get(`${routeName}/`),
        axios.get(`/users`),
        axios.get(`/customers`),
        axios.get(`/userdpto`),
        axios.get(`/priorities`),
      ]);

      const userDepartments = userDepartmentsResponse.data.filter(
        (userDepartment) => userDepartment.userId === userData.id
      );
      const userDepartmentIds = userDepartments.map(
        (userDepartment) => userDepartment.departmentId
      );

      const departmentsMap = userDepartments.reduce((acc, dept) => {
        acc[dept.departmentId] = dept.Department
          ? dept.Department.name
          : 'Não disponível';
        return acc;
      }, {});

      const usersMap = usersResponse.data.reduce((acc, user) => {
        acc[user.id] = user.UserProfile
          ? user.UserProfile.name
          : 'Nome não disponível';
        return acc;
      }, {});

      const requestersMap = requestersResponse.data.reduce(
        (acc, razaoSocial) => {
          const firstName = razaoSocial.razaoSocial.split(' ')[0];
          const secondName = razaoSocial.razaoSocial.split(' ')[1];
          acc[razaoSocial.id] = `${firstName} ${secondName}`;
          return acc;
        },
        {}
      );

      const prioritiesMap = prioritiesResponse.data.reduce((acc, priority) => {
        acc[priority.id] = priority.name;
        return acc;
      }, {});

      const formattedData = await Promise.all(
        ticketsResponse.data
          .filter((ticket) => {
            const inUserDepartment = userDepartmentIds.includes(
              ticket.department
            );

            const shouldShow =
              userData.isadmin === 1 ? true : ticket.status !== 'fechado';

            return inUserDepartment && shouldShow;
          })
          .map(async (ticket) => {
            const serviceDeskResponse = await axios.get(
              `/servicedesks/${ticket.serviceDesk}`
            );
            const serviceDesk = serviceDeskResponse.data;

            const priority = serviceDesk.priorities.find(
              (p) => p.id === ticket.priority
            );
            const atendimentoVencimento = priority
              ? priority.atendimentoVencimento
              : null;
            const solucaoVencimento = priority
              ? priority.solucaoVencimento
              : null;

            let vencAtendimento = 'Não iniciado';
            if (atendimentoVencimento) {
              const createdTime = moment(ticket.createdAt).tz(
                'America/Sao_Paulo'
              );
              const deadline = createdTime
                .clone()
                .add(moment.duration(atendimentoVencimento));
              const currentTime = moment().tz('America/Sao_Paulo');

              if (ticket.startTime) {
                const startTime = moment(ticket.startTime).tz(
                  'America/Sao_Paulo'
                );
                const timeDiff = startTime.diff(createdTime, 'hours', true);

                vencAtendimento = currentTime.isBefore(deadline)
                  ? `✔️ SLA cumprido (${timeDiff.toFixed(0)}h)`
                  : `❌ SLA vencido (${timeDiff.toFixed(0)}h)`;
              } else {
                const remainingTime = deadline.fromNow(true);
                vencAtendimento = currentTime.isBefore(deadline)
                  ? `⏳ Vence em ${remainingTime}`
                  : `❌ Atrasado há ${remainingTime}`;
              }
            }

            let vencSolucao = 'Não iniciado';
            if (ticket.startTime && solucaoVencimento) {
              const startTime = moment(ticket.startTime).tz(
                'America/Sao_Paulo'
              );
              const solutionDeadline = startTime
                .clone()
                .add(moment.duration(solucaoVencimento));
              const currentTime = moment().tz('America/Sao_Paulo');

              if (ticket.closingTime) {
                const closingTime = moment(ticket.closingTime).tz(
                  'America/Sao_Paulo'
                );
                const timeToSolve = closingTime.diff(startTime, 'hours', true);
                vencSolucao = closingTime.isBefore(solutionDeadline)
                  ? `✔️ SLA cumprido (${timeToSolve.toFixed(0)}h)`
                  : `❌ SLA vencido (${timeToSolve.toFixed(0)}h)`;
              } else {
                const remainingTime = solutionDeadline.fromNow(true);
                vencSolucao = currentTime.isBefore(solutionDeadline)
                  ? `⏳ Vence em ${remainingTime}`
                  : `❌ Atrasado há ${remainingTime}`;
              }
            }

            return {
              ...ticket,
              ticketNumber: ticket.id,
              title: ticket.title,
              requester: requestersMap[ticket.company],
              priority: prioritiesMap[ticket.priority] || ticket.priority,
              assignedTo: usersMap[ticket.assignedTo],
              status: ticket.status,
              createdAt: moment(ticket.createdAt)
                .tz('America/Sao_Paulo')
                .format('DD/MM/YYYY HH:mm:ss'),
              vencAtendimento,
              vencSolucao,
              department: departmentsMap[ticket.department] || 'Não disponível',
            };
          })
      );

      setData(formattedData);
      setDataLoaded(true);
    } catch (err) {
      setApiErrors(err);
      setData([]);
    } finally {
      setIsLoading(false);
    }
  }, [userData.id, userData.isadmin]);

  useEffect(() => {
    getData();
  }, [getData]);

  const confirmDelete = useCallback(
    async (id) => {
      try {
        setIsLoading(true);
        await axios.delete(`${routeName}/${id}`);
        notification('Ticket removido com sucesso', 'success');
        const updated = data.filter((ticket) => ticket.id !== id);
        setData(updated);
      } catch (err) {
        setApiErrors(err);
      } finally {
        setIsLoading(false);
      }
    },
    [data, notification]
  );

  const tableData = useMemo(() => {
    return data
      .filter((ticket) =>
        Object.values(ticket).some((value) =>
          String(value).toLowerCase().includes(searchText.toLowerCase())
        )
      )
      .map((ticketObj) => {
        const linksAction = [
          {
            onclick: () => {
              navigate(`${routeName}/${ticketObj.id}/edit`);
            },
            faicon: 'faEdit',
            text: 'Detalhes',
          },
        ];

        if (userData.isadmin === 1) {
          linksAction.push({
            custom: (
              <ButtonModal
                onConfirm={() => confirmDelete(ticketObj.id)}
                message={`Confirma remoção do Ticket ${ticketObj.id}?`}
                buttonProps={{ text: 'Remover', faicon: 'faTrash' }}
                textModal={{
                  title: 'Remover Ticket',
                  textConfirm: 'Remover',
                }}
                dropdown
              />
            ),
          });
        }

        const statusName =
          ticketObj.status === 'aberto' ? (
            <b className="text-success">Aberto</b>
          ) : ticketObj.status === 'em_atendimento' ? (
            <b className="text-warning">Em Atendimento</b>
          ) : ticketObj.status === 'pausado' ? (
            <b className="text-primary">Em Pausa</b>
          ) : (
            <b className="text-danger">Fechado</b>
          );

        return {
          ...ticketObj,
          status: statusName,
          actions: (
            <div className="d-flex justify-content-between">
              <CardHeaderMore links={linksAction} className="" />
            </div>
          ),
        };
      });
  }, [data, confirmDelete, searchText, userData, navigate]);

  return (
    <Container fluid className="px-lg-4 px-xl-5">
      <Loading isLoading={isLoading} />
      <Breadcrumbs title={titleName} />
      <div className="page-header d-flex justify-content-between align-items-center">
        <h1 className="page-heading">{titleName}</h1>
        <div>
          <Link to={`${routeName}/new`}>
            <Button variant="primary" className="text-uppercase">
              <FaPlus /> Novo Ticket
            </Button>
          </Link>
        </div>
      </div>

      <Form.Group controlId="search">
        <Form.Control
          type="text"
          placeholder="Pesquisar..."
          value={searchText}
          style={{ maxWidth: '300px' }}
          onChange={(e) => setSearchText(e.target.value)}
        />
      </Form.Group>

      <Card className="mb-3">
        <Card.Body>
          <Form>
            <Form.Label>Exibir colunas:</Form.Label>
            <div>
              {allColumns.map((col) => (
                <Form.Check
                  inline
                  key={col.accessor}
                  label={col.Header}
                  value={col.accessor}
                  checked={selectedColumns.includes(col.accessor)}
                  onChange={handleColumnSelection}
                />
              ))}
            </div>
          </Form>
        </Card.Body>
      </Card>

      <section>
        {dataLoaded && (
          <Card className="card-table mb-5">
            <ErrorProcessor error={apiErrors} />
            <DataTable
              items={tableData}
              columns={tableColumns}
              defaultPageSize={10}
              defaultSort="createdAt"
            />
          </Card>
        )}
      </section>
    </Container>
  );
}
