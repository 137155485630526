import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';
import { Container, Card, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import axios from '../../services/axios';
import Loading from '../../components/Loading';
import Breadcrumbs from '../../components/Breadcrumbs';
import DataTable from '../../components/DataTable';
import ButtonModal from '../../components/ModalConfirmation/ButtonModal';
import { useNotification } from '../../components/Notification/NotificationContext';
import ErrorProcessor from '../../components/ErrorProcessor';
import CardHeaderMore from '../../components/Cards/CardHeaderMore';

export default function Customers() {
  const routeName = '/customers';
  const dataName = 'Cliente';
  const titleName = 'Clientes';
  const navigate = useNavigate();
  const [apiErrors, setApiErrors] = useState({});
  const notification = useNotification();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const userData = useSelector((state) => state.auth.user);
  const tableColumns = useMemo(
    () => [
      {
        Header: 'Razão Social',
        accessor: 'razaoSocial',
      },
      {
        Header: 'CPF / CNPJ',
        accessor: 'cpfCnpj',
      },
      {
        Header: 'Situação',
        accessor: 'situacaoCadastral',
      },
      {
        Header: 'Ações',
        accessor: 'actions',
        noSearch: true,
        disableSortBy: true,
      },
    ],
    []
  );

  useEffect(() => {
    async function getData() {
      try {
        setIsLoading(true);
        const response = await axios.get(routeName);
        setData(response.data);
      } catch (err) {
        setApiErrors(err);
        setData([]);
      } finally {
        setIsLoading(false);
      }
    }

    getData();
  }, [notification]);

  const confirmDelete = useCallback(
    async (id) => {
      try {
        setIsLoading(true);
        await axios.delete(`${routeName}/${id}`);
        notification(`${dataName} removido com sucesso`, 'success');
        const updated = data.filter((channel) => channel.id !== id);
        setData(updated);
      } catch (err) {
        setApiErrors(err);
      } finally {
        setIsLoading(false);
      }
    },
    [setIsLoading, notification, data]
  );

  const tableData = useMemo(() => {
    return data.map((dataObject) => {
      const linksAction = [
        {
          onclick: () => {
            navigate(`${routeName}/${dataObject.id}/edit`);
          },
          faicon: 'faEdit',
          text: 'Atualizar',
        },
      ];

      if (userData.isadmin === 1) {
        linksAction.push({
          custom: (
            <ButtonModal
              onConfirm={() => confirmDelete(dataObject.id)}
              message={`Confirma remoção do ${dataName} ${dataObject.razaoSocial}?`}
              buttonProps={{ text: 'Remover', faicon: 'faTrash' }}
              textModal={{
                title: `Remover ${dataName}`,
                textConfirm: 'Remover',
              }}
              dropdown
            />
          ),
        });
      }
      return {
        razaoSocial: dataObject.razaoSocial,
        cpfCnpj: dataObject.cpfCnpj,
        situacaoCadastral: dataObject.situacaoCadastral,
        actions: <CardHeaderMore links={linksAction} className="" />,
      };
    });
  }, [data, userData.isadmin, navigate, confirmDelete]);

  return (
    <Container fluid className="px-lg-4 px-xl-5">
      <Loading isLoading={isLoading} />
      <Breadcrumbs title={titleName} />
      <div className="page-header d-flex justify-content-between align-items-center">
        <h1 className="page-heading">{titleName}</h1>
        <div>
          <Link to={`${routeName}/new`}>
            <Button variant="primary" className="text-uppercase">
              <FaPlus /> Cadastrar
            </Button>
          </Link>
        </div>
      </div>
      <section>
        <Card className="card-table mb-5">
          <ErrorProcessor error={apiErrors} />
          <DataTable
            items={tableData}
            columns={tableColumns}
            defaultPageSize={5}
          />
        </Card>
      </section>
    </Container>
  );
}
