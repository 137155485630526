/* eslint-disable no-param-reassign */
/* eslint-disable no-undef */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useRef } from 'react';
import {
  Container,
  Card,
  Row,
  Col,
  Form,
  Button,
  Tabs,
  Tab,
  Spinner,
  Modal,
} from 'react-bootstrap';
import ReactQuill from 'react-quill';
import '../../assets/svg/styles.css';
import 'react-quill/dist/quill.snow.css';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { FaPhoneAlt } from 'react-icons/fa';
import analystIcon from '../../assets/svg/icons/send.svg';
import clientIcon from '../../assets/svg/icons/reply.svg';
import axios from '../../services/axios';
import Loading from '../../components/Loading';
import Breadcrumbs from '../../components/Breadcrumbs';
// Importando o hook de notificações
import { useNotification } from '../../components/Notification/NotificationContext';

export default function TicketsEdit() {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const userData = useSelector((state) => state.auth.user);
  const [status, setStatus] = useState('aberto'); // Status inicial
  const [, setEmAtendimento] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState('');
  const [selectedRequesterId, setSelectedRequesterId] = useState('');
  const [, setSelectedUserName] = useState('');
  const [priority, setPriority] = useState('');
  const [stageid, setStages] = useState('');
  const [serviceDesk, setServiceDesk] = useState('');
  const [internalCommunication, setInternalCommunication] = useState('');
  const [clientCommunication, setClientCommunication] = useState('');
  const [users, setUsers] = useState([]);
  const [, setCompanies] = useState([]);
  const [requesters, setRequesters] = useState([]);
  const [, setDepartments] = useState([]);
  const [serviceDesks, setServiceDesks] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [filteredDepartments, setFilteredDepartments] = useState([]);
  const [filteredPriorities, setFilteredPriorities] = useState([]);
  const [filteredStages, setFilteredStages] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState('');
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [title, setTitle] = useState('');
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState('');
  const [isLoading] = useState(false);
  const [emailTemplate, setEmailTemplate] = useState(null);
  const [apiLigacao, setApiLigacao] = useState(null);
  const [showCallModal, setShowCallModal] = useState(false);
  const [currentPhoneNumber, setCurrentPhoneNumber] = useState('');
  const handleCloseCallModal = () => setShowCallModal(false);
  const [clientCommunicationHistory, setClientCommunicationHistory] = useState(
    []
  );
  const [internalCommunicationHistory, setInternalCommunicationHistory] =
    useState([]);
  const [, setUsersMap] = useState({});
  const [clientPhone, setClientPhone] = useState('');
  const [clientCell, setClientCell] = useState('');
  const [clientInfo, setClientInfo] = useState('');
  const [timelineLogs, setTimelineLogs] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const clientQuillRef = useRef();
  const internalQuillRef = useRef();
  const fileInputRef = useRef();
  const [files, setFiles] = useState([]);
  const notification = useNotification();
  const [recentTickets, setRecentTickets] = useState([]);

  // Função para criar um log
  const createLogEntry = async (action) => {
    try {
      const logEntry = {
        ticket_id: id, // ID do ticket
        user_id: userData.id, // ID do usuário atual
        action, // Descrição da ação realizada
      };

      await axios.post('/timeline', logEntry);
      fetchTimelineLogs(id); // Atualiza os logs exibidos na interface
    } catch (error) {
      console.error('Failed to create log entry:', error);
    }
  };
  const fetchRecentTickets = async (companyId) => {
    try {
      const response = await axios.get(`/tickets`, {
        params: {
          company: companyId,
          limit: 5, // Limita a quantidade para 5 tickets
          order: [['createdAt', 'DESC']], // Ordena pelo mais recente
        },
      });
      setRecentTickets(response.data); // Armazena os tickets retornados
    } catch (error) {
      console.error('Failed to fetch recent tickets:', error);
    }
  };
  const fetchRequesters = async (companyId) => {
    try {
      const response = await axios.get(`/requesters`);
      const filteredRequesters = response.data.filter(
        (requester) => requester.company === parseInt(companyId, 10)
      );
      setRequesters(filteredRequesters);
    } catch (error) {
      console.error('Failed to fetch requesters:', error);
    }
  };

  const fetchTimelineLogs = async (ticketId) => {
    try {
      const response = await axios.get(`/timeline/${ticketId}`);
      setTimelineLogs(response.data);
    } catch (error) {
      console.error('Failed to fetch timeline logs:', error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchTimelineLogs(id); // Carrega os logs da linha do tempo ao carregar o ticket
    }
  }, [id]);
  // Carrega o template de email uma vez, quando o componente é montado
  useEffect(() => {
    const fetchEmailTemplate = async () => {
      try {
        const response = await axios.get('/messagetemplate');
        setEmailTemplate(response.data); // Armazena o template de email na variável de estado
      } catch (error) {
        console.error('Failed to fetch email template:', error);
      }
    };

    fetchEmailTemplate();
  }, []);

  useEffect(() => {
    if (selectedCompany) {
      fetchRecentTickets(selectedCompany); // Busca os últimos tickets do cliente
    }
  }, [selectedCompany]);

  const fetchTemplateContent = async (templateId) => {
    try {
      const response = await axios.get(`/templates/${templateId}`);
      setClientCommunication(response.data.content);
    } catch (error) {
      console.error('Failed to fetch template content:', error);
    }
  };

  const fetchCommunicationHistory = async (ticketId) => {
    try {
      const response = await axios.get(`/communicationHistory/${ticketId}`);
      const clientMessages = response.data.filter(
        (entry) => entry.clientMessage
      );
      const internalMessages = response.data.filter(
        (entry) => entry.internalMessage
      );
      setClientCommunicationHistory(clientMessages);
      setInternalCommunicationHistory(internalMessages);
    } catch (error) {
      console.error('Failed to fetch communication history:', error);
    }
  };

  const fetchUsersMap = async () => {
    try {
      const response = await axios.get('/users');
      const usersData = response.data;
      const usersMap = usersData.reduce((acc, user) => {
        acc[user.id] = user.UserProfile
          ? user.UserProfile.name
          : 'Nome não disponível';
        return acc;
      }, {});
      setUsersMap(usersMap);
    } catch (error) {
      console.error('Failed to fetch users:', error);
    }
  };

  const fetchCustomerInfo = async (companyId) => {
    try {
      const response = await axios.get(`/customers/${companyId}`);
      return response.data.notes;
    } catch (error) {
      console.error('Failed to fetch customer info:', error);
      return '';
    }
  };
  // Função para buscar o perfil do usuário logado e obter o ramal
  const fetchUserRamal = async (userId) => {
    try {
      const response = await axios.get('/profiles');
      const userProfile = response.data.find(
        (profile) => profile.user_id === userId
      ); // Filtra pelo ID do usuário logado
      if (userProfile && userProfile.User && userProfile.User.ramal) {
        return userProfile.User.ramal; // Retornando o ramal do usuário logado
      }
      return null;
    } catch (error) {
      console.error('Erro ao buscar o perfil do usuário:', error);
      return null;
    }
  };

  // Função para buscar a API de ligações
  const fetchApiLigacao = async () => {
    try {
      const response = await axios.get('/ligacoes');
      if (response.data.length > 0) {
        setApiLigacao(response.data[0]); // Assumindo que há uma única API cadastrada
      }
    } catch (error) {
      console.error('Erro ao buscar a API de ligações:', error);
    }
  };

  // Função para fazer a chamada telefônica usando a API cadastrada
  const makeCall = async (number) => {
    if (!apiLigacao) {
      console.error('Nenhuma API de ligação cadastrada.');
      return;
    }
    // Obter o ID do usuário logado
    const userId = userData.id; // Pega o ID do usuário logado

    // Buscar o ramal do usuário logado
    const userRamal = await fetchUserRamal(userId);
    if (!userRamal) {
      console.error('Ramal do usuário não encontrado.');
      return;
    }

    const { url, method, bodyType, body } = apiLigacao;

    let requestBody;
    if (bodyType === 'raw') {
      const parsedBody = JSON.parse(body);
      parsedBody.origem = userRamal; // Ramal do usuário logado
      parsedBody.destino = number; // Número de telefone
      requestBody = JSON.stringify(parsedBody);
    } else if (bodyType === 'x-www-form-urlencoded') {
      const parsedBody = JSON.parse(body);
      const formBody = parsedBody.reduce((acc, field) => {
        acc[field.key] =
          field.value === '{ramal}'
            ? userRamal // Usando o ramal do usuário logado
            : field.value === '{telefone}'
              ? number
              : field.value;
        return acc;
      }, {});
      requestBody = new URLSearchParams(formBody).toString();
    }

    try {
      const response = await axios.post('/proxy-call', {
        url,
        method,
        headers: {
          'Content-Type':
            bodyType === 'raw'
              ? 'application/json'
              : 'application/x-www-form-urlencoded',
        },
        body: requestBody,
      });
      console.log('Ligação realizada:', response.data);
    } catch (error) {
      console.error('Erro ao realizar a ligação via proxy:', error);
    }
  };

  useEffect(() => {
    fetchApiLigacao();
  }, []);

  const fetchTicket = async () => {
    try {
      const [
        ticketResponse,
        usersResponse,
        customersResponse,
        departmentsResponse,
        templatesResponse,
        requestersResponse,
        serviceDesksResponse,
      ] = await Promise.all([
        axios.get(`/tickets/${id}`, {}),
        axios.get(`/users/`, {}),
        axios.get(`/customers/`, {}),
        axios.get(`/departaments/`, {}),
        axios.get(`/templates/`, {}),
        axios.get(`/requesters/`, {}),
        axios.get(`/servicedesks/`),
      ]);

      const ticket = ticketResponse.data;
      const assignedUserId = ticket.assignedTo;
      const foundUser = usersResponse.data.find(
        (user) => user.id === assignedUserId
      );

      setSelectedUserId(assignedUserId);
      setSelectedUserName(
        foundUser ? foundUser.UserProfile.name : 'Usuário não encontrado'
      );

      const filteredUsers = usersResponse.data.filter(
        (user) => user.departmentId === ticket.department
      );

      // Se o usuário responsável não estiver na lista filtrada, adicione manualmente
      if (foundUser && !filteredUsers.includes(foundUser)) {
        filteredUsers.push(foundUser);
      }
      setUsers(filteredUsers); // Atualize a lista de usuários filtrados
      setDepartments(departmentsResponse.data);

      setDataLoaded(true);

      const requester = requestersResponse.data.find(
        (req) => req.id === ticket.requesterId
      );

      if (requester) {
        setClientPhone(requester.telefone);
        setClientCell(requester.celular);
      }

      const notes = await fetchCustomerInfo(ticket.company);

      setClientInfo(notes);

      setPriority(ticket.priority);
      setServiceDesk(ticket.serviceDesk);
      setStatus(ticket.status);
      setInternalCommunication(ticket.internalCommunication);
      setClientCommunication(ticket.clientCommunication);
      setSelectedCompany(ticket.company);
      setSelectedRequesterId(ticket.requesterId);
      setSelectedDepartment(ticket.department);
      setTitle(ticket.title);
      setSelectedTemplateId(ticket.templateId);
      setStages(ticket.stageid);

      setUsers(usersResponse.data);
      setCompanies(customersResponse.data);
      setDepartments(departmentsResponse.data);
      setEmailTemplates(templatesResponse.data);
      setServiceDesks(serviceDesksResponse.data);

      if (ticket.company) {
        await fetchRequesters(ticket.company);
      }

      const selectedServiceDesk = serviceDesksResponse.data.find(
        (desk) => desk.id === parseInt(ticket.serviceDesk, 10)
      );

      if (selectedServiceDesk) {
        setFilteredCompanies(selectedServiceDesk.customers);
        setFilteredDepartments(selectedServiceDesk.departments);
        setFilteredPriorities(selectedServiceDesk.priorities);
        setFilteredStages(selectedServiceDesk.stages);
      }

      setDataLoaded(true);

      fetchCommunicationHistory(id);
    } catch (error) {
      console.error('Failed to fetch ticket or users:', error);
      setDataLoaded(true);
    }
  };

  useEffect(() => {
    if (id) {
      fetchTicket();
    } else {
      Promise.all([
        axios.get(`/users/`, {}),
        axios.get(`/customers/`, {}),
        axios.get(`/departaments/`, {}),
        axios.get(`/templates/`, {}),
        axios.get(`/servicedesks/`),
      ])
        .then(
          ([
            usersResponse,
            customersResponse,
            departmentsResponse,
            templatesResponse,
            serviceDesksResponse,
          ]) => {
            setUsers(usersResponse.data);
            setCompanies(customersResponse.data);
            setDepartments(departmentsResponse.data);
            setEmailTemplates(templatesResponse.data);
            setServiceDesks(serviceDesksResponse.data);
            setDataLoaded(true);
          }
        )
        .catch((error) => {
          console.error('Failed to fetch data:', error);
          setDataLoaded(true);
        });
    }
    fetchUsersMap();
  }, [id]);

  const handleRequesterChange = (e) => {
    const requesterId = e.target.value;
    setSelectedRequesterId(requesterId);
  };

  const handleCompanyChange = (e) => {
    const companyId = e.target.value;
    setSelectedCompany(companyId);
    fetchRequesters(companyId);
    fetchCustomerInfo(companyId).then(setClientInfo);
  };

  const handleDepartmentChange = async (e) => {
    const departmentId = e.target.value;
    // Obter o nome do departamento atual
    const currentDepartment = filteredDepartments.find(
      (dept) => dept.id === parseInt(selectedDepartment, 10)
    );

    // Obter o nome do novo departamento
    const newDepartment = filteredDepartments.find(
      (dept) => dept.id === parseInt(departmentId, 10)
    );
    if (selectedDepartment !== departmentId) {
      createLogEntry(
        `${userData.name} alterou o departamento do ticket de ${
          currentDepartment ? currentDepartment.name : 'N/A'
        } para ${newDepartment ? newDepartment.name : 'N/A'}.`
      );
    }
    setSelectedDepartment(departmentId);
    try {
      // Faz a requisição à API para buscar usuários associados ao departamento selecionado
      const response = await axios.get(`/userdpto/${departmentId}`);

      // Verifica se há dados no retorno da API
      if (response.data && response.data.length > 0) {
        const filteredUsers = response.data.map((entry) => ({
          id: entry.userId,
          name: entry.UserProfile.name,
          lastname: entry.UserProfile.lastname,
        }));

        console.log('Usuários filtrados:', filteredUsers); // Verificação
        setUsers(filteredUsers); // Atualiza corretamente a lista de usuários
      } else {
        console.log('Nenhum usuário encontrado para o departamento');
        setUsers([]); // Nenhum usuário encontrado, limpa a lista de usuários
      }
    } catch (error) {
      console.error('Erro ao buscar usuários pelo departamento:', error);
      setUsers([]); // Em caso de erro, limpa a lista de usuários
    }
  };
  useEffect(() => {
    const fetchUsersByDepartment = async (departmentId) => {
      try {
        // Faz a requisição à API para buscar usuários associados ao departamento
        const response = await axios.get(`/userdpto/${departmentId}`);
        if (response.data && response.data.length > 0) {
          // Extrai os dados dos usuários retornados pela API
          const filteredUsers = response.data.map((entry) => ({
            id: entry.userId,
            name: entry.UserProfile.name,
            lastname: entry.UserProfile.lastname,
          }));

          setUsers(filteredUsers); // Atualiza a lista de usuários filtrados
        } else {
          setUsers([]); // Limpa a lista de usuários se a API não retornar nada
        }
      } catch (error) {
        console.error('Erro ao buscar usuários pelo departamento:', error);
        setUsers([]); // Em caso de erro, limpa a lista de usuários
      }
    };

    if (selectedDepartment) {
      fetchUsersByDepartment(selectedDepartment); // Chama a função ao carregar o departamento selecionado
    }
  }, [selectedDepartment]); // Dispara sempre que o selectedDepartment mudar

  const handleUserChange = (e) => {
    const userId = parseInt(e.target.value, 10);

    // Verifique se o usuário existe na lista filtrada
    const selectedUser = users.find((user) => user.id === userId);

    createLogEntry(`${userData.name} alterou o responsável do ticket.`); // Registra a ação no log

    setSelectedUserId(userId);
    setSelectedUserName(
      selectedUser && selectedUser.UserProfile
        ? selectedUser.UserProfile.name
        : 'Usuário não encontrado'
    );
  };

  const handlePriorityChange = (e) => {
    const priorityValue = e.target.value;

    const currentpriority = filteredPriorities.find(
      (prio) => prio.id === parseInt(priority, 10)
    );

    // Obter o nome do novo PRIORIDADE
    const newpriority = filteredPriorities.find(
      (prio) => prio.id === parseInt(priorityValue, 10)
    );

    if (priority !== priorityValue) {
      createLogEntry(
        `${userData.name} alterou a prioridade do ticket de ${currentpriority.name} para ${newpriority.name}.`
      );
    }
    setPriority(priorityValue);
  };

  const handleStagesChange = (e) => {
    const stageValue = e.target.value;
    const currentStage = filteredStages.find(
      (stg) => stg.id === parseInt(stageid, 10)
    );

    // Obter o nome do novo stage
    const newStage = filteredStages.find(
      (stg) => stg.id === parseInt(stageValue, 10)
    );

    if (stageid !== stageValue) {
      createLogEntry(
        `${userData.name} alterou a prioridade do ticket de ${currentStage.name} para ${newStage.name}.`
      );
    }

    setStages(stageValue);
  };

  const handleServiceDeskChange = (e) => {
    const serviceDeskValue = e.target.value;
    setServiceDesk(serviceDeskValue);

    const selectedServiceDesk = serviceDesks.find(
      (desk) => desk.id === parseInt(serviceDeskValue, 10)
    );

    if (selectedServiceDesk) {
      setFilteredCompanies(selectedServiceDesk.customers);
      setFilteredDepartments(selectedServiceDesk.departments);
      setFilteredPriorities(selectedServiceDesk.priorities);
      setFilteredStages(selectedServiceDesk.stages);
    }
  };

  const handleTemplateChange = (e) => {
    const templateId = e.target.value;
    setSelectedTemplateId(templateId);
    fetchTemplateContent(templateId);
  };

  const handleFileChange = (e) => {
    setFiles([...e.target.files]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const ticketData = {
      assignedTo: selectedUserId,
      requesterId: selectedRequesterId,
      priority,
      stageid,
      serviceDesk,
      status,
      internalCommunication,
      clientCommunication,
      company: selectedCompany,
      department: selectedDepartment,
      title,
      templateId: selectedTemplateId,
    };
    try {
      if (id) {
        await axios.put(`/tickets/${id}`, ticketData);
        createLogEntry(`${userData.name} atualizou o ticket.`);
      } else {
        await axios.post('/tickets', ticketData);
      }
      navigate('/tickets');
    } catch (error) {
      console.error('Failed to submit ticket:', error);
      alert('Falha ao enviar os dados do ticket.');
    }
  };

  const handleAddComment = async (message, type) => {
    setIsSending(true); // Iniciar o loading
    try {
      const formData = new FormData();
      formData.append('ticketId', id);
      formData.append('userId', userData.id);
      formData.append('clientName', selectedCompany);
      formData.append(
        type === 'client' ? 'clientMessage' : 'internalMessage',
        message
      );
      files.forEach((file) => {
        formData.append('files', file);
      });

      await axios.post('/communicationHistory', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      fetchCommunicationHistory(id);
      setFiles([]);
    } catch (error) {
      console.error('Failed to add comment:', error);
    } finally {
      setIsSending(false); // Finalizar o loading após o envio
    }
  };

  const parseFiles = (files) => {
    // Se já for um array, retorne diretamente
    if (Array.isArray(files)) {
      // Verifica se é um array de strings ou objetos
      if (typeof files[0] === 'string') {
        // Se for um array de strings (exemplo: ["/public/..."])
        return files.map((filePath) => ({
          originalname: filePath.split('/').pop(), // Nome do arquivo
          path: filePath, // Caminho completo do arquivo
        }));
      }
      // Se for um array de objetos (exemplo: [{ originalname: "...", path: "..."}])
      return files;
    }

    // Se for uma string JSON, tente fazer o parsing
    if (typeof files === 'string') {
      try {
        const parsedFiles = JSON.parse(files);
        // Verifica se após o parsing ainda é um array
        if (Array.isArray(parsedFiles)) {
          return parsedFiles;
        }
      } catch (error) {
        console.error('Erro ao fazer parse de files:', error);
        return [];
      }
    }

    // Se não for nem um array nem uma string válida, retorne um array vazio
    return [];
  };

  // Função para renderizar imagens inline substituindo as referências no corpo do e-mail
  const replaceInlineImages = (message, files) => {
    const parsedFiles = parseFiles(files); // Garante que temos um array de arquivos

    // Substitui as referências inline no HTML da mensagem
    parsedFiles.forEach((file) => {
      const fileUrl = getPublicFilePath(file.path || file); // Obtém o caminho do arquivo, lidando com ambos os formatos
      const regex = new RegExp(
        `\\[image: ${file.originalname || 'image.png'}\\]`,
        'g'
      ); // Substitui '[image: nome]' pelo caminho real da imagem
      message = message.replace(
        regex,
        `<img src="${fileUrl}" alt="inline image" style="max-width: 100%; height: auto;" />`
      );
    });

    return message;
  };

  const getPublicFilePath = (filePath) => {
    if (!filePath) {
      console.error('Caminho do arquivo indefinido');
      return '';
    }
    const pathSegments = filePath.split('/');
    const empresa = pathSegments[pathSegments.length - 3];
    const date = pathSegments[pathSegments.length - 2];
    const fileName = pathSegments[pathSegments.length - 1];
    return `${process.env.REACT_APP_API_BASE_URL}/download/${empresa}/${date}/${fileName}`;
  };

  const updateTicketStatus = async (newStatus) => {
    const prevStatus = status; // Captura o status anterior antes da atualização
    setStatus(newStatus); // Atualiza o status

    try {
      await axios.put(`/tickets/${id}`, { status: newStatus });
      notification(`Ticket atualizado com sucesso`, 'success');

      if (newStatus === 'fechado') {
        navigate('/tickets'); // Redireciona para a lista de tickets
      }
      // Se o novo status for "em_atendimento" e o status anterior for "pausado", apenas atualiza o status, sem enviar mensagem
      if (newStatus === 'em_atendimento' && prevStatus === 'pausado') {
        createLogEntry(`${userData.name} retomou o atendimento do ticket.`);
        return; // Retorna cedo, não envia a mensagem
      }

      // Garante que o template foi carregado
      if (!emailTemplate) {
        console.error('Email template not loaded');
        return;
      }

      // Seleciona a mensagem correta do template baseado no status
      let message = '';
      if (newStatus === 'em_atendimento') {
        message = emailTemplate.status_em_atendimento;
      } else if (newStatus === 'pausado') {
        message = emailTemplate.status_pausado;
      } else if (newStatus === 'fechado') {
        message = emailTemplate.status_fechado;
      } else {
        message = emailTemplate.status_aberto;
      }

      // Adiciona o logo da assinatura no final da mensagem
      const messageWithSignature = `
        ${message}

      `;

      await handleAddComment(messageWithSignature, 'client'); // Envia a mensagem com o logo ao cliente

      // Cria o log correspondente
      if (newStatus === 'em_atendimento' && prevStatus === 'pausado') {
        createLogEntry(`${userData.name} retomou o atendimento do ticket.`);
      } else if (newStatus === 'em_atendimento') {
        createLogEntry(`${userData.name} iniciou o atendimento do ticket.`);
      } else if (newStatus === 'pausado') {
        createLogEntry(`${userData.name} pausou o atendimento do ticket.`);
      } else if (newStatus === 'fechado') {
        createLogEntry(`${userData.name} encerrou o atendimento do ticket.`);
      }
    } catch (error) {
      notification(`Erro ao atualizar o status do ticket:`, 'success');
      console.error('Erro ao atualizar o status do ticket:', error);
    }
  };

  const handleStart = () => {
    setEmAtendimento(true); // Iniciar atendimento
    updateTicketStatus(
      'em_atendimento',
      `Você iniciou o atendimento do Ticket ${id}`
    );
  };

  const handlePause = () => {
    updateTicketStatus('pausado', `Você pausou o atendimento do Ticket ${id}`);
  };

  const handleEnd = () => {
    setEmAtendimento(false); // Reseta o estado de atendimento
    updateTicketStatus(
      'fechado',
      `Você encerrou o atendimento do Ticket ${id}`
    );
  };
  // Função para retomar o atendimento após pausar
  const handleResume = () => {
    setEmAtendimento(false); // Retomar o atendimento localmente
    updateTicketStatus(
      'em_atendimento',
      `Você retomou o atendimento do Ticket ${id}`
    );
  };

  if (!dataLoaded) {
    return <Loading />;
  }

  // Função para realizar a chamada ao clicar no botão de telefone
  const handlePhoneClick = (number) => {
    setCurrentPhoneNumber(number); // Armazena o número atual
    setShowCallModal(true); // Exibe o modal com a mensagem
    makeCall(number); // Realiza a chamada
    createLogEntry(`${userData.name} Ligou para o Nº ${number}.`);
  };

  if (!dataLoaded) {
    return <Loading />;
  }

  return (
    <Container fluid className="px-lg-4 px-xl-15">
      <Breadcrumbs
        title="Tickets"
        pages={[{ name: 'Tickets', link: '/tickets' }]}
      />
      <Modal show={showCallModal} onHide={handleCloseCallModal}>
        <Modal.Header closeButton>
          <Modal.Title>Ligação em andamento</Modal.Title>
        </Modal.Header>
        <Modal.Body>Ligando para o número: {currentPhoneNumber}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseCallModal}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      <Card>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>Título</Form.Label>
                  <Form.Control
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Modelo de Email</Form.Label>
                  <Form.Select
                    value={selectedTemplateId}
                    onChange={handleTemplateChange}
                    disabled
                  >
                    <option value="">--Selecione--</option>
                    {emailTemplates.map((template) => (
                      <option key={template.id} value={template.id}>
                        {template.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>Mesa de serviço</Form.Label>
                  <Form.Select
                    value={serviceDesk}
                    onChange={handleServiceDeskChange}
                    disabled
                  >
                    <option value="">--Selecione--</option>
                    {serviceDesks.map((desk) => (
                      <option key={desk.id} value={desk.id}>
                        {desk.displayName}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Empresa</Form.Label>
                  <Form.Select
                    value={selectedCompany}
                    onChange={handleCompanyChange}
                    disabled
                  >
                    <option value="">--Selecione--</option>
                    {filteredCompanies.map((company) => (
                      <option key={company.id} value={company.id}>
                        {company.razaoSocial}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Solicitante</Form.Label>
                  <Form.Select
                    value={selectedRequesterId}
                    onChange={handleRequesterChange}
                    disabled
                  >
                    <option value="">--Selecione--</option>
                    {requesters.map((requester) => (
                      <option key={requester.id} value={requester.id}>
                        {requester.name} - {requester.email}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>Telefone</Form.Label>
                  <div className="d-flex align-items-center">
                    <Form.Control type="text" value={clientPhone} disabled />
                    <Button
                      variant="link"
                      onClick={() => handlePhoneClick(clientPhone)}
                    >
                      <FaPhoneAlt /> Ligar
                    </Button>
                  </div>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Celular</Form.Label>
                  <div className="d-flex align-items-center">
                    <Form.Control type="text" value={clientCell} disabled />
                    <Button
                      variant="link"
                      onClick={() => handlePhoneClick(clientCell)}
                    >
                      <FaPhoneAlt /> Ligar
                    </Button>
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>Informações do Cliente</Form.Label>
                  <Form.Control
                    as="textarea"
                    value={clientInfo}
                    disabled
                    style={{ height: '150px' }} // Ajuste a altura aqui
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>Departamento</Form.Label>
                  <Form.Select
                    value={selectedDepartment}
                    onChange={handleDepartmentChange}
                  >
                    <option value="">--Selecione--</option>
                    {filteredDepartments.map((department) => (
                      <option key={department.id} value={department.id}>
                        {department.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Prioridade</Form.Label>
                  <Form.Select value={priority} onChange={handlePriorityChange}>
                    <option value="">--Selecione--</option>
                    {filteredPriorities.map((prio) => (
                      <option key={prio.id} value={prio.id}>
                        {prio.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>Responsável</Form.Label>
                  <Form.Select
                    value={selectedUserId}
                    onChange={handleUserChange}
                  >
                    <option value="">--Selecione--</option>
                    {users.length > 0 ? (
                      users.map((user) => (
                        <option key={user.id} value={user.id}>
                          {user.name && user.lastname
                            ? `${user.name} ${user.lastname}`
                            : `${user.UserProfile.name} ${user.UserProfile.lastname}`}
                        </option>
                      ))
                    ) : (
                      <option value="">Nenhum usuário disponível</option>
                    )}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Status</Form.Label>
                  <Form.Select
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    disabled
                  >
                    <option value="">--Selecione--</option>
                    <option value="em_atendimento">Em atendimento</option>
                    <option value="aberto">Aberto</option>
                    <option value="fechado">Fechado</option>
                    <option value="pausado">Pausado</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Estágio</Form.Label>
                  <Form.Select value={stageid} onChange={handleStagesChange}>
                    <option value="">--Selecione--</option>
                    {filteredStages.map((stage) => (
                      <option key={stage.id} value={stage.id}>
                        {stage.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Button type="submit">Atualizar</Button>
          </Form>
        </Card.Body>
        <div className="d-flex justify-content-around mt-3">
          {status === 'aberto' && (
            <Button variant="success" onClick={handleStart}>
              Iniciar Atendimento
            </Button>
          )}
          {status === 'em_atendimento' && (
            <>
              <Button variant="warning" onClick={handlePause}>
                Pausar
              </Button>
              <Button variant="danger" onClick={handleEnd}>
                Encerrar Atendimento
              </Button>
            </>
          )}
          {status === 'pausado' && (
            <Button variant="success" onClick={handleResume}>
              Retomar Atendimento
            </Button>
          )}
        </div>
        <Card.Footer>
          <Tabs
            defaultActiveKey="clientCommunication"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="clientCommunication" title="Comunicação com Cliente">
              <ReactQuill
                ref={clientQuillRef}
                theme="snow"
                value={clientCommunication}
                onChange={setClientCommunication}
                style={{ height: '200px' }}
              />
              <Form.Group className="mt-3">
                <Form.Label>
                  <strong />
                </Form.Label>
                <Form.Control
                  type="file"
                  multiple
                  ref={fileInputRef}
                  onChange={handleFileChange}
                />
              </Form.Group>
              <Button
                className="mt-3"
                onClick={() => {
                  handleAddComment(clientCommunication, 'client');
                  setClientCommunication('');
                }}
                disabled={isSending}
              >
                {isSending ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  'Enviar Comunicação'
                )}
              </Button>
              <h4 className="mt-4">Histórico de Comunicação com Cliente</h4>
              {clientCommunicationHistory.map((entry) => (
                <div
                  key={entry.id}
                  className={`message-container ${entry.whos === 'Cliente' ? 'client-message' : 'analyst-message'}`}
                >
                  <img
                    src={entry.whos === 'Cliente' ? clientIcon : analystIcon}
                    alt="Profile"
                    className="message-icon"
                  />
                  <div>
                    <strong>{entry.whos}</strong>{' '}
                    <span className="message-timestamp">
                      {new Date(entry.createdAt).toLocaleString()}
                    </span>
                    <div
                      className="message-text"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                          replaceInlineImages(entry.clientMessage, entry.files)
                        ),
                      }}
                    />
                    {entry.files && entry.files.length > 0 && (
                      <div>
                        <h6>Arquivos em anexo:</h6>
                        <ul>
                          {parseFiles(entry.files).map((file, index) => (
                            <div key={index}>
                              <img
                                src={getPublicFilePath(file.path)}
                                alt={file.originalname}
                                style={{ maxWidth: '100%', height: 'auto' }}
                              />
                            </div>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </Tab>
            <Tab eventKey="internalCommunication" title="Comunicação Interna">
              <ReactQuill
                ref={internalQuillRef}
                theme="snow"
                value={internalCommunication}
                onChange={setInternalCommunication}
                style={{ height: '200px' }}
              />
              <Form.Group className="mt-3">
                <Form.Label>
                  <strong />
                </Form.Label>
                <Form.Control
                  type="file"
                  multiple
                  ref={fileInputRef}
                  onChange={handleFileChange}
                />
              </Form.Group>
              <Button
                className="mt-3"
                onClick={() => {
                  handleAddComment(internalCommunication, 'internal');
                  setInternalCommunication('');
                }}
              >
                {isLoading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  'Enviar Comunicação'
                )}
              </Button>
              <h4 className="mt-4">Histórico de Comunicação Interna</h4>
              {internalCommunicationHistory.map((entry) => (
                <div key={entry.id} className="mb-3">
                  <strong>{entry.whos}</strong>{' '}
                  <span>{new Date(entry.createdAt).toLocaleString()}</span>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(entry.internalMessage),
                    }}
                  />
                  {entry.files && entry.files.length > 0 && (
                    <div>
                      <h6>Arquivos:</h6>
                      <ul>
                        {parseFiles(entry.files).map((file, index) => (
                          <li key={index}>
                            <a
                              href={getPublicFilePath(file.path)}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {file.originalname}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              ))}
            </Tab>
            <Tab eventKey="timeline" title="Linha do Tempo">
              <h4 className="mt-4">Logs do Ticket</h4>
              {timelineLogs.length === 0 ? (
                <p>Nenhum log disponível.</p>
              ) : (
                timelineLogs.map((log, index) => (
                  <div key={index} className="mb-3">
                    <strong>{log.user_name}</strong>{' '}
                    <span>{new Date(log.createdAt).toLocaleString()}</span>
                    <p>{log.action}</p>
                  </div>
                ))
              )}
            </Tab>
            <Tab eventKey="recentTickets" title="Últimos Tickets">
              {recentTickets.length === 0 ? (
                <p>Nenhum ticket encontrado.</p>
              ) : (
                <ul>
                  {recentTickets.map((ticket) => (
                    <li key={ticket.id}>
                      <strong>Ticket:</strong> {ticket.id} - {ticket.title}
                      <Button
                        variant="link"
                        onClick={() => navigate(`/tickets/${ticket.id}/edit`)}
                      >
                        Ver Detalhes
                      </Button>
                    </li>
                  ))}
                </ul>
              )}
            </Tab>
          </Tabs>
        </Card.Footer>
      </Card>
    </Container>
  );
}
