import React, { useState, useEffect } from 'react';
import { isEmail } from 'validator';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';

import * as actions from '../../store/modules/auth/actions';
import Loading from '../../components/Loading';
import Image from '../../components/CustomImage';
import { useNotification } from '../../components/Notification/NotificationContext';

export default function Login() {
  const notification = useNotification();
  const location = useLocation();
  const dispatch = useDispatch();
  const prevPath = get(location, 'state.prevPath', '/');
  const isLoading = useSelector((state) => state.auth.isLoading);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const loginError = useSelector((state) => state.auth.loginError);
  const userData = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(false);
  const [tryLogin, seTryLogin] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    let formErrors = false;

    if (!isEmail(email)) {
      formErrors = true;
      notification('E-mail inválido.', 'danger', 'Título', 'Agora', 5000);
    }

    if (password.length < 1) {
      formErrors = true;
      notification('Senha obrigatória', 'danger');
    }

    if (formErrors) return;

    seTryLogin(true);
    dispatch(actions.loginRequest({ email, password, prevPath, remember }));
  };

  useEffect(() => {
    if (isLoggedIn) {
      if (userData.isadmin !== 1) {
        if (
          typeof userData.userModules === 'object' &&
          userData.userModules.length > 0
        ) {
          let redirModule = false;
          userData.userModules.forEach((module) => {
            if (prevPath === module.page) {
              redirModule = true;
            }
          });

          if (!redirModule && userData.userModules) {
            const reorderModules = userData.userModules.sort(
              (a, b) => a.order - b.order
            );
            navigate(reorderModules[0].page);
            return;
          }
        }
      }
      navigate(prevPath);
    } else if (loginError === 1 && tryLogin === true) {
      notification('Usuário o senha inválidos');
    }
  }, [isLoggedIn, prevPath, loginError, userData]);

  return (
    <Container fluid className="px-0 overflow-hidden">
      <Loading isLoading={isLoading} />
      <Row className="gx-0 min-vh-100">
        <Col
          md={9}
          lg={6}
          xl={4}
          className="px-5 d-flex align-items-top shadow"
        >
          <div className="w-100 py-5">
            <div className="text-center">
              <img
                className="img-fluid mb-4"
                src="img/logos/opennewLogo.png"
                alt="..."
                style={{ maxWidth: '6rem' }}
              />
              <h1 className="h4 text-uppercase mb-5">Bem-vindo de volta!</h1>
            </div>
            <Form onSubmit={handleSubmit}>
              <div className="mb-3">
                <Form.Label className="form-label">Email</Form.Label>
                <Form.Control
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Entre com seu e-mail"
                />
              </div>
              <div className="mb-4">
                <Row>
                  <Col>
                    <Form.Label className="form-label">Senha</Form.Label>
                  </Col>
                  <Col xs="auto">
                    <Link
                      className="form-text small text-muted"
                      to="/resetpassword"
                    >
                      Esqueceu sua senha ?
                    </Link>
                  </Col>
                </Row>
                <Form.Control
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Entre com sua senha"
                />
              </div>
              <Form.Check
                className="mb-4"
                id="remember"
                label="Mantenha-me conectado"
                checked={remember}
                onChange={() => setRemember(!remember)}
              />
              <div className="d-grid mb-5">
                <Button
                  type="submit"
                  variant="primary"
                  className="text-uppercase"
                >
                  Acessar
                </Button>
              </div>
            </Form>
          </div>
        </Col>
        <Col md={3} lg={6} xl={8} className="d-none d-md-block">
          <div className="bg-cover h-100 me-n3 position-relative">
            <Image
              src="/img/photos/greenShapes.jpg"
              alt="..."
              layout="fill"
              objectFit="cover"
              sizes="75vw"
              height={900}
              priority
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
}
