import React, { useState, useEffect } from 'react';
import { Container, Card, Form, Button, Table, Modal } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from '../../services/axios';
import Loading from '../../components/Loading';
import Breadcrumbs from '../../components/Breadcrumbs';

export default function EmailTemplates() {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [templateName, setTemplateName] = useState('');
  const [templateContent, setTemplateContent] = useState('');
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);

  const fetchTemplates = async () => {
    try {
      const response = await axios.get('/templates');
      setTemplates(response.data);
      setDataLoaded(true);
    } catch (error) {
      console.error('Failed to fetch templates:', error);
      setDataLoaded(true);
    }
  };

  useEffect(() => {
    fetchTemplates();
  }, []);

  const handleShowModal = (isEdit, template = {}) => {
    setIsEditing(isEdit);
    setTemplateName(isEdit ? template.name : '');
    setTemplateContent(isEdit ? template.content : '');
    setSelectedTemplateId(isEdit ? template.id : null);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setTemplateName('');
    setTemplateContent('');
    setSelectedTemplateId(null);
  };

  const handleSaveTemplate = async () => {
    const templateData = {
      name: templateName,
      content: templateContent,
    };

    try {
      if (isEditing && selectedTemplateId) {
        await axios.put(`/templates/${selectedTemplateId}`, templateData);
      } else {
        await axios.post('/templates', templateData);
      }
      fetchTemplates();
      handleCloseModal();
    } catch (error) {
      console.error('Failed to save template:', error);
      alert('Falha ao salvar o modelo de e-mail.');
    }
  };

  const handleDeleteTemplate = async (templateId) => {
    try {
      await axios.delete(`/templates/${templateId}`);
      fetchTemplates();
    } catch (error) {
      console.error('Failed to delete template:', error);
      alert('Falha ao excluir o modelo de e-mail.');
    }
  };

  if (!dataLoaded) {
    return <Loading />;
  }

  return (
    <Container fluid className="px-lg-4 px-xl-15">
      <Breadcrumbs
        title="Modelos de Email"
        pages={[{ name: 'Modelos de Email', link: '/email-templates' }]}
      />
      <div className="page-header d-flex justify-content-between align-items-center">
        <h1>Modelos de Email</h1>
        <Button onClick={() => handleShowModal(false)}>Novo Modelo</Button>
      </div>
      <Card>
        <Card.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Nome</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {templates.map((template) => (
                <tr key={template.id}>
                  <td>{template.name}</td>
                  <td>
                    <Button
                      variant="primary"
                      onClick={() => handleShowModal(true, template)}
                      className="me-2"
                    >
                      Editar
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => handleDeleteTemplate(template.id)}
                    >
                      Excluir
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      {/* Modal for adding/editing templates */}
      <Modal show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            {isEditing ? 'Editar Modelo' : 'Novo Modelo'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Nome</Form.Label>
              <Form.Control
                type="text"
                value={templateName}
                onChange={(e) => setTemplateName(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Conteúdo</Form.Label>
              <ReactQuill
                theme="snow"
                value={templateContent}
                onChange={setTemplateContent}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Fechar
          </Button>
          <Button variant="primary" onClick={handleSaveTemplate}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}
