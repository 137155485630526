export function formatPhoneBr(phone) {
  const digitsOnly = phone.replace(/\D/g, '');

  if (digitsOnly.length === 13 && digitsOnly.startsWith('55')) {
    return digitsOnly.replace(/(\d{2})(\d{2})(\d{5})(\d{4})/, '($1) $2 $3-$4');
  }

  if (digitsOnly.length === 12 && digitsOnly.startsWith('55')) {
    return digitsOnly.replace(/(\d{2})(\d{2})(\d{4})(\d{4})/, '($1) $2 $3-$4');
  }

  if (digitsOnly.length === 11) {
    return digitsOnly.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
  }

  if (digitsOnly.length === 10) {
    return digitsOnly.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
  }

  return phone;
}
