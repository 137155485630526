import React, { createContext, useContext, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Notification from '.';

const NotificationContext = createContext();

export function NotificationProvider({ children }) {
  const [notifications, setNotifications] = useState([]);

  const showNotification = useCallback(
    (message, type, delay = 15000, title = 'Aviso', date = 'agora') => {
      const newNotification = {
        message,
        type,
        title,
        date,
        delay,
        id: new Date().getTime(), // Adicionando um ID único para cada notificação
      };

      setNotifications((prevNotifications) => [
        ...prevNotifications,
        newNotification,
      ]);

      setTimeout(() => {
        setNotifications((prevNotifications) =>
          prevNotifications.filter((n) => n.id !== newNotification.id)
        );
      }, delay);
    },
    []
  );

  return (
    <NotificationContext.Provider value={showNotification}>
      {children}
      {notifications.map((notification) => (
        <Notification key={notification.id} {...notification} />
      ))}
    </NotificationContext.Provider>
  );
}

NotificationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error(
      'useNotification must be used within a NotificationProvider'
    );
  }
  return context;
};
