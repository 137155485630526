import React, { useEffect, useRef } from 'react';
import { InputGroup, Form } from 'react-bootstrap';
import { Datepicker, DateRangePicker } from 'vanillajs-datepicker';
import PropTypes from 'prop-types';
import ptBR from './localePtBr';

export default function DatepickerComponent({
  range,
  autohide,
  maxNumberOfDates,
  defaultValue,
  size,
  format,
  buttonSearch,
  addProps,
}) {
  const datepickerRef = useRef(false);
  Datepicker.locales['pt-BR'] = ptBR;

  useEffect(() => {
    const datepicker = range
      ? new DateRangePicker(datepickerRef.current, {
          buttonClass: 'btn',
          format,
          autohide,
          language: 'pt-BR',
        })
      : new Datepicker(datepickerRef.current, {
          buttonClass: 'btn',
          format,
          autohide,
          maxNumberOfDates,
          language: 'pt-BR',
        });

    datepickerRef.current.addEventListener('changeDate', (event) => {
      const dataReturn = { start: false, end: false };
      if (event.target.id === 'startDateDatepicker') {
        dataReturn.start = event.detail.date;
      }

      if (event.target.id === 'endDateDatepicker') {
        dataReturn.end = event.detail.date;
      }

      if (addProps && addProps.onChange) {
        addProps.onChange({ ...dataReturn });
      }
    });

    return () => datepicker.destroy();
  }, [maxNumberOfDates, autohide, range, format, addProps]);

  return range ? (
    <InputGroup ref={datepickerRef}>
      <InputGroup.Text>De</InputGroup.Text>
      <Form.Control
        defaultValue={
          defaultValue && defaultValue.start ? defaultValue.start : ''
        }
        {...addProps}
        id="startDateDatepicker"
        type="text"
      />
      <InputGroup.Text>até</InputGroup.Text>
      <Form.Control
        defaultValue={defaultValue && defaultValue.end ? defaultValue.end : ''}
        {...addProps}
        id="endDateDatepicker"
        type="text"
      />
      {buttonSearch}
    </InputGroup>
  ) : (
    <Form.Control
      defaultValue={defaultValue}
      size={size}
      type="text"
      ref={datepickerRef}
      {...addProps}
    />
  );
}

DatepickerComponent.defaultProps = {
  range: false,
  autohide: true,
  maxNumberOfDates: null,
  defaultValue: '',
  size: 36,
  format: 'dd/mm/yyyy',
  addProps: {},
  buttonSearch: null,
};

DatepickerComponent.propTypes = {
  format: PropTypes.string,
  range: PropTypes.bool,
  autohide: PropTypes.bool,
  maxNumberOfDates: PropTypes.number,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ start: PropTypes.string, end: PropTypes.string }),
  ]),
  size: PropTypes.number,
  addProps: PropTypes.shape({ onChange: PropTypes.func }),
  buttonSearch: PropTypes.node,
};
