/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Dropdown, NavItem, NavLink } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faUpload } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import axios from '../../services/axios';
import Icon from '../Icon';

export default function Notifications() {
  const [notifications, setNotifications] = useState([]);
  const [previousNotifications, setPreviousNotifications] = useState([]); // Para comparar novas notificações
  const [, setApiErrors] = useState({});
  const navigate = useNavigate();
  const userId = useSelector((state) => state.auth.user.id); // Obtém o ID do usuário logado pelo Redux

  // Função para tocar som ao receber notificação
  const playNotificationSound = () => {
    const audio = new Audio('/sounds/notification.mp3'); // Som que você deseja usar
    audio.play();
  };

  const fetchNotifications = async () => {
    try {
      if (!userId) return;

      // Faz as requisições para os endpoints /pretickets e /forUser/$userId
      const [preTicketsResponse, userTicketsResponse] = await Promise.all([
        axios.get('/pretickets'),
        axios.get(`/tickets/forUser/${userId}`),
      ]);

      const newNotifications = [
        {
          type: 'message',
          content: `Existem ${preTicketsResponse.data.length} pré-tickets.`,
        },
        {
          type: 'system',
          content: `Você tem ${userTicketsResponse.data.length} tickets em aberto.`,
        },
      ];

      // Condição para tocar som de notificação apenas se houver pré-tickets ou tickets abertos
      const totalNotifications =
        preTicketsResponse.data.length + userTicketsResponse.data.length;

      // Somente toca o som se houver notificações (1 ou mais pré-tickets ou tickets abertos)
      if (
        totalNotifications > 0 &&
        JSON.stringify(newNotifications) !==
          JSON.stringify(previousNotifications)
      ) {
        playNotificationSound();
        setPreviousNotifications(newNotifications); // Atualiza o estado anterior
      }

      setNotifications(newNotifications);
    } catch (err) {
      setApiErrors(err);
    }
  };

  useEffect(() => {
    fetchNotifications(); // Carrega as notificações ao carregar o componente

    // Configura polling para buscar notificações a cada 30 segundos
    const pollingInterval = setInterval(fetchNotifications, 30000); // 30 segundos

    // Limpa o intervalo ao desmontar o componente
    return () => clearInterval(pollingInterval);
  }, [userId]);

  // Função para redirecionar ao clicar nas notificações
  const handleClickNotification = (notification) => {
    if (notification.type === 'message') {
      navigate('/pretickets'); // Redireciona para a tela de pré-tickets
    } else if (notification.type === 'system') {
      navigate('/tickets'); // Redireciona para a tela de tickets
    }
  };

  return (
    <Dropdown as={NavItem} className="me-2" align="end">
      <Dropdown.Toggle
        as={NavLink}
        className="text-red-400 px-1 nav-link-icon"
        id="notifications"
      >
        <Icon icon="message-bubble-1" className="svg-icon-md svg-icon-heavy" />
        <span className="notification-badge bg-green" />
      </Dropdown.Toggle>
      <Dropdown.Menu
        data-bs-popper="none"
        className="text-sm dropdown-menu-animated"
        aria-labelledby="notifications"
      >
        {notifications.map((notification) => (
          <Dropdown.Item
            href="#"
            key={notification.content}
            onClick={() => handleClickNotification(notification)}
          >
            <div className="d-flex align-items-center">
              <div
                className={`icon icon-sm text-white ${
                  notification.type === 'twitter' ? 'bg-indigo' : 'bg-blue'
                } ${notification.type === 'message' ? 'bg-red' : 'bg-blue'}`}
              >
                {notification.type === 'twitter' && (
                  <FontAwesomeIcon icon={faTwitter} />
                )}
                {notification.type === 'system' && (
                  <FontAwesomeIcon icon={faUpload} />
                )}
                {notification.type === 'message' && (
                  <FontAwesomeIcon icon={faEnvelope} />
                )}
              </div>
              <div className="text ms-2">
                <p className="mb-0">{notification.content}</p>
              </div>
            </div>
          </Dropdown.Item>
        ))}

        <Dropdown.Divider />
        <Dropdown.Item className="text-center" href="#">
          <small className="fw-bold text-uppercase">
            View all notifications
          </small>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
