import React from 'react';
import { Navigate, useLocation, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function PrivateRoute() {
  const location = useLocation();
  const userData = useSelector((state) => state.auth.user);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  if (!isLoggedIn) {
    return <Navigate to="/login" state={{ prevPath: location.pathname }} />;
  }

  if (userData.isadmin !== 1) {
    let isModule = false;

    userData.modules.forEach((module) => {
      if (module.page === `/${location.pathname.split('/')[1]}`) {
        isModule = true;
      }
    });

    if (isModule) {
      let passModule = false;
      userData.userModules.forEach((module) => {
        if (module.page === `/${location.pathname.split('/')[1]}`) {
          passModule = true;
        }
      });

      if (!passModule) {
        return <Navigate to="/403" state={{ prevPath: location.pathname }} />;
      }
    }
  }

  return <Outlet />;
}
