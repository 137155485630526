import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Card, Button } from 'react-bootstrap';
import { FaEdit, FaPlus } from 'react-icons/fa';
import axios from '../../services/axios';
import Loading from '../../components/Loading';
import Breadcrumbs from '../../components/Breadcrumbs';
import DataTable from '../../components/DataTable';
import ErrorProcessor from '../../components/ErrorProcessor';

export default function ApiLigacoesIndex() {
  const routeName = '/apitelefonia';
  const apiname = '/ligacoes';
  const titleName = 'API de Ligações';
  const navigate = useNavigate();
  const [apiErrors, setApiErrors] = useState(null);
  const [apiData, setApiData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function fetchApiData() {
      setIsLoading(true);
      try {
        const response = await axios.get(`${apiname}`);
        setApiData(response.data);
      } catch (error) {
        console.error('Erro ao carregar dados:', error);
        setApiErrors(error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchApiData();
  }, [apiname]);

  // Definindo colunas da tabela, incluindo "Ações"
  const tableColumns = useMemo(
    () => [
      { Header: 'URL', accessor: 'url' },
      { Header: 'Método', accessor: 'method' },
      { Header: 'Tipo de Body', accessor: 'bodyType' },
      {
        Header: 'Ações', // Adicionando a coluna de "Ações"
        accessor: 'actions',
        noSearch: true, // Desabilitar pesquisa nessa coluna se necessário
        disableSortBy: true, // Desabilitar ordenação nessa coluna
      },
    ],
    []
  );

  // Dados da tabela com o botão de "Editar"
  const tableData = useMemo(() => {
    return apiData.map((apiLigacao) => ({
      ...apiLigacao,
      actions: (
        <div className="d-flex justify-content-end">
          <Button
            variant="primary"
            onClick={() => navigate(`${routeName}/${apiLigacao.id}/edit`)}
          >
            <FaEdit /> Editar
          </Button>
        </div>
      ),
    }));
  }, [apiData, navigate, routeName]);

  return (
    <Container fluid className="px-lg-4 px-xl-5">
      <Loading isLoading={isLoading} />
      <Breadcrumbs title={titleName} />
      <div className="page-header d-flex justify-content-between align-items-center">
        <h1 className="page-heading">{titleName}</h1>

        {/* Botão de Cadastrar API */}
        <Button
          variant="primary"
          className="text-uppercase"
          onClick={() => navigate(`${routeName}/new`)} // Redireciona para a rota de cadastro
        >
          <FaPlus /> Cadastrar API
        </Button>
      </div>
      <section>
        <Card className="card-table mb-5">
          <ErrorProcessor error={apiErrors} />
          <DataTable
            items={tableData}
            columns={tableColumns}
            defaultPageSize={5}
            actions
          />
        </Card>
      </section>
    </Container>
  );
}
