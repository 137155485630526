import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Container, Card, Row, Col } from 'react-bootstrap';
import { get } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { FaTrash } from 'react-icons/fa';

import Loading from '../../components/Loading';
import axios from '../../services/axios';
import Breadcrumbs from '../../components/Breadcrumbs';
import { useNotification } from '../../components/Notification/NotificationContext';
import Forms from '../../components/Forms';
import ErrorProcessor from '../../components/ErrorProcessor';
import ButtonModal from '../../components/ModalConfirmation/ButtonModal';
import DataTable from '../../components/DataTable';
import PageHeader from '../../components/PageHeader';

export default function AccessCompany() {
  const routeName = '/accessescompanies';
  const titleName = 'Empresas';
  const dataName = 'Acesso';
  const [dataLoaded, setDataLoaded] = useState(false);
  const [apiErrors, setApiErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const notification = useNotification();
  const navigate = useNavigate();
  const params = useParams();
  const id = get(params, 'id', '');
  const [accesses, setAccesses] = useState([]);
  const [company, setCompany] = useState({ Accesses: [] });
  const [formValues, setFormValues] = useState({
    company_id: '',
  });

  const validationSchema = Yup.object().shape({
    access_id: Yup.string().required('Acesso obrigatório.'),
  });

  const tableColumns = useMemo(
    () => [
      {
        Header: 'Acesso',
        accessor: 'name',
      },
      {
        Header: 'Remover',
        accessor: 'remove',
        noSearch: true,
      },
    ],
    []
  );

  const handleFieldChange = useCallback((fieldName, value) => {
    setFormValues((prevValues) => ({ ...prevValues, [fieldName]: value }));
  }, []);

  const fields = [
    {
      name: 'access_id',
      id: 'access_id',
      label: 'Acesso',
      type: 'select',
      options: accesses,
      onChange: (e) =>
        handleFieldChange('access_id', e.value ? e.value : e.target.value),
      colSize: 12,
      additionalProps: {},
    },
  ];

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      await axios.post(routeName, {
        ...formValues,
        ...{ company_id: id },
      });
      const getCompany = await axios.get(`companies/${id}`);
      setCompany(getCompany.data);
      notification(`${dataName} adicionado com sucesso`, 'success');
    } catch (err) {
      setApiErrors(err);
    } finally {
      setIsLoading(false);
    }
  };

  const confirmDelete = useCallback(async (AccessCompanyId) => {
    try {
      setIsLoading(true);
      await axios.delete(`${routeName}/${AccessCompanyId}`);
      const getCompany = await axios.get(`companies/${id}`);
      setCompany(getCompany.data);
      notification(`${dataName} removido da empresa com sucesso`, 'success');
    } catch (err) {
      setApiErrors(err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const tableData = useMemo(() => {
    return company.Accesses.map((access) => ({
      remove: (
        <ButtonModal
          onConfirm={() => confirmDelete(access.AccessCompany.id)}
          message={`Confirma remoção do acesso ${access.name} ?`}
          buttonProps={{
            variant: 'danger',
            className: 'rounded-pill shadow',
            size: 'sm',
          }}
          textModal={{
            title: 'Remover acesso da empresa',
            textConfirm: 'Remover',
          }}
        >
          <FaTrash />
        </ButtonModal>
      ),
      ...access,
    }));
  }, [company]);

  useEffect(() => {
    if (!id) {
      navigate('/companies');
      notification(`Empresa inválida ou não informada`);
      return;
    }

    async function getData() {
      try {
        setIsLoading(true);
        const options = [{ value: '', label: '-- Selecione --' }];
        const getCompany = await axios.get(`companies/${id}`);
        const getAccesses = await axios.get(`accesses`);

        setCompany(getCompany.data);

        if (getAccesses) {
          getAccesses.data.forEach((data) => {
            options.push({
              value: data.id,
              label: data.name,
            });
          });
        }
        setAccesses(options);
      } catch (err) {
        setApiErrors(err);
      } finally {
        setIsLoading(false);
        setDataLoaded(true);
      }
    }

    getData();
  }, [id, notification, navigate]);

  return (
    <Container fluid className="px-lg-4 px-xl-5">
      <Loading isLoading={isLoading} />
      <Breadcrumbs
        title="Acesso"
        pages={[{ link: '/companies', name: 'Empresas' }]}
      />
      <PageHeader title={titleName} />
      <section>
        <Row>
          <Col lg={8}>
            <Card className="mb-4">
              <Card.Header>
                <h4 className="card-heading">
                  Acessos da empresa {company.name}
                </h4>
              </Card.Header>
              <Card.Body>
                <DataTable
                  items={tableData}
                  columns={tableColumns}
                  defaultPageSize={5}
                />
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4}>
            <Card className="mb-5">
              <Card.Header>
                <h4 className="card-heading">Adicionar acesso</h4>
              </Card.Header>
              <Card.Body>
                <ErrorProcessor error={apiErrors} />
                {!id || dataLoaded ? (
                  <Forms
                    initialValues={formValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    fields={fields}
                    btnSubmit={{
                      variant: 'primary',
                      type: 'submit',
                      text: 'Adicionar',
                    }}
                  />
                ) : null}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </section>
    </Container>
  );
}
