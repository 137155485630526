import React, { useState } from 'react';
import { Toast } from 'react-bootstrap';
import PropTypes from 'prop-types';

export default function Notification({ type, title, message, date, delay }) {
  const [toastState, setToastState] = useState(true);

  const handleClose = () => {
    if (toastState !== false) {
      setToastState(false);
    }
  };

  return (
    <Toast
      show={toastState}
      onClose={handleClose}
      delay={type === 'success' ? 2000 : delay}
      autohide
      style={{
        position: 'fixed',
        top: '10%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: '999',
      }}
    >
      <Toast.Header>
        <span className={`dot bg-${type} me-2`} />
        <div className="card-heading text-dark me-auto">{title} </div>
        <small>{date}</small>
      </Toast.Header>

      <Toast.Body className="text-muted">{message}</Toast.Body>
    </Toast>
  );
}

Notification.defaultProps = {
  type: 'danger',
  title: 'Aviso',
  date: 'agora',
  delay: 15000,
};

Notification.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  type: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
  delay: PropTypes.number,
};
