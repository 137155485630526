import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import PropTypes from 'prop-types';
import { FaFileExcel } from 'react-icons/fa';
import Spinner from 'react-bootstrap/Spinner';

export default function ToXLSX({ data, fileName, classname, btnText }) {
  const [loading, setLoading] = useState(false);

  const handleDownload = () => {
    setLoading(true);
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, `${fileName}`);
    setLoading(false);
  };

  return (
    <Button
      title="Clique para exportar para excel"
      variant="success"
      className={classname}
      onClick={handleDownload}
      disabled={loading}
    >
      {loading ? (
        <>
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          <span className="visually-hidden">Carregando...</span>
        </>
      ) : (
        <>
          <FaFileExcel /> {btnText}
        </>
      )}
    </Button>
  );
}

ToXLSX.defaultProps = {
  data: [],
  fileName: 'myExport.xlsx',
  classname: 'text-white shadow me-2',
  btnText: '',
};

ToXLSX.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  fileName: PropTypes.string,
  classname: PropTypes.string,
  btnText: PropTypes.string,
};
