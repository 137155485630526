import React, { useState, useEffect, useRef } from 'react';
import { Container, Form, Card, Button, Row, Col } from 'react-bootstrap';
import { get } from 'lodash';
import * as Yup from 'yup';

import { useParams } from 'react-router-dom';
import Loading from '../../components/Loading';
import axios from '../../services/axios';
import { useNotification } from '../../components/Notification/NotificationContext';
import Breadcrumbs from '../../components/Breadcrumbs';
import Image from '../../components/CustomImage';
import Dropzone from '../../components/Dropzone';
import ErrorProcessor from '../../components/ErrorProcessor';
import PageHeader from '../../components/PageHeader';
import Forms from '../../components/Forms';

// Definir o URL base da API para concatenar o caminho da foto
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export default function UserProfile() {
  const notification = useNotification();
  const [apiErrors, setApiErrors] = useState({});
  const params = useParams();
  const id = get(params, 'id', '');
  const formRefProfile = useRef();

  const [dataLoaded, setDataLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [photo, setPhoto] = useState(false);
  const [photoUrl, setPhotoUrl] = useState('');
  const [userId, setUserId] = useState('');
  const [name, setName] = useState('');
  const [lastname, setLastname] = useState('');
  const [ramal, setRamal] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, 'Nome deve conter no mínimo 3 caracteres.')
      .required('Nome obrigatório.'),
    ramal: Yup.string()
      .min(3, 'Ramal deve conter no mínimo 3 caracteres.')
      .required('Nome obrigatório.'),
    lastname: Yup.string()
      .min(3, 'Sobrenome deve conter no mínimo 3 caracteres.')
      .required('Sobrenome obrigatório.'),
    password: Yup.string().min(6, 'Senha deve conter no mínimo 6 caracteres.'),
    confirmPassword: Yup.string().when('password', {
      is: (pass) => pass && pass.length > 0,
      then: () =>
        Yup.string()
          .oneOf([Yup.ref('password'), null], 'Senhas devem ser iguais')
          .required('Campo obrigatório quando a senha é fornecida.'),
    }),
  });

  const formSubmitProfile = () => {
    if (formRefProfile.current) {
      formRefProfile.current.submitForm();
    }
  };

  const fields = [
    {
      name: 'name',
      id: 'name',
      label: 'Nome',
      type: 'text',
      onChange: (e) => setName(e.target.value),
      colSize: 6,
      additionalProps: {},
    },
    {
      name: 'lastname',
      id: 'lastname',
      label: 'Sobrenome',
      type: 'text',
      onChange: (e) => setLastname(e.target.value),
      colSize: 6,
      additionalProps: {},
    },
    {
      name: 'password',
      id: 'password',
      label: 'Alterar senha',
      type: 'password',
      onChange: (e) => setPassword(e.target.value),
      colSize: 6,
      additionalProps: {},
    },
    {
      name: 'confirmPassword',
      id: 'confirmPassword',
      label: 'Confirme a nova senha',
      type: 'password',
      colSize: 6,
      additionalProps: {},
    },
    {
      name: 'ramal',
      id: 'ramal',
      label: 'Ramal',
      type: 'text',
      onChange: (e) => setRamal(e.target.value),
      colSize: 6,
      additionalProps: {},
    },
  ];

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        setUserId(id);
        const { data } = await axios.get(`users/${id}`);

        // Concatena o caminho base com o caminho da foto recebido da API
        const photoPath = get(data, 'UserProfile.photo', '');
        const fullPhotoUrl = photoPath ? `${API_BASE_URL}${photoPath}` : '';
        setPhotoUrl(fullPhotoUrl);

        setName(data.UserProfile.name);
        setLastname(data.UserProfile.lastname);
        setRamal(data.ramal);
        setEmail(data.email);
      } catch (err) {
        setApiErrors(err);
      } finally {
        setIsLoading(false);
        setDataLoaded(true);
      }
    };
    getData();
  }, [id, notification]);

  const handleSubmitPhoto = async (e) => {
    e.preventDefault();

    if (!photo) {
      notification('Nenhuma foto informada');
      return;
    }

    const formData = new FormData();
    formData.append('photo', photo);
    try {
      setIsLoading(true);
      await axios.post(`/profiles/photoupdate/${userId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      notification('Foto atualizada com sucesso', 'success');
    } catch (err) {
      setApiErrors(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmitProfile = async () => {
    const formData = { name, lastname, ramal };

    if (password) {
      formData.password = password;
    }

    try {
      setIsLoading(true);
      await axios.put(`/users/${userId}`, formData);
      notification('Perfil atualizado com sucesso', 'success');
    } catch (err) {
      setApiErrors(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileChange = (e) => {
    const newPhoto = e[0];
    const urlPhoto = URL.createObjectURL(newPhoto);
    setPhotoUrl(urlPhoto);
    setPhoto(newPhoto);
  };

  return (
    <Container fluid className="px-lg-4 px-xl-5">
      <Loading isLoading={isLoading} />
      <Breadcrumbs
        title="Perfil"
        pages={[{ link: '/users', name: 'Usuários' }]}
      />
      <PageHeader title="Meu Perfil" />
      <section>
        <Row>
          <Col lg={4}>
            <Card className="card-profile mb-4">
              <Card.Header
                className="overflow-hidden"
                style={{
                  backgroundImage: `url(/img/photos/greenShapes.jpg)`,
                }}
              />
              <Card.Body className="text-center">
                <div
                  className="card-profile-img mx-auto d-flex align-center"
                  style={{ maxHeight: '8rem' }}
                >
                  <div className="position-relative overflow-hidden rounded-circle">
                    <Image
                      src={photoUrl || '/img/user-profile-circle.png'}
                      layout="fixed"
                      width={122}
                      height={122}
                      priority
                    />
                  </div>
                </div>

                <h3 className="mb-3">{`${name} ${lastname}`}</h3>
                <p className="mb-4">{email}</p>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={8}>
            <Card className="mb-5">
              <Card.Header>
                <h4 className="card-heading">Alterar foto</h4>
              </Card.Header>
              <Card.Body className="text-center">
                <ErrorProcessor error={apiErrors} />
                <Form onSubmit={handleSubmitPhoto}>
                  <Dropzone onChange={handleFileChange} />
                </Form>
              </Card.Body>
              <Card.Footer>
                <Button
                  variant="primary"
                  type="button"
                  onClick={handleSubmitPhoto}
                  className="mt-2"
                >
                  Atualizar
                </Button>
              </Card.Footer>
            </Card>
            <Card className="mb-5">
              <Card.Header>
                <h4 className="card-heading">Atualizar meus dados</h4>
              </Card.Header>
              <Card.Body>
                <ErrorProcessor error={apiErrors} />
                {dataLoaded && (
                  <Forms
                    initialValues={{ name, lastname, ramal }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmitProfile}
                    fields={fields}
                    formRef={formRefProfile}
                  />
                )}
              </Card.Body>
              <Card.Footer>
                <Button
                  variant="primary"
                  type="button"
                  onClick={formSubmitProfile}
                  className="mt-2"
                >
                  Atualizar
                </Button>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </section>
    </Container>
  );
}
