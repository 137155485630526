import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';
import { Container, Card, Button, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import moment from 'moment';

import axios from '../../services/axios';
import Loading from '../../components/Loading';
import Breadcrumbs from '../../components/Breadcrumbs';
import DataTable from '../../components/DataTable';
import ButtonModal from '../../components/ModalConfirmation/ButtonModal';
import { useNotification } from '../../components/Notification/NotificationContext';
import ErrorProcessor from '../../components/ErrorProcessor';
import DatepickerComponent from '../../components/Datepicker';
import CardHeaderMore from '../../components/Cards/CardHeaderMore';

export default function Channels() {
  const routeName = '/channels';
  const dataName = 'Fonte de lead';
  const titleName = 'Fontes de leads';
  const navigate = useNavigate();
  const [apiErrors, setApiErrors] = useState({});
  const notification = useNotification();
  const [data, setData] = useState([]);
  const startDate = useRef(moment().format('YYYY-MM-DD'));
  const endDate = useRef(moment().format('YYYY-MM-DD'));
  const [isLoading, setIsLoading] = useState(false);
  const userData = useSelector((state) => state.auth.user);
  const tableColumns = useMemo(
    () => [
      {
        Header: 'Nome',
        accessor: 'name',
      },
      {
        Header: 'Empresa',
        accessor: 'company_name',
      },
      {
        Header: 'Tipo',
        accessor: 'channel_type_name',
      },
    ],
    []
  );

  useEffect(() => {
    async function getData() {
      try {
        setIsLoading(true);
        const response = await axios.get(routeName);
        setData(response.data);
      } catch (err) {
        setApiErrors(err);
        setData([]);
      } finally {
        setIsLoading(false);
      }
    }

    getData();
  }, []);

  const confirmDelete = useCallback(async (id) => {
    try {
      setIsLoading(true);
      await axios.delete(`${routeName}/${id}`);
      notification(`${dataName} removida com sucesso`, 'success');
      const updated = data.filter((channel) => channel.id !== id);
      setData(updated);
    } catch (err) {
      setApiErrors(err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const confirmSync = useCallback(async (id) => {
    if (!moment(startDate.current, 'YYYY-MM-DD', true).isValid()) {
      notification('Data inicial infomada inválida');
      return;
    }

    if (!moment(endDate.current, 'YYYY-MM-DD', true).isValid()) {
      notification('Data final infomada inválida');
      return;
    }

    if (moment(startDate.current).isAfter(endDate.current)) {
      notification('Data inicial não pode ser maior que a data final');
      return;
    }

    try {
      setIsLoading(true);

      await axios.post(`${routeName}/sync/${id}`, {
        start_date: startDate.current,
        end_date: endDate.current,
      });
      notification(`${dataName} sincronizada com sucesso`, 'success');
    } catch (err) {
      setApiErrors(err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleDateChange = useCallback((date) => {
    const dateChange = date.start || date.end;

    if (!dateChange) {
      return;
    }

    const srcDate = new Date(dateChange);
    const formatedDate = moment(srcDate).format('YYYY-MM-DD');

    if (date.start) {
      startDate.current = formatedDate;
    }

    if (date.end) {
      endDate.current = formatedDate;
    }
  }, []);

  const tableData = useMemo(() => {
    return data.map((channel) => {
      const linksAction = [
        {
          onclick: () => {
            navigate(`${routeName}/${channel.id}/edit`);
          },
          faicon: 'faEdit',
          text: 'Atualizar',
        },
        {
          custom: (
            <ButtonModal
              onConfirm={() => confirmSync(channel.id)}
              message={
                <div className="mb-3">
                  <Form.Label>Informe as datas:</Form.Label>
                  <DatepickerComponent
                    range
                    maxNumberOfDates={31}
                    defaultValue={{
                      start: moment(startDate).format('dd/MM/YYYY'),
                      end: moment(endDate).format('dd/MM/YYYY'),
                    }}
                    addProps={{
                      onChange: handleDateChange,
                    }}
                  />
                </div>
              }
              buttonProps={{ text: 'Sincronizar', faicon: 'faSync' }}
              textModal={{
                title: 'Sincronizar leads',
                textConfirm: 'Sincronizar',
              }}
              dropdown
            />
          ),
        },
      ];

      if (userData.isadmin === 1) {
        linksAction.push({
          custom: (
            <ButtonModal
              onConfirm={() => confirmDelete(channel.id)}
              message={`Confirma remoção da ${dataName} ${channel.name}?`}
              buttonProps={{ text: 'Remover', faicon: 'faTrash' }}
              textModal={{
                title: `Remover ${dataName}`,
                textConfirm: 'Remover',
              }}
              dropdown
            />
          ),
        });
      }

      return {
        company_name: channel.Company.name,
        channel_type_name: (
          <div className="d-flex justify-content-between">
            <span>{channel.ChannelType.name}</span>
            <CardHeaderMore links={linksAction} className="" />
          </div>
        ),
        ...channel,
      };
    });
  }, [data, userData]);

  return (
    <Container fluid className="px-lg-4 px-xl-5">
      <Loading isLoading={isLoading} />
      <Breadcrumbs title="Fontes de leads" />
      <div className="page-header d-flex justify-content-between align-items-center">
        <h1 className="page-heading">{titleName}</h1>
        <div>
          <Link to={`${routeName}/new`}>
            <Button variant="primary" className="text-uppercase">
              <FaPlus /> Cadastrar
            </Button>
          </Link>
        </div>
      </div>
      <section>
        <Card className="card-table mb-5">
          <ErrorProcessor error={apiErrors} />
          <DataTable
            items={tableData}
            columns={tableColumns}
            defaultPageSize={5}
          />
        </Card>
      </section>
    </Container>
  );
}
