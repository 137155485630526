import React, { useState, useEffect, useCallback } from 'react';
import { Container, Card, Row, Col } from 'react-bootstrap';
import { get } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import Loading from '../../components/Loading';
import axios from '../../services/axios';
import Breadcrumbs from '../../components/Breadcrumbs';
import { useNotification } from '../../components/Notification/NotificationContext';
import Forms from '../../components/Forms';
import ErrorProcessor from '../../components/ErrorProcessor';
import PageHeader from '../../components/PageHeader';

export default function EmailProvider() {
  const routeName = '/emailsproviders';
  const dataName = 'Provedor de e-mail';
  const titleName = 'Provedores de e-mail';
  const [dataLoaded, setDataLoaded] = useState(false);
  const [apiErrors, setApiErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const notification = useNotification();
  const navigate = useNavigate();
  const params = useParams();
  const id = get(params, 'id', '');
  const [companies, setCompanies] = useState([]);
  const [formValues, setFormValues] = useState({
    provider_name: '',
    api_key: '',
    smtp_host: '',
    smtp_port: '587',
  });

  const validationSchema = Yup.object().shape({
    provider_name: Yup.string()
      .min(3, 'Nome deve conter no mínimo 3 caracteres.')
      .required('Nome obrigatório.'),
    company_id: Yup.number().required('Empresa obrigatória.'),
    smtp_host: Yup.string().required('Servidor SMTP obrigatório.'),
    smtp_port: Yup.number()
      .max(65535, 'A porta SMTP não pode ser maior que 65535.')
      .required('Porta SMTP obrigatória.'),
    default_sender_email: Yup.string()
      .email('Por favor, insira um endereço de e-mail válido')
      .required('E-mail de envio obrigatório.'),
  });

  const handleFieldChange = useCallback((fieldName, value) => {
    setFormValues((prevValues) => ({ ...prevValues, [fieldName]: value }));
  }, []);

  const fields = [
    {
      name: 'provider_name',
      id: 'provider_name',
      label: 'Nome do provedor',
      type: 'text',
      onChange: (e) => handleFieldChange('provider_name', e.target.value),
      colSize: 4,
      additionalProps: {},
    },
    {
      name: 'company_id',
      id: 'company_id',
      label: 'Empresa',
      type: 'select',
      options: companies,
      onChange: (e) => handleFieldChange('company_id', e.target.value),
      colSize: 4,
      additionalProps: {},
    },
    {
      name: 'smtp_host',
      id: 'smtp_host',
      label: 'Servidor SMTP',
      type: 'text',
      onChange: (e) => handleFieldChange('smtp_host', e.target.value),
      colSize: 4,
      additionalProps: {},
    },
    {
      name: 'smtp_port',
      id: 'smtp_port',
      label: 'Porta SMTP',
      type: 'number',
      onChange: (e) => handleFieldChange('smtp_port', e.target.value),
      colSize: 4,
      additionalProps: {},
    },
    {
      name: 'smtp_secure',
      id: 'smtp_secure',
      label: 'SMTP seguro',
      type: 'select',
      options: [
        { label: 'Desabilitado', value: false },
        { label: 'Habilitado', value: true },
      ],
      onChange: (e) => handleFieldChange('smtp_secure', e.target.value),
      colSize: 4,
      additionalProps: {},
    },
    {
      name: 'api_key',
      id: 'api_key',
      label: 'Chave de API',
      type: 'text',
      onChange: (e) => handleFieldChange('api_key', e.target.value),
      colSize: 4,
      additionalProps: {},
    },
    {
      name: 'username',
      id: 'username',
      label: 'Usuário',
      type: 'text',
      onChange: (e) => handleFieldChange('username', e.target.value),
      colSize: 4,
      additionalProps: {},
    },
    {
      name: 'password',
      id: 'password',
      label: 'Senha',
      type: 'password',
      onChange: (e) => handleFieldChange('password', e.target.value),
      colSize: 4,
      additionalProps: {},
    },
    {
      name: 'default_sender_email',
      id: 'default_sender_email',
      label: 'E-mail de envio',
      type: 'email',
      onChange: (e) =>
        handleFieldChange('default_sender_email', e.target.value),
      colSize: 4,
      additionalProps: {},
    },
  ];

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      let action = 'cadastrado';

      if (id) {
        await axios.put(`${routeName}/${id}`, formValues);
        action = 'atualizado';
      } else {
        await axios.post(routeName, formValues);
      }
      navigate(routeName);
      notification(`${dataName} ${action} com sucesso`, 'success');
    } catch (err) {
      setApiErrors(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    async function getData() {
      try {
        setIsLoading(true);

        const optionsCompanies = [{ value: '', label: '-- Selecione --' }];
        const getCompanies = await axios.get(`/companies`);

        if (getCompanies) {
          getCompanies.data.forEach((data) => {
            optionsCompanies.push({
              value: data.id,
              label: data.name,
            });
          });
        }

        setCompanies(optionsCompanies);
        if (id) {
          const getChannel = await axios.get(`${routeName}/${id}`);
          setFormValues({
            ...getChannel.data,
          });
        }
      } catch (err) {
        setApiErrors(err);
      } finally {
        setIsLoading(false);
        setDataLoaded(true);
      }
    }

    getData();
  }, [id, notification, navigate]);

  return (
    <Container fluid className="px-lg-4 px-xl-5">
      <Loading isLoading={isLoading} />
      <Breadcrumbs
        title={id ? 'Atualizar' : 'Cadastrar'}
        pages={[{ link: routeName, name: titleName }]}
      />
      <PageHeader title={titleName} />
      <section>
        <Row>
          <Col lg={12}>
            <Card className="mb-5">
              <Card.Header>
                <h4 className="card-heading">
                  {id ? `Atualizar ${dataName}` : `Cadastrar ${dataName}`}
                </h4>
              </Card.Header>
              <Card.Body>
                <ErrorProcessor error={apiErrors} />
                {!id || dataLoaded ? (
                  <Forms
                    initialValues={formValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    fields={fields}
                    btnSubmit={{
                      variant: 'primary',
                      type: 'submit',
                      text: id ? 'Atualizar' : 'Cadastrar',
                    }}
                  />
                ) : null}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </section>
    </Container>
  );
}
