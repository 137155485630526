// HeaderWithLocation.js
import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import * as pagesConfig from '../../config/pages';

export default function ContentPages({ children }) {
  const location = useLocation();
  let classPage = 'bg-gray-100';
  pagesConfig.classNameContent.forEach((value) => {
    if (value.page === location.pathname) {
      classPage = value.class;
    }
  });

  return <div className={`page-holder ${classPage}`}>{children}</div>;
}

ContentPages.propTypes = {
  children: PropTypes.node.isRequired,
};
