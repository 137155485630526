import React from 'react';
import { Card, Button, ListGroup, Nav } from 'react-bootstrap';
import { v4 } from 'uuid';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

function TitleBlock({ title, titleTag, subtitle }) {
  return (
    <>
      {title && <Card.Title as={titleTag}>{title}</Card.Title>}
      {subtitle && (
        <Card.Subtitle className="text-muted">{subtitle}</Card.Subtitle>
      )}
    </>
  );
}

export default function CardMain({
  className,
  img,
  imgBottom,
  imgStyle,
  title,
  titleTag,
  subtitle,
  text,
  textMuted,
  button,
  content,
  list,
  links,
  titleTop,
  header,
  headerClass,
  footer,
  nav,
}) {
  const goBackPage = (pages = 1) => {
    for (let i = 0; i < pages; i += 1) {
      window.history.back();
    }
  };

  return (
    <Card className={className || ''}>
      {header && (
        <Card.Header className={headerClass || ''}>
          <div className="card-heading">{header}</div>
        </Card.Header>
      )}
      {nav && (
        <Card.Header className={headerClass || ''}>
          <Nav variant={nav.variant}>
            {nav.items.map((item) => (
              <Nav.Item key={item.name}>
                <Nav.Link
                  href={item.link}
                  active={item.active}
                  disabled={item.disabled}
                >
                  {item.name}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Card.Header>
      )}
      {titleTop && (title || subtitle) && (
        <Card.Body>
          <TitleBlock title={title} titleTag={titleTag} subtitle={subtitle} />
        </Card.Body>
      )}
      {!imgBottom && img && (
        <Card.Img
          src={img}
          alt=""
          variant="top"
          className={titleTop ? 'rounded-0' : ''}
          style={imgStyle}
        />
      )}

      <Card.Body>
        {!titleTop && (title || subtitle) && <TitleBlock />}
        {content}
        <Card.Text>{text}</Card.Text>
        {textMuted && (
          <Card.Text>
            <small className="text-muted">{textMuted}</small>
          </Card.Text>
        )}
        {button && button.link && (
          /* eslint-disable jsx-a11y/anchor-is-valid */
          <Link to={button.goBack ? undefined : button.link}>
            <Button
              variant={button.variant ? button.variant : 'primary'}
              onClick={
                button.goBack ? () => goBackPage(button.goBack) : () => {}
              }
            >
              {button.label}
            </Button>
          </Link>
        )}
      </Card.Body>
      {imgBottom && img && (
        <Card.Img src={img} alt="Card image cap" variant="bottom" />
      )}
      {list && (
        <ListGroup variant="flush">
          {list.map((item) => (
            <ListGroup.Item key={v4()} className="text-muted">
              {item}
            </ListGroup.Item>
          ))}
        </ListGroup>
      )}
      {links && (
        <Card.Body>
          {links.map((link) => (
            <Card.Link href={link.link} key={v4()}>
              {link.name}
            </Card.Link>
          ))}
        </Card.Body>
      )}
      {footer && <Card.Footer className="text-muted">{footer}</Card.Footer>}
    </Card>
  );
}

CardMain.defaultProps = {
  className: '',
  text: '',
  img: '',
  imgBottom: false,
  title: '',
  titleTag: '',
  subtitle: '',
  textMuted: '',
  button: null,
  content: '',
  list: [],
  links: '',
  titleTop: false,
  header: null,
  headerClass: '',
  footer: '',
  nav: null,
  imgStyle: {},
};

CardMain.propTypes = {
  className: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  img: PropTypes.string,
  imgBottom: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  titleTag: PropTypes.string,
  subtitle: PropTypes.string,
  textMuted: PropTypes.string,
  button: PropTypes.shape({
    variant: PropTypes.string,
    link: PropTypes.string,
    label: PropTypes.string,
    goBack: PropTypes.number,
  }),
  content: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.string),
  links: PropTypes.string,
  titleTop: PropTypes.bool,
  header: PropTypes.string,
  headerClass: PropTypes.string,
  footer: PropTypes.string,
  nav: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})),
    variant: PropTypes.string,
  }),
  imgStyle: PropTypes.shape({}),
};

TitleBlock.defaultProps = {
  title: '',
  titleTag: '',
  subtitle: '',
};

TitleBlock.propTypes = {
  title: PropTypes.string,
  titleTag: PropTypes.string,
  subtitle: PropTypes.string,
};
