// import '@fortawesome/fontawesome-svg-core/styles.css';
// import 'bootstrap/dist/css/bootstrap.min.css';

import { createGlobalStyle } from 'styled-components';
import '../scss/style.default.scss';

const GlobalStyles = createGlobalStyle`
  .table-responsive {
    overflow: visible !important;
  }
`;

export default GlobalStyles;
