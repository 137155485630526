import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useNavigate } from 'react-router-dom';

import { useNotification } from '../Notification/NotificationContext';
import * as actions from '../../store/modules/auth/actions';

export default function ErrorProcessor({ error }) {
  const navigate = useNavigate();
  const notification = useNotification();
  const dispatch = useDispatch();
  const rememberUser = useSelector((state) => state.auth.remember);
  const loginError = useSelector((state) => state.auth.loginError);

  useEffect(() => {
    if (error) {
      const status = get(error, 'response.status', 0);
      let erros = get(error, 'response.data.errors', []);

      if (typeof erros !== 'object') {
        erros = [erros];
      }

      if (status === 401) {
        if (rememberUser && loginError === 0) {
          dispatch(
            actions.loginRequest({
              email: rememberUser.email,
              password: rememberUser.password,
              remember: true,
            })
          );

          navigate(window.location.pathname, { replace: true });
        } else {
          dispatch(actions.loginFailure());
          navigate('/login');
        }
        return;
      }

      if (status === 403) {
        navigate('/403');
        notification(erros.join('. '), 'danger');
        return;
      }

      if (status > 299) {
        notification(
          erros.lenght > 0 ? erros.join('. ') : 'Erro ao processar página',
          'danger',
          5000
        );
      }
    }
  }, [error, loginError]);

  return <span />;
}

ErrorProcessor.defaultProps = {
  error: {},
};

ErrorProcessor.propTypes = {
  error: PropTypes.shape({}),
};
