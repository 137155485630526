import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

import Icon from '../Icon';

export default function Stats1({ color, number, name, footer, icon }) {
  return (
    <Card className="h-100">
      <Card.Body className="d-flex align-items-center justify-content-between">
        <div>
          <h4 className={`fw-normal text-${color}`}>{number}</h4>
          <p className="subtitle text-sm text-muted mb-0">{name}</p>
        </div>
        <div className="flex-shrink-0 ms-3">
          <Icon icon={icon} className={`text-${color}`} />
        </div>
      </Card.Body>
      <Card.Footer className={`py-3 bg-${color}-light`}>
        <Row className={`align-items-center text-${color}`}>
          <Col xs={10}>
            <p className="mb-0">{footer}</p>
          </Col>
          <Col xs={2} className="text-end">
            <FontAwesomeIcon icon={faCaretUp} />
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
}

Stats1.defaultProps = {
  icon: 'speed-1',
  color: 'primary',
  number: '',
  name: '',
  footer: '',
};

Stats1.propTypes = {
  icon: PropTypes.string,
  color: PropTypes.string,
  number: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
  footer: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
};
