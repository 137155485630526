import React from 'react';
import PropTypes from 'prop-types';

import { LoadingOverlay } from './styled';
import Preloader from '../Preloader';

export default function Loading({ isLoading, type }) {
  if (!isLoading) return null;
  return (
    <LoadingOverlay>
      <Preloader type={type} variant="white" />
    </LoadingOverlay>
  );
}

Loading.defaultProps = {
  isLoading: false,
  type: 'three-bounce',
};

Loading.propTypes = {
  isLoading: PropTypes.bool,
  type: PropTypes.string,
};
