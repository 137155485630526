import React, { useState, useEffect } from 'react';
import { Container, Card, Row, Col, Form, Button } from 'react-bootstrap';
import Select from 'react-select';
import { useParams, useNavigate } from 'react-router-dom';
import axios from '../../services/axios';
import Loading from '../../components/Loading';
import Breadcrumbs from '../../components/Breadcrumbs';
import { useNotification } from '../../components/Notification/NotificationContext';

export default function ServiceDeskEdit() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [description, setDescription] = useState('');
  const [customers, setCustomers] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [priorities, setPriorities] = useState([]);
  const [stages, setStages] = useState([]);
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [selectedDepartmentIds, setSelectedDepartmentIds] = useState([]);
  const [selectedPriorityIds, setSelectedPriorityIds] = useState([]);
  const [selectedStageIds, setSelectedStageIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const notification = useNotification();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [
          customersResponse,
          departmentsResponse,
          prioritiesResponse,
          stagesResponse,
          serviceDeskResponse,
        ] = await Promise.all([
          axios.get('/customers'),
          axios.get('/departaments'),
          axios.get('/priorities'),
          axios.get('/stages'),
          axios.get(`/servicedesks/${id}`),
        ]);
        setCustomers(customersResponse.data);
        setDepartments(departmentsResponse.data);
        setPriorities(prioritiesResponse.data);
        setStages(stagesResponse.data);
        const serviceDesk = serviceDeskResponse.data;
        setName(serviceDesk.name);
        setDisplayName(serviceDesk.displayName);
        setDescription(serviceDesk.description);

        setSelectedCustomerIds(
          serviceDesk.customers.map((customer) => ({
            value: customer.id,
            label: customer.razaoSocial,
          }))
        );
        setSelectedDepartmentIds(
          serviceDesk.departments.map((department) => ({
            value: department.id,
            label: department.name,
          }))
        );
        setSelectedPriorityIds(
          serviceDesk.priorities.map((priority) => ({
            value: priority.id,
            label: priority.name,
          }))
        );
        setSelectedStageIds(
          serviceDesk.stages.map((stage) => ({
            value: stage.id,
            label: stage.name,
          }))
        );
      } catch (error) {
        console.error('Failed to fetch data:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.put(`/servicedesks/${id}`, {
        name,
        displayName,
        description,
        customerIds: selectedCustomerIds.map((option) => option.value),
        departmentIds: selectedDepartmentIds.map((option) => option.value),
        priorityIds: selectedPriorityIds.map((option) => option.value),
        stageIds: selectedStageIds.map((option) => option.value),
      });
      notification('Mesa de serviço atualizada com sucesso:', response.data);
      navigate('/servicedesk');
    } catch (error) {
      notification('Falha ao atualizar a Mesa de serviço:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container fluid className="px-lg-4 px-xl-15">
      <Breadcrumbs
        title="Editar Mesa de Serviço"
        pages={[{ name: 'Mesa de Serviço', link: '/servicedesk' }]}
      />
      <Card>
        <Card.Body>
          {loading && <Loading />}
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>Nome</Form.Label>
                  <Form.Control
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Display Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={displayName}
                    onChange={(e) => setDisplayName(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>Descrição</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>Clientes</Form.Label>
                  <Select
                    isMulti
                    value={selectedCustomerIds}
                    onChange={setSelectedCustomerIds}
                    options={customers.map((customer) => ({
                      value: customer.id,
                      label: customer.razaoSocial,
                    }))}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Departamentos</Form.Label>
                  <Select
                    isMulti
                    value={selectedDepartmentIds}
                    onChange={setSelectedDepartmentIds}
                    options={departments.map((department) => ({
                      value: department.id,
                      label: department.name,
                    }))}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Prioridades</Form.Label>
                  <Select
                    isMulti
                    value={selectedPriorityIds}
                    onChange={setSelectedPriorityIds}
                    options={priorities.map((priority) => ({
                      value: priority.id,
                      label: priority.name,
                    }))}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Estágios</Form.Label>
                  <Select
                    isMulti
                    value={selectedStageIds}
                    onChange={setSelectedStageIds}
                    options={stages.map((stage) => ({
                      value: stage.id,
                      label: stage.name,
                    }))}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3 d-flex justify-content-end">
              <Col sm="auto">
                <Button type="submit" className="mt-2">
                  Atualizar Mesa De Serviço
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
}
