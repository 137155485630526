import React from 'react';
import PropTypes from 'prop-types';
import * as faSolidIcons from '@fortawesome/free-solid-svg-icons';
import { v4 } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown } from 'react-bootstrap';

export default function CardHeaderMore({ links, className }) {
  return (
    <div className={className}>
      <Dropdown align="end">
        <Dropdown.Toggle variant="header-more">
          <FontAwesomeIcon icon={faSolidIcons.faEllipsisV} />
        </Dropdown.Toggle>

        <Dropdown.Menu className="text-sm">
          {links.map((link) =>
            link.custom ? (
              <React.Fragment key={v4()}>{link.custom}</React.Fragment>
            ) : (
              <Dropdown.Item key={v4()} onClick={link.onclick}>
                <FontAwesomeIcon
                  icon={faSolidIcons[link.faicon] || faSolidIcons.faCircle}
                  className="me-2 opacity-5"
                />
                {link.text}
              </Dropdown.Item>
            )
          )}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

CardHeaderMore.defaultProps = {
  links: [],
  className: 'card-header-more',
};

CardHeaderMore.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      onclick: PropTypes.func,
      faicon: PropTypes.string,
      text: PropTypes.string,
      custom: PropTypes.node,
    })
  ),
  className: PropTypes.string,
};
