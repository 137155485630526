/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone'; // Importa o moment-timezone
import 'moment/locale/pt-br';
import axios from '../../services/axios';
import Loading from '../../components/Loading';
import Breadcrumbs from '../../components/Breadcrumbs';
import DataTable from '../../components/DataTable';
import ButtonModal from '../../components/ModalConfirmation/ButtonModal';
import { useNotification } from '../../components/Notification/NotificationContext';
import ErrorProcessor from '../../components/ErrorProcessor';
import CardHeaderMore from '../../components/Cards/CardHeaderMore';

// Define o locale como pt-br
moment.locale('pt-br');

// Função para extrair nome e e-mail do campo senderEmail
const extractNameAndEmail = (senderEmail) => {
  const emailRegex = /"(.*?)" <(.*?)>/;
  const match = senderEmail.match(emailRegex);
  if (match) {
    return {
      name: match[1], // Nome
      email: match[2], // E-mail
    };
  }
  // Retorna o email completo se o formato esperado não for encontrado
  return {
    name: senderEmail,
    email: senderEmail,
  };
};

export default function PreTickets() {
  const routeName = '/pretickets';
  const titleName = 'Pré-tickets';
  const navigate = useNavigate();
  const [apiErrors, setApiErrors] = useState({});
  const notification = useNotification();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const userData = useSelector((state) => state.auth.user);

  const tableColumns = useMemo(
    () => [
      {
        Header: 'Nome',
        accessor: 'name', // Acessa o campo "name"
      },
      {
        Header: 'E-mail do Solicitante',
        accessor: 'email', // Acessa o campo "email"
      },
      {
        Header: 'Título',
        accessor: 'subject',
      },
      {
        Header: 'Criado em',
        accessor: 'createdAt',
        Cell: ({ value }) => moment(value).format('DD/MM/YYYY HH:mm'), // Formata a data de criação
      },
      {
        Header: 'Ações',
        accessor: 'status',
        disableSortBy: true,
      },
    ],
    []
  );

  const confirmDelete = useCallback(
    async (id) => {
      try {
        setIsLoading(true);
        await axios.delete(`${routeName}/${id}`);
        notification('Pré-ticket removido com sucesso', 'success');
        const updated = data.filter((ticket) => ticket.id !== id);
        setData(updated);
      } catch (err) {
        setApiErrors(err);
      } finally {
        setIsLoading(false);
      }
    },
    [data, notification]
  );

  const tableData = useMemo(() => {
    return data.map((ticketObj) => {
      // Extrai nome e e-mail
      const { name, email } = extractNameAndEmail(ticketObj.senderEmail);

      const linksAction = [
        {
          onclick: () => {
            // Navegando para a página de criação de ticket com os dados do pré-ticket
            navigate('/pretickets/new', {
              state: {
                id: ticketObj.id, // Inclua o ID do pré-ticket aqui
                email,
                title: ticketObj.subject,
                message: ticketObj.message,
                files: ticketObj.files, // Passando os arquivos também
              },
            });
          },
          faicon: 'faEdit',
          text: 'Abrir Ticket',
        },
      ];

      linksAction.push({
        custom: (
          <ButtonModal
            onConfirm={() => confirmDelete(ticketObj.id)}
            message={`Confirma remoção do Pré-ticket ${ticketObj.id}?`}
            buttonProps={{ text: 'Remover', faicon: 'faTrash' }}
            textModal={{
              title: 'Remover Pré-ticket',
              textConfirm: 'Remover',
            }}
            dropdown
          />
        ),
      });

      return {
        ...ticketObj,
        name,
        email,
        status: (
          <div className="d-flex justify-content-between">
            <CardHeaderMore links={linksAction} className="" />
          </div>
        ),
      };
    });
  }, [data, confirmDelete, userData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(routeName);
        setData(response.data);
        setDataLoaded(true);
      } catch (err) {
        setApiErrors(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [routeName]);

  return (
    <Container fluid className="px-lg-4 px-xl-5">
      <Loading isLoading={isLoading} />
      <Breadcrumbs title={titleName} />
      <section>
        {dataLoaded && (
          <Card className="card-table mb-5">
            <ErrorProcessor error={apiErrors} />
            <DataTable
              items={tableData}
              columns={tableColumns}
              defaultPageSize={5}
              defaultSort="createdAt"
            />
          </Card>
        )}
      </section>
    </Container>
  );
}
