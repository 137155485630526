import React from 'react';
import { Form, Row, Col, InputGroup, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import PropTypes from 'prop-types';

export default function Forms({
  initialValues,
  validationSchema,
  onSubmit,
  fields,
  msgIsValid,
  btnSubmit,
  dataPrependForm,
  formRef,
}) {
  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values);
        setSubmitting(false);
      }}
      innerRef={formRef}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Row className="g-3 mb-3">
            {dataPrependForm && <Col md={12}>{dataPrependForm}</Col>}
            {fields.map((field) => {
              const formControlProps = {
                name: field.name,
                value: values[field.name],
                onChange: (e) => {
                  handleChange(e);
                  field.onChange && field.onChange(e);
                },
                onBlur: handleBlur,
                isValid: touched[field.name] && !errors[field.name],
                isInvalid: !!errors[field.name],
                ...field.additionalProps,
              };

              const formControl = (
                <>
                  {field.type === 'select' || field.type === 'textarea' ? (
                    <Form.Control
                      as={field.type === 'select' ? 'select' : 'textarea'}
                      className={
                        field.type === 'select'
                          ? 'form-select'
                          : 'form-textarea'
                      }
                      {...formControlProps}
                    >
                      {field.type === 'select' &&
                        field.options.map((data) => {
                          return (
                            <option
                              key={`${field.name}_${data.value}`}
                              value={data.value}
                            >
                              {data.label}
                            </option>
                          );
                        })}
                    </Form.Control>
                  ) : (
                    <Form.Control type={field.type} {...formControlProps} />
                  )}
                  <Form.Control.Feedback>{msgIsValid}</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors[field.name]}
                  </Form.Control.Feedback>
                </>
              );

              return (
                <Form.Group
                  as={Col}
                  md={field.colSize}
                  controlId={field.id || field.name}
                  key={field.id}
                >
                  <Form.Label>{field.label}</Form.Label>

                  {field.groupText ? (
                    <InputGroup hasValidation>
                      <InputGroup.Text>{field.groupText}</InputGroup.Text>
                      {formControl}
                    </InputGroup>
                  ) : (
                    formControl
                  )}
                </Form.Group>
              );
            })}
          </Row>
          {btnSubmit && <Button {...btnSubmit}>{btnSubmit.text}</Button>}
        </Form>
      )}
    </Formik>
  );
}

Forms.defaultProps = {
  initialValues: {},
  validationSchema: {},
  onSubmit: () => {},
  fields: [],
  msgIsValid: 'Tudo certo aqui!',
  btnSubmit: null,
  dataPrependForm: null,
  formRef: undefined,
};

Forms.propTypes = {
  initialValues: PropTypes.shape({}),
  validationSchema: PropTypes.shape({}),
  onSubmit: PropTypes.func,
  fields: PropTypes.arrayOf(PropTypes.shape({})),
  msgIsValid: PropTypes.string,
  btnSubmit: PropTypes.shape({ text: PropTypes.string }),
  dataPrependForm: PropTypes.node,
  formRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
};
