import styled, { keyframes } from 'styled-components';

const overlayAnimation = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const spinAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  animation: ${overlayAnimation} 0.3s ease; /* Adiciona uma animação de fade-in */
`;

export const LoadingSpinner = styled.div`
  border: 8px solid rgba(255, 255, 255, 0.3);
  border-top: 8px solid #ffffff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spinAnimation} 1s linear infinite;
`;

export const LoadingText = styled.p`
  color: #ffffff;
  margin-top: 10px;
`;
