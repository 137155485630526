import { call, put, all, takeLatest } from 'redux-saga/effects';
import { get } from 'lodash';
import * as actions from './actions';
import * as types from '../types';
import axios from '../../../services/axios';

function* loginRequest({ payload }) {
  try {
    const { email, password, remember } = payload;
    const response = yield call(axios.post, '/tokens', { email, password });
    yield put(
      actions.loginSuccess({
        ...response.data,
        ...{ email, password, remember },
      })
    );
    axios.defaults.headers.Authorization = `Bearer ${response.data.token}`;
  } catch (e) {
    yield put(actions.loginFailure(payload));
  }
}

function persistRehydrate({ payload }) {
  const token = get(payload, 'auth.token', '');
  if (!token) return;
  axios.defaults.headers.Authorization = `Bearer ${token}`;
}

export default all([
  takeLatest(types.LOGIN_REQUEST, loginRequest),
  takeLatest(types.PERSIST_REHYDRATE, persistRehydrate),
]);
