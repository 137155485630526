// HeaderWithLocation.js
import React from 'react';
import { useLocation } from 'react-router-dom';
import Footer from './index';
import * as pagesConfig from '../../config/pages';

export default function FooterLocation() {
  const location = useLocation();
  if (pagesConfig.noFooterPages.includes(location.pathname)) {
    return null;
  }

  return <Footer />;
}
