/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Container, Card, Row, Col, Form, Button } from 'react-bootstrap';
import { get } from 'lodash';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { FaImage, FaPhotoVideo, FaFileAlt } from 'react-icons/fa';
import * as Yup from 'yup';
import moment from 'moment';
import ReactPlayer from 'react-player';

import Loading from '../../components/Loading';
import axios from '../../services/axios';
import Breadcrumbs from '../../components/Breadcrumbs';
import { useNotification } from '../../components/Notification/NotificationContext';
import Forms from '../../components/Forms';
import ErrorProcessor from '../../components/ErrorProcessor';
import CustomImage from '../../components/CustomImage';
import ButtonModal from '../../components/ModalConfirmation/ButtonModal';
import { formatPhoneBr } from '../../hooks/formatPhoneBr';
import DataTable from '../../components/DataTable';
import Dropzone from '../../components/Dropzone';
import CardHeaderMore from '../../components/Cards/CardHeaderMore';

export default function Lead() {
  const navigate = useNavigate();
  const routeName = '/leads';
  const dataName = 'Lead';
  const titleName = 'Lead';
  const [dataLoaded, setDataLoaded] = useState(false);
  const [apiErrors, setApiErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const notification = useNotification();
  const [clearDropzone, setClearDropzone] = useState(false);
  const params = useParams();
  const id = get(params, 'id', '');
  const [companies, setCompanies] = useState([]);
  const [channels, setChannels] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [classifications, setClassifications] = useState([]);
  const [users, setUsers] = useState([]);
  const [file, setFile] = useState([]);
  const [fileName, setFileName] = useState('');
  const [formValues, setFormValues] = useState({
    name: '',
    notes: '',
    doc_id: '',
    user_id: null,
  });

  const validationSchema = {
    default: Yup.object().shape({
      name: Yup.string()
        .min(3, 'Nome deve conter no mínimo 3 caracteres.')
        .required('Nome obrigatório.'),
      company_id: Yup.number().required('Empresa obrigatória.'),
      doc_id: Yup.number().min(
        6,
        'Documento deve conter no mínimo 6 caracteres.'
      ),
    }),
    LeadIvrOpenNewCanceled: Yup.object().shape({}),
  };

  const handleFileChange = (e) => {
    const newFile = e[0];
    setFile(newFile);
  };

  const handleFieldChange = useCallback((fieldName, value, type) => {
    switch (type) {
      case 'LeadIvrOpenNewCanceled':
        setFormValues((prevValues) => ({
          ...prevValues,
          LeadIvrOpenNewCanceled: {
            ...prevValues.LeadIvrOpenNewCanceled,
            [fieldName]: value,
          },
        }));
        break;
      default:
        setFormValues((prevValues) => ({ ...prevValues, [fieldName]: value }));
        break;
    }
  }, []);

  const fields = {
    default: [
      {
        name: 'name',
        id: 'name',
        label: 'Nome',
        type: 'text',
        onChange: (e) => handleFieldChange('name', e.target.value),
        colSize: 4,
        additionalProps: {},
      },
      {
        name: 'company_id',
        id: 'company_id',
        label: 'Empresa',
        type: 'select',
        options: companies,
        onChange: (e) => handleFieldChange('company_id', e.target.value),
        colSize: 4,
        additionalProps: {},
      },
      {
        name: 'doc_id',
        id: 'doc_id',
        label: 'Documento',
        type: 'text',
        onChange: (e) => handleFieldChange('doc_id', e.target.value),
        colSize: 4,
        additionalProps: {},
      },
      {
        name: 'channel_id',
        id: 'channel_id',
        label: 'Fonte do lead',
        type: 'select',
        options: channels,
        onChange: (e) => handleFieldChange('channel_id', e.target.value),
        colSize: 4,
        additionalProps: {},
      },
      {
        name: 'customer_id',
        id: 'customer_id',
        label: 'Cliente',
        type: 'select',
        options: customers,
        onChange: (e) => handleFieldChange('customer_id', e.target.value),
        colSize: 4,
        additionalProps: {},
      },
      {
        name: 'cassification_id',
        id: 'cassification_id',
        label: 'Classificação do lead',
        type: 'select',
        options: classifications,
        onChange: (e) => handleFieldChange('cassification_id', e.target.value),
        colSize: 4,
        additionalProps: {},
      },
      {
        name: 'user_id',
        id: 'user_id',
        label: 'Atribuir usuário',
        type: 'select',
        options: users,
        onChange: (e) => handleFieldChange('user_id', e.target.value),
        colSize: 12,
        additionalProps: {},
      },
      {
        name: 'notes',
        id: 'notes',
        label: 'Observações',
        type: 'textarea',
        onChange: (e) => handleFieldChange('notes', e.target.value),
        colSize: 12,
        additionalProps: {},
      },
    ],
    LeadIvrOpenNewCanceled: [
      {
        name: 'metodo_reembolso',
        id: 'metodo_reembolso',
        label: 'Método de reembolso',
        type: 'text',
        onChange: (e) =>
          handleFieldChange(
            'metodo_reembolso',
            e.target.value,
            'LeadIvrOpenNewCanceled'
          ),
        colSize: 4,
        additionalProps: {},
      },
      {
        name: 'banco',
        id: 'banco',
        label: 'Banco',
        type: 'text',
        onChange: (e) =>
          handleFieldChange('banco', e.target.value, 'LeadIvrOpenNewCanceled'),
        colSize: 4,
        additionalProps: {},
      },
      {
        name: 'conta',
        id: 'conta',
        label: 'Conta',
        type: 'text',
        onChange: (e) =>
          handleFieldChange('conta', e.target.value, 'LeadIvrOpenNewCanceled'),
        colSize: 4,
        additionalProps: {},
      },
      {
        name: 'titular_conta',
        id: 'titular_conta',
        label: 'Titular da conta',
        type: 'text',
        onChange: (e) =>
          handleFieldChange(
            'titular_conta',
            e.target.value,
            'LeadIvrOpenNewCanceled'
          ),
        colSize: 4,
        additionalProps: {},
      },
      {
        name: 'pix',
        id: 'pix',
        label: 'Pix',
        type: 'text',
        onChange: (e) =>
          handleFieldChange('pix', e.target.value, 'LeadIvrOpenNewCanceled'),
        colSize: 4,
        additionalProps: {},
      },
    ],
  };

  const tableColumnsFiles = useMemo(
    () => [
      {
        Header: 'Data',
        accessor: 'created_at',
      },
      {
        Header: 'Nome',
        accessor: 'name',
      },
      {
        Header: 'Arquivo',
        accessor: 'urlFile',
      },
      {
        Header: 'Tipo',
        accessor: 'type',
      },
    ],
    []
  );

  const handleSubmit = {
    default: async () => {
      try {
        setIsLoading(true);
        let action = 'cadastrado';

        if (id) {
          await axios.put(`${routeName}/${id}`, formValues);
          action = 'atualizado';
        } else {
          await axios.post(routeName, formValues);
        }

        if (!id) {
          navigate(routeName);
        }

        notification(`${dataName} ${action} com sucesso`, 'success');
      } catch (err) {
        setApiErrors(err);
      } finally {
        setIsLoading(false);
      }
    },
    LeadIvrOpenNewCanceled: async () => {
      try {
        const dataForm = {
          pix: formValues.LeadIvrOpenNewCanceled.pix,
          metodo_reembolso: formValues.LeadIvrOpenNewCanceled.metodo_reembolso,
          conta: formValues.LeadIvrOpenNewCanceled.conta,
          titular_conta: formValues.LeadIvrOpenNewCanceled.titular_conta,
          banco: formValues.LeadIvrOpenNewCanceled.banco,
        };
        setIsLoading(true);
        await axios.put(
          `leadsivropennewcancel/${formValues.LeadIvrOpenNewCanceled.id}`,
          dataForm
        );
        notification(`${dataName} atualizado com sucesso`, 'success');
      } catch (err) {
        setApiErrors(err);
      } finally {
        setIsLoading(false);
      }
    },
  };

  const alterStatusLead = useCallback(async () => {
    try {
      setIsLoading(true);
      handleFieldChange('closed', formValues.closed === 0 ? 1 : 0);
      const lead = await axios.put(
        `${routeName}/${id}/tooglestatus`,
        formValues
      );
      notification(
        `Lead ${lead.data.closed === 0 ? 'reaberto' : 'encerrado'} com sucesso`,
        'success'
      );
    } catch (err) {
      setApiErrors(err);
    } finally {
      setIsLoading(false);
    }
  }, [formValues]);

  const getLead = useCallback(async () => {
    try {
      const request = await axios.get(`${routeName}/${id}`);
      setFormValues({
        ...request.data,
      });
    } catch (err) {
      setApiErrors(err);
    }
  }, [id]);

  const handleSubmitFile = async (e) => {
    e.preventDefault();

    if (!file) {
      notification('Nenhum arquivo informado');
      return;
    }

    if (!fileName) {
      notification('Nome do arquivo não informado');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('lead_id', id);
    formData.append('name', fileName);

    try {
      setIsLoading(true);
      await axios.post(`/leadsfiles`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      await getLead();
      notification('Arquivo carregado com sucesso', 'success');
      setClearDropzone(true);
      setFileName('');
    } catch (err) {
      setApiErrors(err);
    } finally {
      setIsLoading(false);
    }
  };

  const confirmDeleteFile = useCallback(async (idFile) => {
    try {
      setIsLoading(true);
      await axios.delete(`leadsfiles/${idFile}`);
      await getLead();
      notification(`Arquivo removido com sucesso`, 'success');
    } catch (err) {
      setApiErrors(err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const tableDataFiles = useMemo(() => {
    return !formValues.LeadFiles
      ? []
      : formValues.LeadFiles.map((dataObject) => {
          let icon = null;
          let typeString = '';
          switch (dataObject.type) {
            case 'documents':
              icon = <FaFileAlt />;
              typeString = `Documento ${dataObject.file.split('.').pop()}`;
              break;
            case 'images':
              icon = <FaImage />;
              typeString = `Imagem ${dataObject.file.split('.').pop()}`;
              break;
            case 'medias':
              icon = <FaPhotoVideo />;
              typeString = `Áudio / vídeo ${dataObject.file.split('.').pop()}`;
              break;
            default:
              icon = null;
              break;
          }

          const linksAction = [
            {
              custom: (
                <ButtonModal
                  onConfirm={() => confirmDeleteFile(dataObject.id)}
                  message={`Confirma remoção do arquivo ${dataObject.name}?`}
                  buttonProps={{ text: 'Remover', faicon: 'faTrash' }}
                  textModal={{
                    title: `Remover arquivo`,
                    textConfirm: 'Remover',
                  }}
                  dropdown
                />
              ),
            },
          ];

          return {
            ...dataObject,
            created_at: moment.utc(dataObject.created_at).format('DD/MM/YYYY'),
            urlFile: (
              <Link
                to={dataObject.urlFile}
                title="Clique para abrir"
                target="_blank"
              >
                {icon}
              </Link>
            ),
            type: (
              <div className="d-flex justify-content-between">
                <span>{typeString}</span>{' '}
                <CardHeaderMore links={linksAction} className="" />
              </div>
            ),
          };
        });
  }, [formValues]);

  useEffect(() => {
    async function getData() {
      try {
        setIsLoading(true);

        const optionsCompanies = [{ value: '', label: '-- Selecione --' }];
        const optionsChannels = [{ value: '', label: '-- Selecione --' }];
        const optionsClassifiers = [{ value: '', label: 'Não classificado' }];
        const optionsCustomers = [{ value: '', label: '-- Selecione --' }];
        const optionsUsers = [{ value: '', label: '-- Selecione --' }];

        const getCompanies = await axios.get(`/companies`);
        if (getCompanies) {
          getCompanies.data.forEach((data) => {
            optionsCompanies.push({
              value: data.id,
              label: data.name,
            });
          });
        }
        setCompanies(optionsCompanies);

        const getChannels = await axios.get(`/channels`);
        if (getChannels) {
          getChannels.data.forEach((data) => {
            optionsChannels.push({
              value: data.id,
              label: data.name,
            });
          });
        }
        setChannels(optionsChannels);

        const getClassifiers = await axios.get(`/classifiers`);
        if (getClassifiers) {
          getClassifiers.data.forEach((data) => {
            optionsClassifiers.push({
              value: data.id,
              label: data.name,
            });
          });
        }
        setClassifications(optionsClassifiers);

        const getCustomers = await axios.get(`/customers`);
        if (getCustomers) {
          getCustomers.data.forEach((data) => {
            optionsCustomers.push({
              value: data.id,
              label: data.name,
            });
          });
        }
        setCustomers(optionsCustomers);

        const getUsers = await axios.get(`/users`);
        if (getUsers) {
          getUsers.data.forEach((data) => {
            optionsUsers.push({
              value: data.id,
              label: data.UserProfile.name,
            });
          });
        }
        setUsers(optionsUsers);

        if (id) {
          await getLead();
        }
      } catch (err) {
        setApiErrors(err);
      } finally {
        setIsLoading(false);
        setDataLoaded(true);
      }
    }

    getData();
  }, [id]);

  return (
    <Container fluid className="px-lg-4 px-xl-5">
      <Loading isLoading={isLoading} />
      <Breadcrumbs
        title={id ? 'Atualizar' : 'Cadastrar'}
        pages={[{ link: routeName, name: 'Leads' }]}
      />
      <div className="page-header d-flex justify-content-between align-items-center">
        <h1 className="page-heading">{titleName}</h1>
        {formValues.id && formValues.user_id && dataLoaded ? (
          <div>
            <ButtonModal
              onConfirm={() => alterStatusLead()}
              message={`Confirma ${formValues.closed === 0 ? 'encerramento' : 'reabertura'} do lead ${formValues.name} ?`}
              buttonProps={{
                variant: formValues.closed === 0 ? 'danger' : 'success',
                className: 'text-uppercase text-white',
                title: `Clique para ${formValues.closed === 0 ? 'fechar' : 'reabrir'} o lead`,
              }}
              textModal={{
                title: `${formValues.closed === 0 ? 'Fechar' : 'Reabrir'} lead`,
                textConfirm: `Confirmar`,
              }}
            >
              {formValues.closed === 0 ? 'Fechar' : 'Reabrir'}
            </ButtonModal>
          </div>
        ) : null}
      </div>
      <section>
        <Row>
          {id && dataLoaded ? (
            <Col lg={4}>
              <>
                <Card className="card-profile mb-4">
                  <Card.Header
                    className="overflow-hidden"
                    style={{
                      backgroundImage: `url(/img/photos/greenShapes.jpg)`,
                    }}
                  />
                  <Card.Body className="text-center">
                    <div
                      className="card-profile-img mx-auto d-flex align-center"
                      style={{ maxHeight: '8rem' }}
                    >
                      <div className="position-relative overflow-hidden rounded-circle">
                        <CustomImage
                          src={formValues.photo || '/img/phone-circle.png'}
                          layout="fixed"
                          width={122}
                          height={122}
                          priority
                        />
                      </div>
                    </div>

                    <h3 className="mb-3">{formValues.name}</h3>
                    <p className="mb-4">{formValues.doc_id}</p>
                  </Card.Body>
                </Card>
                <Card className="mb-4">
                  <Card.Header>
                    <h4 className="card-heading">Informações do Lead</h4>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col>
                        <Form.Label>Status</Form.Label>
                        <h6
                          className={
                            formValues.closed === 0
                              ? 'text-success'
                              : 'text-danger'
                          }
                        >
                          {formValues.closed === 0 ? 'Aberto' : 'Fechado'}
                        </h6>
                      </Col>
                    </Row>
                    <hr className="bg-gray-400 my-4" />
                    <Row>
                      <Col>
                        <Form.Label>Empresa</Form.Label>
                        <h6>{formValues.Company.name}</h6>
                      </Col>
                    </Row>
                    <hr className="bg-gray-400 my-4" />
                    <Row>
                      <Col>
                        <Form.Label>Fonte de lead</Form.Label>
                        <h6>{formValues.Channel.name}</h6>
                      </Col>
                    </Row>
                    <hr className="bg-gray-400 my-4" />
                    <Row>
                      <Col>
                        <Form.Label>Classificação</Form.Label>
                        <h6>
                          {formValues.Classifier
                            ? formValues.Classifier.name
                            : 'Não classificado'}
                        </h6>
                      </Col>
                    </Row>
                    <hr className="bg-gray-400 my-4" />
                    <Row>
                      <Col>
                        <Form.Label>Data de inclusão</Form.Label>
                        <h6>
                          {moment(formValues.created_at).format('DD/MM/YYYY')}
                        </h6>
                      </Col>
                    </Row>
                    <hr className="bg-gray-400 my-4" />
                    <Row>
                      <Col>
                        <Form.Label>Protocolo</Form.Label>
                        <h6>{formValues.protocol}</h6>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </>
            </Col>
          ) : null}
          <Col lg={id ? 8 : 12}>
            <ErrorProcessor error={apiErrors} />
            {!id || dataLoaded ? (
              <Card className="mb-5">
                <Card.Header>
                  <h4 className="card-heading">
                    {id ? `Informações de cadastro` : `Cadastrar ${dataName}`}
                  </h4>
                </Card.Header>
                <Card.Body>
                  <Forms
                    initialValues={formValues}
                    validationSchema={validationSchema.default}
                    onSubmit={handleSubmit.default}
                    fields={fields.default}
                    btnSubmit={{
                      variant: 'primary',
                      type: 'submit',
                      text: id ? 'Atualizar' : 'Cadastrar',
                    }}
                  />
                </Card.Body>
              </Card>
            ) : null}
            {formValues.LeadIvrOpenNewCanceled && (
              <Card className="mb-5">
                <Card.Header>
                  <h4 className="card-heading">Informações da fonte de lead</h4>
                </Card.Header>
                <Card.Body>
                  <Row className="mb-3">
                    <Col>
                      <Form.Label>Duração da chamada</Form.Label>
                      <h6 className="mb-3">
                        {formValues.LeadIvrOpenNewCanceled.call_time}
                      </h6>
                    </Col>
                    <Col>
                      <Form.Label>Número do telefone</Form.Label>
                      <h6 className="mb-3">
                        {formatPhoneBr(formValues.LeadIvrOpenNewCanceled.phone)}
                      </h6>
                    </Col>
                    <Col>
                      <Form.Label>Gravação da chamada</Form.Label>
                      <ReactPlayer
                        url={formValues.LeadIvrOpenNewCanceled.record_url}
                        controls
                        width="100%"
                        height={40}
                      />
                    </Col>
                  </Row>
                  <hr className="bg-gray-400 my-4" />
                  <Forms
                    initialValues={formValues.LeadIvrOpenNewCanceled}
                    validationSchema={validationSchema.LeadIvrOpenNewCanceled}
                    onSubmit={handleSubmit.LeadIvrOpenNewCanceled}
                    fields={fields.LeadIvrOpenNewCanceled}
                    btnSubmit={{
                      variant: 'primary',
                      type: 'submit',
                      text: 'Atualizar',
                    }}
                  />
                </Card.Body>
              </Card>
            )}
          </Col>
          {id && dataLoaded && (
            <>
              <Col lg={4}>
                <Card className="mb-5">
                  <Card.Header>
                    <h4 className="card-heading">Carregar arquivo</h4>
                  </Card.Header>
                  <Card.Body>
                    <Form onSubmit={handleSubmitFile}>
                      <div className="mb-2">
                        <Form.Label className="form-label">
                          Nome do arquivo
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={fileName}
                          onChange={(e) => setFileName(e.target.value)}
                        />
                      </div>
                      <Dropzone
                        onChange={handleFileChange}
                        clear={clearDropzone}
                        acceptMedia
                        acceptDocument
                      />
                    </Form>
                  </Card.Body>
                  <Card.Footer>
                    <Button
                      variant="primary"
                      type="button"
                      onClick={handleSubmitFile}
                      className="mt-2"
                    >
                      Carregar
                    </Button>
                  </Card.Footer>
                </Card>
              </Col>
              <Col lg={8}>
                <Card className="mb-5">
                  <Card.Header>
                    <h4 className="card-heading">Arquivos do lead</h4>
                  </Card.Header>
                  <Card.Body>
                    <DataTable
                      items={tableDataFiles}
                      columns={tableColumnsFiles}
                      defaultPageSize={5}
                      defaultSort="created_at"
                    />
                  </Card.Body>
                </Card>
              </Col>
            </>
          )}
        </Row>
      </section>
    </Container>
  );
}
