import { useLocation, Link } from 'react-router-dom';
import React, { Children } from 'react';
import PropTypes from 'prop-types';

export default function ActiveLink({
  children,
  activeClassName,
  href,
  matchPath,
  ...props
}) {
  const { pathname } = useLocation();
  const child = Children.only(children);
  const childClassName = child.props.className || '';
  const link = matchPath === true ? `/${pathname.split('/')[1]}` : pathname;
  const className =
    link === href
      ? `${childClassName} ${activeClassName}`.trim()
      : childClassName;

  return (
    <Link to={href} {...props}>
      {React.cloneElement(child, {
        className: className || null,
      })}
    </Link>
  );
}

ActiveLink.defaultProps = {
  activeClassName: 'active',
  matchPath: true,
};

ActiveLink.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
  activeClassName: PropTypes.string,
  matchPath: PropTypes.bool,
};
