import React, { useState, useEffect, useRef } from 'react';
import {
  Container,
  Card,
  Row,
  Col,
  Form,
  Button,
  Tabs,
  Tab,
  Spinner,
} from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from '../../services/axios';
import Loading from '../../components/Loading';
import Breadcrumbs from '../../components/Breadcrumbs';

export default function TicketsAdd() {
  const [isSubmitting, setIsSubmitting] = useState(false); // Estado para controle do loading
  const userData = useSelector((state) => state.auth.user);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState('');
  const [selectedRequesterId, setSelectedRequesterId] = useState('');
  const [, setSelectedUserName] = useState('');
  const [priority, setPriority] = useState('');
  const [stages, setStages] = useState('');
  const [serviceDesk, setServiceDesk] = useState('');
  const [status, setStatus] = useState('');
  const [internalCommunication, setInternalCommunication] = useState('');
  const [clientCommunication, setClientCommunication] = useState('');
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [, setCompanies] = useState([]);
  const [requesters, setRequesters] = useState([]);
  const [, setDepartments] = useState([]);
  const [serviceDesks, setServiceDesks] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [filteredDepartments, setFilteredDepartments] = useState([]);
  const [filteredPriorities, setFilteredPriorities] = useState([]);
  const [filteredStages, setFilteredStages] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState('');
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [title, setTitle] = useState('');
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState('');
  const [errors] = useState({});
  const navigate = useNavigate();
  const clientQuillRef = useRef();
  const internalQuillRef = useRef();
  const fileInputRef = useRef();
  const [files, setFiles] = useState([]); // Novo estado para os arquivos

  const fetchRequesters = async (companyId) => {
    try {
      const response = await axios.get(`/requesters`);
      const filteredRequesters = response.data.filter(
        (requester) => requester.company === parseInt(companyId, 10)
      );
      setRequesters(filteredRequesters);
    } catch (error) {
      console.error('Failed to fetch requesters:', error);
    }
  };

  const fetchTemplateContent = async (templateId) => {
    try {
      const response = await axios.get(`/templates/${templateId}`);
      setClientCommunication(response.data.content);
    } catch (error) {
      console.error('Failed to fetch template content:', error);
    }
  };

  useEffect(() => {
    Promise.all([
      axios.get(`/customers/`, {}),
      axios.get(`/departaments/`, {}),
      axios.get(`/templates/`, {}),
      axios.get(`/userdpto/`, {}),
      axios.get(`/servicedesks/`),
    ])
      .then(
        ([
          customersResponse,
          departmentsResponse,
          templatesResponse,
          userDptoResponse,
          serviceDesksResponse,
        ]) => {
          const departmentsData = departmentsResponse.data;
          const usersData = userDptoResponse.data;

          setCompanies(customersResponse.data);
          setDepartments(departmentsData);
          setEmailTemplates(templatesResponse.data);
          setUsers(usersData);
          setServiceDesks(serviceDesksResponse.data);
          setDataLoaded(true);
        }
      )
      .catch((error) => {
        console.error('Failed to fetch data:', error);
        setDataLoaded(true);
      });
  }, []);

  const handleUserChange = (e) => {
    const userId = e.target.value;
    const selectedUser = filteredUsers.find(
      (userItem) => userItem.userId === parseInt(userId, 10)
    );
    setSelectedUserId(userId);
    setSelectedUserName(
      selectedUser && selectedUser.UserProfile
        ? selectedUser.UserProfile.name
        : 'Usuário não encontrado'
    );
  };

  const handleRequesterChange = (e) => {
    const requesterId = e.target.value;
    setSelectedRequesterId(requesterId);
  };

  const handleCompanyChange = (e) => {
    const companyId = e.target.value;
    setSelectedCompany(companyId);
    fetchRequesters(companyId);
  };

  const handleDepartmentChange = (e) => {
    const departmentId = e.target.value;
    setSelectedDepartment(departmentId);

    const filtered = users.filter(
      (user) => user.departmentId === parseInt(departmentId, 10)
    );
    setFilteredUsers(filtered);
  };

  const handlePriorityChange = (e) => {
    const priorityValue = e.target.value;
    setPriority(priorityValue);
  };
  const handleStagesChange = (e) => {
    const stagesValue = e.target.value;
    setStages(stagesValue);
  };

  const handleServiceDeskChange = (e) => {
    const serviceDeskValue = e.target.value;
    setServiceDesk(serviceDeskValue);

    const selectedServiceDesk = serviceDesks.find(
      (desk) => desk.id === parseInt(serviceDeskValue, 10)
    );

    if (selectedServiceDesk) {
      setFilteredCompanies(selectedServiceDesk.customers);
      setFilteredDepartments(selectedServiceDesk.departments);
      setFilteredPriorities(selectedServiceDesk.priorities);
      setFilteredStages(selectedServiceDesk.stages);
    }
  };

  const handleTemplateChange = (e) => {
    const templateId = e.target.value;
    setSelectedTemplateId(templateId);
    fetchTemplateContent(templateId);
  };

  const handleFileChange = (e) => {
    setFiles([...e.target.files]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Ativar o loading

    try {
      const ticketData = {
        assignedTo: selectedUserId,
        requesterId: selectedRequesterId,
        priority,
        stages,
        serviceDesk,
        status,
        company: selectedCompany,
        department: selectedDepartment,
        title,
        templateId: selectedTemplateId,
      };

      const formData = new FormData();
      formData.append('ticketData', JSON.stringify(ticketData));
      files.forEach((file) => {
        formData.append('files', file);
      });

      const ticketResponse = await axios.post('/tickets', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const ticketId = ticketResponse.data.id;

      if (internalCommunication) {
        await axios.post('/communicationHistory', {
          ticketId,
          userId: userData.id,
          internalMessage: internalCommunication,
          type: 'internal',
        });
      }

      if (clientCommunication) {
        const communicationFormData = new FormData();
        communicationFormData.append('ticketId', ticketId);
        communicationFormData.append('userId', userData.id);
        communicationFormData.append('clientMessage', clientCommunication);
        communicationFormData.append('type', 'client');
        files.forEach((file) => {
          communicationFormData.append('files', file);
        });
        await axios.post('/communicationHistory', communicationFormData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      }

      navigate('/tickets');
    } catch (error) {
      console.error('Failed to submit ticket:', error);
      alert('Falha ao enviar os dados do ticket.');
    } finally {
      setIsSubmitting(false); // Desativar o loading após a criação
    }
  };

  if (!dataLoaded) {
    return <Loading />;
  }

  return (
    <Container fluid className="px-lg-4 px-xl-15">
      <Breadcrumbs
        title="Tickets"
        pages={[{ name: 'Tickets', link: '/tickets' }]}
      />
      <Card>
        <Card.Header>
          <Tabs
            defaultActiveKey="clientCommunication"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="clientCommunication" title="Comunicação com Cliente">
              <ReactQuill
                ref={clientQuillRef}
                theme="snow"
                value={clientCommunication}
                onChange={setClientCommunication}
              />
            </Tab>
            <Tab eventKey="internalCommunication" title="Comunicação Interna">
              <ReactQuill
                ref={internalQuillRef}
                theme="snow"
                value={internalCommunication}
                onChange={setInternalCommunication}
              />
            </Tab>
          </Tabs>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>Título</Form.Label>
                  <Form.Control
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    isInvalid={!!errors.title}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.title}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Modelo de Email</Form.Label>
                  <Form.Select
                    value={selectedTemplateId}
                    onChange={handleTemplateChange}
                    isInvalid={!!errors.selectedTemplateId}
                  >
                    <option value="">--Selecione--</option>
                    {emailTemplates.map((template) => (
                      <option key={template.id} value={template.id}>
                        {template.name}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.selectedTemplateId}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>Mesa de serviço</Form.Label>
                  <Form.Select
                    value={serviceDesk}
                    onChange={handleServiceDeskChange}
                    isInvalid={!!errors.serviceDesk}
                  >
                    <option value="">--Selecione--</option>
                    {serviceDesks.map((desk) => (
                      <option key={desk.id} value={desk.id}>
                        {desk.displayName}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.serviceDesk}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Empresa</Form.Label>
                  <Form.Select
                    value={selectedCompany}
                    onChange={handleCompanyChange}
                    isInvalid={!!errors.selectedCompany}
                  >
                    <option value="">--Selecione--</option>
                    {filteredCompanies.map((company) => (
                      <option key={company.id} value={company.id}>
                        {company.razaoSocial}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.selectedCompany}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Solicitante</Form.Label>
                  <Form.Select
                    value={selectedRequesterId}
                    onChange={handleRequesterChange}
                    isInvalid={!!errors.selectedRequesterId}
                  >
                    <option value="">--Selecione--</option>
                    {requesters.map((requester) => (
                      <option key={requester.id} value={requester.id}>
                        {requester.name} - {requester.email}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.selectedRequesterId}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>Departamento</Form.Label>
                  <Form.Select
                    value={selectedDepartment}
                    onChange={handleDepartmentChange}
                    isInvalid={!!errors.selectedDepartment}
                  >
                    <option value="">--Selecione--</option>
                    {filteredDepartments.map((department) => (
                      <option key={department.id} value={department.id}>
                        {department.name}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.selectedDepartment}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Prioridade</Form.Label>
                  <Form.Select
                    value={priority}
                    onChange={handlePriorityChange}
                    isInvalid={!!errors.priority}
                  >
                    <option value="">--Selecione--</option>
                    {filteredPriorities.map((prio) => (
                      <option key={prio.id} value={prio.id}>
                        {prio.name}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.priority}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>Responsável</Form.Label>
                  <Form.Select
                    value={selectedUserId}
                    onChange={handleUserChange}
                    isInvalid={!!errors.selectedUserId}
                  >
                    <option value="">--Selecione--</option>
                    {filteredUsers.map((user) => (
                      <option key={user.userId} value={user.userId}>
                        {user.UserProfile
                          ? user.UserProfile.name
                          : 'Nome não disponível'}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.selectedUserId}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Status</Form.Label>
                  <Form.Select
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    isInvalid={!!errors.status}
                  >
                    <option value="">--Selecione--</option>
                    <option value="aberto">Aberto</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.status}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Estágio</Form.Label>
                  <Form.Select
                    value={stages}
                    onChange={handleStagesChange}
                    isInvalid={!!errors.stages}
                  >
                    <option value="">--Selecione--</option>
                    {filteredStages.map((stage) => (
                      <option key={stage.id} value={stage.id}>
                        {stage.name}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.stage}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3 d-flex justify-content-end">
              <Col style={{ maxWidth: '100%', height: 'auto' }}>
                <Form.Group>
                  <Form.Label>Arquivos</Form.Label>
                  <Form.Control
                    type="file"
                    multiple
                    ref={fileInputRef}
                    onChange={handleFileChange}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={8} md={1} className="d-flex align-items-end">
                <Button type="submit" disabled={isSubmitting} className="mt-2">
                  {isSubmitting ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    'Enviar'
                  )}
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
}
