import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Container, Card, Row, Col } from 'react-bootstrap';
import { get } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { FaTrash } from 'react-icons/fa';

import Loading from '../../components/Loading';
import axios from '../../services/axios';
import Breadcrumbs from '../../components/Breadcrumbs';
import { useNotification } from '../../components/Notification/NotificationContext';
import Forms from '../../components/Forms';
import ErrorProcessor from '../../components/ErrorProcessor';
import ButtonModal from '../../components/ModalConfirmation/ButtonModal';
import DataTable from '../../components/DataTable';
import PageHeader from '../../components/PageHeader';

export default function AccessModule() {
  const routeName = '/accessesmodules';
  const titleName = 'Acessos';
  const dataName = 'Módulo';
  const [dataLoaded, setDataLoaded] = useState(false);
  const [apiErrors, setApiErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const notification = useNotification();
  const navigate = useNavigate();
  const params = useParams();
  const id = get(params, 'id', '');
  const [modules, setModules] = useState([]);
  const [access, setAccess] = useState({ Modules: [] });
  const [formValues, setFormValues] = useState({
    module_id: '',
  });

  const validationSchema = Yup.object().shape({
    module_id: Yup.string().required('Módulo obrigatório.'),
  });

  const tableColumns = useMemo(
    () => [
      {
        Header: 'Módulo',
        accessor: 'name',
      },
      {
        Header: 'Remover',
        accessor: 'remove',
        noSearch: true,
      },
    ],
    []
  );

  const handleFieldChange = useCallback((fieldName, value) => {
    setFormValues((prevValues) => ({ ...prevValues, [fieldName]: value }));
  }, []);

  const fields = [
    {
      name: 'module_id',
      id: 'module_id',
      label: 'Módulo',
      type: 'select',
      options: modules,
      onChange: (e) =>
        handleFieldChange('module_id', e.value ? e.value : e.target.value),
      colSize: 12,
      additionalProps: {},
    },
  ];

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      await axios.post(routeName, {
        ...formValues,
        ...{ access_id: id },
      });
      const getAccess = await axios.get(`accesses/${id}`);
      setAccess(getAccess.data);
      notification(`${dataName} adicionado com sucesso`, 'success');
    } catch (err) {
      setApiErrors(err);
    } finally {
      setIsLoading(false);
    }
  };

  const confirmDelete = useCallback(
    async (AccessModuleId) => {
      try {
        setIsLoading(true);
        await axios.delete(`${routeName}/${AccessModuleId}`);
        const getAccess = await axios.get(`accesses/${id}`);
        setAccess(getAccess.data);
        notification(`${dataName} removido do acesso com sucesso`, 'success');
      } catch (err) {
        setApiErrors(err);
      } finally {
        setIsLoading(false);
      }
    },
    [setIsLoading, notification, id]
  );

  const tableData = useMemo(() => {
    return access.Modules.map((module) => ({
      remove: (
        <ButtonModal
          onConfirm={() => confirmDelete(module.AccessModule.id)}
          message={`Confirma remoção do ${dataName} ${module.name} ?`}
          buttonProps={{
            variant: 'danger',
            className: 'rounded-pill shadow',
            size: 'sm',
          }}
          textModal={{
            title: 'Remover',
            textConfirm: 'Remover',
          }}
        >
          <FaTrash />
        </ButtonModal>
      ),
      ...module,
    }));
  }, [access, confirmDelete]);

  useEffect(() => {
    if (!id) {
      notification(`Acesso inválido ou não informado`);
      navigate('/accesses');
      return;
    }
    async function getData() {
      try {
        setIsLoading(true);
        const optionsModules = [{ value: '', label: '-- Selecione --' }];
        const getAccess = await axios.get(`accesses/${id}`);
        const getModules = await axios.get(`modules`);
        setAccess(getAccess.data);

        if (getModules) {
          getModules.data.forEach((data) => {
            optionsModules.push({
              value: data.id,
              label: data.name,
            });
          });
        }
        setModules(optionsModules);
      } catch (err) {
        setApiErrors(err);
      } finally {
        setIsLoading(false);
        setDataLoaded(true);
      }
    }

    getData();
  }, [id, notification, navigate]);

  return (
    <Container fluid className="px-lg-4 px-xl-5">
      <Loading isLoading={isLoading} />
      <Breadcrumbs
        title="Módulo"
        pages={[{ link: '/accesses', name: 'Acessos' }]}
      />
      <PageHeader title={titleName} />
      <section>
        <Row>
          <Col lg={8}>
            <Card className="mb-4">
              <Card.Header>
                <h4 className="card-heading">
                  Módulos do acesso {access.name}
                </h4>
              </Card.Header>
              <Card.Body>
                <DataTable
                  items={tableData}
                  columns={tableColumns}
                  defaultPageSize={5}
                />
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4}>
            <Card className="mb-5">
              <Card.Header>
                <h4 className="card-heading">Adicionar módulo</h4>
              </Card.Header>
              <Card.Body>
                <ErrorProcessor error={apiErrors} />
                {!id || dataLoaded ? (
                  <Forms
                    // key={JSON.stringify(initialValues)}
                    initialValues={formValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    fields={fields}
                    btnSubmit={{
                      variant: 'primary',
                      type: 'submit',
                      text: 'Adicionar',
                    }}
                  />
                ) : null}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </section>
    </Container>
  );
}
