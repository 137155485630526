import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, NavItem, NavLink } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { get } from 'lodash';
import { FaUserCircle } from 'react-icons/fa';

import Avatar from '../Avatar';
import * as actions from '../../store/modules/auth/actions';
import axios from '../../services/axios';
import { useNotification } from '../Notification/NotificationContext';
import ErrorProcessor from '../ErrorProcessor';

// Definir o URL base da API para concatenar o caminho da foto
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL; // Ajuste conforme necessário

export default function UserMenu() {
  // eslint-disable-next-line no-unused-vars
  const notification = useNotification();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [userName, setUserName] = useState('');
  const [userLastname, setUserLastname] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userPhoto, setUserPhoto] = useState('');
  const userId = useSelector((state) => state.auth.user.id);
  const [apiErrors, setApiErrors] = useState({});

  const handleLogout = useCallback(() => {
    dispatch(actions.loginFailure());
    navigate('/login');
  }, [dispatch, navigate]);

  const handleClickLink = (e) => {
    e.preventDefault();
    const route = e.target.getAttribute('data-route');

    if (route) {
      navigate(route);
    }
  };

  useEffect(() => {
    if (!userId) return;
    async function getData() {
      try {
        const { data } = await axios.get(`users/${userId}`);

        // Concatena o caminho base com o caminho da foto recebido da API
        const photoPath = get(data, 'UserProfile.photo', '');
        const fullPhotoUrl = photoPath ? `${API_BASE_URL}${photoPath}` : '';

        setUserName(data.UserProfile.name);
        setUserLastname(data.UserProfile.lastname);
        setUserEmail(data.email);
        setUserPhoto(fullPhotoUrl); // Definir o URL completo da foto
      } catch (err) {
        setApiErrors(err);
      }
    }

    getData();
  }, [userId]);

  return (
    <Dropdown as={NavItem} className="ms-auto" align="end">
      <ErrorProcessor error={apiErrors} />
      <Dropdown.Toggle as={NavLink} className="pe-0" id="userInfo">
        {userPhoto ? (
          <Avatar image={userPhoto} alt="Foto de perfil" border priority />
        ) : (
          <FaUserCircle size={48} />
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu
        className="dropdown-menu-animated"
        aria-labelledby="userInfo"
        data-bs-popper="none"
      >
        <Dropdown.Header className="text-gray-700">
          <h6 className="text-uppercase font-weight-bold">
            {userName} {userLastname}
          </h6>
          <small>{userEmail.split(' ')[0]}</small>
        </Dropdown.Header>
        <Dropdown.Divider />
        <Dropdown.Item
          data-route={`/profile/${userId || 0}`}
          onClick={handleClickLink}
        >
          Perfil
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item onClick={handleLogout}>Sair</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
